import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from '../../../component/admin/Footer';
import Content from '../../../component/admin/Content';
import DataTable from '../../../Utils/Datatables';
import { Link } from "react-router-dom";
 // import { GetAdminAllClients } from "../../../ReduxStore/Slice/AdminMasters"

const Signals = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  // const { data, isLoading, } = useSelector((state) => state && state?.Adminslice)

  
  const GetAllClients = async () => {
  //  await dispatch(GetAdminAllClients(AdminToken))
  }

  useEffect(() => {
    GetAllClients()
  }, [dispatch])




  const columns = [

    {
      name: 'username',
      selector: row => row.username,
      width: "140px"
    },
    {
      name: "Contact ",
      selector: row => row.phone_number,
      width: "170px"

    },
    {
      name: 'Email',
      selector: row => row.email,
      width: "170px"

    },
    {
      name: 'Multiplayer',
      selector: row => row.multiplayer,
      width: "100px"

    },
    {
      name: 'Broker',
      selector: row => row.broker,
      width: "100px"

    },
    {
      name: 'Api Key',
      selector: row => row.api_key,
      width: "100px"

    },
    {
      name: 'Api Secret',
      selector: row => row.api_secret,
      width: "100px"

    },
    {
      name: 'Api Type',
      selector: row => row.api_type,
      width: "100px"

    },
    {
      name: 'Actions',
      selector: row =>
        <>
          <div className='actiontd'>
            <Link to={`/admin/client/edit/${row.id} `}  state={row}>
              <i class="fa-solid fa-pen-to-square" />
            </Link>
            {/* //<a href='#' className='mx-2'><i class="fa-regular fa-eye"></i></a> */}
            {/* <a href='#'><i class="fa-solid fa-trash"></i></a> */}
          </div>
        </>
      ,
      width: "172px"

    },


  ]


  return (
    <div className='main-content position-relative'>
      <div className="page-content">
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="mb-3 col-md-6 mb-md-0">
            <h5 className="mb-0 text-white h3 font-weight-400">Signals</h5>
          </div>
        </div>
      </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {/* <DataTable
                  columns={columns} data={data && data}
                /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></div>
  )
}

export default Signals