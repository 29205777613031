

export const Nav_Config = [
    {
        id: 1,
        name: "Dashboard",
        icon: "fa-solid fa-house",
        route: "/child/dashboard"
    },
    // {
     //   id: 2,
      //  name: "Signals",
       // icon: "fas fa-wave-square ",
       // route: "/child/signals"
   // },
    {
        id: 3,
        name: "TradeBook",
        icon: "fa-solid fa-clock-rotate-left",
        route: "/child/tradehistory"
    },
    {
        id: 3,
        name: "Positions",
        icon: "fa-sharp fa-regular fa-map fa-2x",
        route: "/child/positions"
    },

    {
        id: 4,
        name: "RiskMgmt",
        icon: "fa-solid fa-people-roof",
        route: "/child/riskmgmt"
    },
    {
       id: 2,
        name: "Trading Status",
        icon: "fa fa-receipt",
       route: "/child/tradingstatus"
    },
   {
      id: 3 ,
       name: "Api Create Info",
       icon: "fa-solid fa-info",
      route: "/child/createapiinformation"
   },

]