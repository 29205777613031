import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";
import { fDateTimeSuffix, dateFormatePlusThirty } from '../Utils/DateFormat';
import Admin from './Admin'
import SuperAdmin from './Superadmin'
import Client from './Child_Client'
import Master_Client from './Master_Clients'
import Login from '../Layout/Auth/Login'
import ResetPassword from '../Layout/Auth/ResetPassword';
import UpdatePassword from '../Layout/Auth/UpdatePassword';



import Testing from "../Testing"
import NotFound from "../Layout/NotFound"



const Routing = () => {
    const location = useLocation();

    const navigate = useNavigate()
    // const accessToken = localStorage.getItem("user_details").accessToken
    const role_id = localStorage.getItem("user_details")
    //   const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;


    const expiryTime = localStorage.getItem("expiryTime")

    const data = JSON.parse(role_id)?.roles[0]
    // const accessToken = JSON.parse(accessToken)
    const data1 = JSON.parse(role_id)





    useEffect(() => {
        if (location.pathname === "/") {
            navigate("/login");
        }
        if (role_id != null) {
            if (data === "ADMIN" && location.pathname === "/") {
                navigate("/admin/dashboard");
            } else if (data === "USER" && location.pathname === "/") {
                navigate("/child/dashboard");
            }
            else if (data === "MASTER" && location.pathname === "/") {
                navigate("/master/dashboard");
            }
            else if (data === "SUPERADMIN" && location.pathname === "/") {
                navigate("/super/dashboard");
            }
            // else {
            //     navigate("/login");
            // }
        }

    }, [location.pathname])


    return <>
        <Routes>
            <Route path="/super/*" element={(data == "SUPERADMIN") ? <SuperAdmin /> : <Login />} />
            <Route path="/admin/*" element={(data == "ADMIN") ? <Admin /> : <Login />} />
            <Route path="/master/*" element={(data == "MASTER") ? <Master_Client /> : <Login />} />
            <Route path="/child/*" element={(data == "USER") ? <Client /> : <Login />} />
            <Route path="/login" element={<Login />} />
            {/* <Route path="/resetpassword" element={<ResetPassword />} /> */}
            <Route path="/forgetpassword" element={<ResetPassword />} />
            <Route path="/updatepassword/:id" element={<UpdatePassword />} />
            <Route path="/test" element={<Testing />} />
            <Route path="*"  element={<NotFound />} />

        </Routes>


    </>
}

export default Routing