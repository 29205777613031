import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, useFormik } from 'formik';
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate, Link } from "react-router-dom";
import * as  valid_err from "../../Utils/Common_Messages"
import { Email_regex } from "../../Utils/Common_regex"
import { fDateTimeSuffix, dateFormatePlusThirty } from '../../Utils/DateFormat';

import { SignIn, Get_Current_Date } from "../../ReduxStore/Slice/AuthSlice/AuthSlice"
import Formikform from "../../Utils/Formikform"
import { useDispatch, useSelector } from "react-redux";
import AlertToast from "../../Utils/Alert_Toast"
import { toast } from "react-toastify";

const Login = () => {

  const [time, setTime] = useState("")


  // const getTime1 = async () => {
  //   await dispatch(Get_Current_Date()).then((res) => {
  //     // console.log("new Date(new Date(datee).getTime()", new Date(new Date(datee).getTime()))
  //     let a = new Date(new Date(res.payload.datetime.replace("T", " ").split(".")[0])).getTime()
  //     setTime(fDateTimeSuffix(a + 60 * 60 * 24 * 1000))
  //   })
  // }

  // useEffect(() => {
  //   getTime1()
  // }, [])


  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const AdminToken = JSON.parse(localStorage.getItem('admin')).accessToken;

  const selector = useSelector((state) => state?.AuthSlice)

  const isValidEmail = (email) => {
    return Email_regex(email)
  }

  const formik = useFormik({
    initialValues: {
      userpassword: '',
      useremail: '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.userpassword) {
        errors.userpassword = valid_err.PASSWORD_ERROR;
      }

      if (!values.useremail) {
        errors.useremail = valid_err.EMPTY_EMAIL_ERROR;
      } else if (!isValidEmail(values.useremail)) {
        errors.useremail = valid_err.INVALID_EMAIL_ERROR;
      }

      return errors;
    },
    onSubmit: async (values) => {

      const SESSION_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const EXPIRY_KEY = 'sessionExpiry';



      // return
      // localStorage.setItem('expiryTime', expiryTime.toString());
      await dispatch(SignIn({ email: values.useremail, password: values.userpassword })).then((res) => {
        // console.log("res", res);

        if (res.payload.status) {
          if (res.meta.requestStatus === "fulfilled") {
            if (res.payload.roles[0] === "SUPERADMIN") {
              // __________ Super Admin __________
              const expiryTimestamp = new Date + SESSION_DURATION;

              sessionStorage.setItem("EXPIRY_KEY", time);
              localStorage.setItem('user_details', JSON.stringify(res.payload));
              navigate("/super/dashboard");
            }
            else if (res.payload.roles[0] === "ADMIN") {

              // __________ Admin __________
              const expiryTimestamp = new Date + SESSION_DURATION;
              sessionStorage.setItem("EXPIRY_KEY", time);
              localStorage.setItem('user_details', JSON.stringify(res.payload));
              navigate("/admin/dashboard");
            } else if (res.payload.roles[0] === "USER") {
              // __________ Child __________
              const expiryTimestamp = Date.now() + SESSION_DURATION;
              sessionStorage.setItem("EXPIRY_KEY", time);
              localStorage.setItem('user_details', JSON.stringify(res.payload));
              navigate("/child/dashboard");
            } else if (res.payload.roles[0] === "MASTER") {
              // __________ Master __________
              const expiryTimestamp = Date.now() + SESSION_DURATION;
              localStorage.setItem("EXPIRY_KEY", time);
              localStorage.setItem('user_details', JSON.stringify(res.payload));
              navigate("/master/dashboard");
            }
          }
        }
        else {
          toast.error(res.payload.message)
        }
      })
    },
  });

  const fields = [
    { name: 'useremail', label: 'Your Email', type: 'text' },
    { name: 'userpassword', label: 'Password', type: "password" },
  ];


  return (
    <section style={{ height: '100vh', background: '#ebf8fc', overflowY: 'hidden' }} className="d-flex align-items-center justify-content-center">
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 ">
            <div className="card rounded border ">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-7 d-none d-md-flex align-items-center justify-content-center">
                    <div>
                      <h2 className="mb-0">Welcome Back!</h2>
                      <p className>To keep you connected, please login with your personal info.</p>
                      <img src="./images/wave.gif" alt className="w-100" />
                    </div>
                  </div>
                  <div className="col-md-5 pt-md-4">
                    <div>
                      <div className="text-center">
                        <a href="/">
                          <img src="../logo.png" alt className="w-50" />
                        </a>
                      </div>
                      <Formikform fieldtype={fields} formik={formik} btn_name="Login" />
                      <AlertToast position="top-center" />
                    </div>
                    <div class="form-group">
                      <div class="d-flex justify-content-end">
                        <Link to="/forgetpassword"

                          class="text-dark font-weight-bold">
                          <small>Forgot password ?</small>

                        </Link>
                      </div>

                      <div className="text-center">
                        <small className="text-dark" style={{ fontSize: 11 }}>
                          © Copyright 2023 &nbsp; Copytrading
                          &nbsp;
                          All Rights Reserved.
                        </small>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >

  );
};

export default Login;
