import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { AdminDashboard_Data, getDashboardData } from "../../../ReduxStore/Slice/AdminSlice/Dashboard/dashboardSlice"
import Loader from "../../../component/ReusableComponents/Loader/Loader"
const Dashboard = () => {

    const dispatch = useDispatch()

    const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
    const userid = JSON.parse(localStorage.getItem('user_details')).id;
    const admin_id = JSON.parse(localStorage.getItem('user_details')).admin_id;

    const DashboardData = useSelector(getDashboardData)

    // console.log("DashboardData", DashboardData);

    const GetAllClients = async () => {
        await dispatch(AdminDashboard_Data({ id: userid, admin_id: admin_id, AdminToken: AdminToken }))
    }

    useEffect(() => {
        GetAllClients()
    }, [dispatch])

    return (
        <div>
            <div className="main-panel">
                <div className="content ">
                    <div className="panel-header bg-primary-gradient">
                        <div className="py-5 page-inner">
                            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                                <div>
                                    <h2 className="pb-2 text-white fw-bold">AdminDashboard</h2>
                                </div>

                                {/* <div className="py-2 ml-md-auto py-md-0">
                                    <a href="#" className="mr-2 btn btn-success btn-border ">Deposits</a>
                                    <a href="#" className="mr-2 btn btn-danger btn-border ">Withdrawals</a>
                                    <a href="#" className="btn btn-secondary ">Users</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>
                    <div className="page-inner mt--5">
                        {/* Beginning of  Dashboard Stats  */}
                        <div className="row row-card-no-pd  shadow-lg mt--2">
                            <div className="col-sm-6 col-md-4">
                                <div className="card card-stats card-round  full-height">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="text-center icon-big">
                                                    <i className="fa fa-download text-warning" />
                                                </div>
                                            </div>
                                            <div className="col-8 col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">Total Licence</p>
                                                    <h4 className="card-title ">
                                                        {DashboardData.length != 0 && DashboardData[0].total_licence}
                                                    </h4>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <div className="card card-stats card-round  full-height">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="text-center icon-big">
                                                    {/* <i className="flaticon-download text-info" /> */}
                                                    <i className="fa fa-arrow-down-long text-info"></i>

                                                </div>
                                            </div>
                                            <div className="col-8 col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">Used Licence</p>
                                                    <h4 className="card-title ">
                                                        {DashboardData.length != 0 && DashboardData[0].used_licence}
                                                    </h4>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <div className="card card-stats card-round  full-height">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="text-center icon-big">

                                                    <i className="fa fa-arrow-up-right-from-square text-danger"></i>
                                                </div>
                                            </div>
                                            <div className="col-8 col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">Remaining Licence</p>
                                                    <h4 className="card-title ">
                                                        {DashboardData.length != 0 && (parseInt(DashboardData[0].total_licence) - parseInt(DashboardData[0].used_licence))}
                                                    </h4>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-4">
                                <div className="card card-stats card-round ">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="text-center icon-big">
                                                    <i className="fa-solid fa-users text-success"></i>

                                                </div>
                                            </div>
                                            <div className="col-8 col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">Total Master Users</p>
                                                    <h4 className="card-title ">
                                                        {DashboardData.length != 0 && DashboardData[0].total_master}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-sm-6 col-md-3">
                                <div className="card card-stats card-round ">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="text-center icon-big">
                                                    <i className="fa-solid fa-ban text-danger"></i>

                                                </div>
                                            </div>
                                            <div className="col-8 col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">Block Users</p>
                                                    <h4 className="card-title ">0</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-sm-6 col-md-3">
                                <div className="card card-stats card-round ">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="text-center icon-big">

                                                    <i className="fa-regular fa-user text-success"></i>
                                                </div>
                                            </div>
                                            <div className="col-8 col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">Active Users</p>
                                                    <h4 className="card-title ">397</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-sm-6 col-md-3">
                                <div className="card card-stats card-round ">
                                    <div className="card-body ">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="text-center icon-big">
                                                    <i className="fa fa-folder-tree text-warning"></i>
                                                </div>
                                            </div>
                                            <div className="col-8 col-stats">
                                                <div className="numbers">
                                                    <p className="card-category">Investment Plans</p>
                                                    <h4 className="card-title ">0</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="overflow-auto">
                                    <canvas id="myChart" height={100} className />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Dashboard