import React, { useState } from 'react'
import axios from "axios";

// import Files
import * as Config from "../Utils/Config";



// Admin ForGet Password Email  Link 


export function SignIn_function(data) {
    return axios.post(`${Config.base_url}auth/signin`, data, {
        // headers: header(token),
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}

export function Forget_Password_SendLink(data) {
    return axios.post(`${Config.base_url}auth/forgotepassword`, data, {
        // headers: header(token),
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}

export function Forget_Password_Update(data) {
    return axios.post(`${Config.base_url}auth/resetpassword`, data, {
        // headers: header(token),
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


export function Change_Password_Update(data) {
    return axios.post(`${Config.base_url}auth/changepassword`, data, {
        // headers: header(token),
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}





export function GetCurrentDate() {
    return axios
        .get(`https://worldtimeapi.org/api/ip`, {

        })
        .then((res) => {
            return res;
        }).catch((res) => { return res })
}


