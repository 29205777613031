import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Get_Broker_access_token, Get_User_Trading_status  , Get_User_Trading_status_Off} from "../../../Service/user.service"


export const GetBrokerAccessToken = createAsyncThunk("admin/childs", async (data, token) => {
  const { client_id, AdminToken } = data

  try {
    const res = await Get_Broker_access_token({ client_id: client_id }, AdminToken)
    if (res.status) {
      return await res?.data;
    }
  } catch (err) {
    console.log("error", err);
  }
});


export const GetTradingStatus = createAsyncThunk("user/getTradingStatus", async (data, token) => {
  const { id, AdminToken } = data
  try {
    const res = await Get_User_Trading_status({ id: id }, AdminToken)
    // console.log("GetTradingStatus", res);
    return await res?.data;

  } catch (err) {
    console.log("error", err);
    // custom error
  }
});

export const UserTradingstatusOff = createAsyncThunk("user/tradingOff", async (data) => {
  const { id, AdminToken } = data


  try {
    const res = await Get_User_Trading_status_Off({ id: id }, AdminToken)
console.log("Get_User_Trading_status_Off" , res);
    // if (res.status) {
    return await res?.data;
    // } else {
    //   console.log("res", res);
    // }
  } catch (err) {
    console.log("error", err);
  }
});

// export const FindOneChildUser = createAsyncThunk("admin/Find_One_Child_User", async (data) => {
//   const { id, token } = data
//   try {
//     const res = await Find_One_Child_User(id, token)

//     if (res.status) {
//       return await res?.data;
//     } else {
//       console.log("res", res);
//     }
//   } catch (err) {
//     console.log("error", err);
//   }
// });


// export const UpdateMasterChild = createAsyncThunk("admin/update", async (data, token) => {
//   const { req, AdminToken } = data
//   try {
//     const res = await Update_master_or_child(req, AdminToken)
//     return await res?.data;
//   } catch (err) {
//     console.log("error", err.message);
//     // custom error
//   }
// });





const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState: {
    isLoading: false,
    accesstokendata: [],
    tradingstatus: [],
    tradingstatusOff: []

    // isError: false,
    // status : false
  },

  recuders: {},
  extraReducers: {
    //     [GetAdminAllChilds.pending]: (state, {payload}) => {
    //   state.isLoading = true;
    //   return { ...state, childlist: [] };

    // },
    [GetBrokerAccessToken.pending]: (state, action) => {
      console.log("Pending")
    },
    [GetBrokerAccessToken.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      return { ...state, accesstoken: payload };
    },
    [GetBrokerAccessToken.rejected]: (state) => {
      state.isError = true;
    },
    [GetTradingStatus.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      return { ...state, tradingstatus: payload };
    },
    [UserTradingstatusOff.fulfilled]: (state, { payload }) => {
      console.log("payload" ,payload);
      state.isLoading = false;
      return { ...state, tradingstatusOff: payload };
    },
  },
})

export const accesstoken = (state) => state.DashboardSlice.accesstoken;
export const tradingstatus = (state) => state?.DashboardSlice?.tradingstatus.data;
export const tradingstatusOff = (state) => state?.DashboardSlice?.tradingstatusOff;
// export const getMasters = (state) => state.AdminChilds.allmasters;
// export const addChild = (state) => state.AdminChilds.addchild
// export const findOnchild = (state) => state && state.AdminChilds.findOnchild.data

export default DashboardSlice;

