
export const superadmin_route =

    [
        {
            id: 1,
            route: "/super/dashboard",
            name: "Dashboard ",
            icon: "fas fa-home",
        },
        {
            id: 2,
            route: "/super/admins",
            name: "All Admins",
            icon: "fa fa-user-check",
        },
  {
            id: 2,
            route: "/super/system",
            name: "System",
            icon: "fas fa-cubes",
        },

    ]

export const admin_route = [
    {
        id: 1,
        route: "/admin/dashboard",
        name: "Dashboard",
        icon: "fas fa-home",
    },
    {
        id: 2,
        route: "/admin/masters",
        name: "Master Client",
        icon: "fa fa-user-check",
    },
    {
        id: 3,
        route: "/admin/childs",
        name: "Child Client",
        icon: "fa fa-user-check",
    },
    {
        id: 4,
        route: "/admin/system",
        name: "System",
        icon: "fas fa-cubes ",
    }
]