import React from 'react'
import { AdminSystem, UpdateAdminSystem } from "../../../ReduxStore/Slice/AdminSlice/System/SystemSlice"


import Formikform from "../../../Utils/Formikform"
import { Formik, Form, Field, useFormik } from 'formik';
import * as  valid_err from "../../../Utils/Common_Messages"
import { toast } from "react-toastify";
import AlertToast from "../../../Utils/Alert_Toast"
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Email_regex, Mobile_regex } from "../../../Utils/Common_regex"
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "../../../component/admin/admin-assets/css/style.css"

// import { FindOneChildUser, AddAdminsChilds, findOnchild, GetAllMastersName, getMasters, UpdateMasterChild } from "../../../ReduxStore/Slice/AdminChilds"
// import { FindOneChildUser, AddAdminsChilds, findOnchild, GetAllMastersName, getMasters, UpdateMasterChild } from "../../../ReduxStore/Slice/AdminSlice/Common"


import { FindOneChildUser, AddAdminsChilds, findOnchild, GetAllMastersName, getMasters, UpdateMasterChild } from "../../../ReduxStore/Slice/AdminSlice/Common"



export default function Edit_System() {

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const params_id = useParams()
  // console.log("location", location.state);

  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;





  const isValidEmail = (email) => {
    return Email_regex(email)
  }
  const formik = useFormik({
    initialValues: {
      companyname: location.state.company_name,
      companyemail: location.state.company_email,
      companycc: location.state.company_cc,
      companybcc: location.state.company_bcc,
      companyhost: location.state.smtp_host,
      companyport: location.state.smtp_port,
      smtppasword: location.state.email_password,
    },
    validate: (values) => {
      const errors = {};
      if (!values.companyhost) {
        errors.companyhost = valid_err.COMPANY_HOST_ERROR;
      }
      if (!values.companyport) {
        errors.companyport = valid_err.COMPANY_PORT_ERROR;
      }
      if (!values.companyname) {
        errors.companyname = valid_err.COMPANY_NAME_ERROR;
      }
      if (!values.smtppasword) {
        errors.smtppasword = valid_err.COMPANY_PASSWORD_ERROR;
      }
      if (!values.companyemail) {
        errors.companyemail = valid_err.EMPTY_COMPANY_EMAIL_ERROR;
      } else if (!isValidEmail(values.companyemail)) {
        errors.companyemail = valid_err.INVALID_COMPANY_EMAIL_ERROR;
      }
      if (!values.companycc) {
        errors.companycc = valid_err.EMPTY_COMPANYCC_EMAIL_ERROR;
      } else if (!isValidEmail(values.companycc)) {
        errors.companycc = valid_err.INVALID_COMPANYCC_EMAIL_ERROR;
      }
      if (!values.companybcc) {
        errors.companybcc = valid_err.EMPTY_COMPANYBCC_EMAIL_ERROR;
      } else if (!isValidEmail(values.companybcc)) {
        errors.companybcc = valid_err.INVALID_COMPANYBCC_EMAIL_ERROR;
      }




      return errors;
    },
    onSubmit: async (values) => {
      // console.log("values", values);
      const req = {
        "company_name": values.companyname,
        // "company_favicon":"fav icon",
        "company_email": values.companyemail,
        "company_cc": values.companycc,
        "company_bcc": values.companybcc,
        "email_password": values.smtppasword,
        "smtp_host": values.companyhost,
        "smtp_port": values.companyport,
        "admin_id": userid

      }
      await dispatch(UpdateAdminSystem({ req: req, AdminToken: AdminToken })).then((res) => {

        // console.log("UpdateAdminSystem", res);
        if (res.meta.requestStatus === "fulfilled") {
          toast.success(res.payload)
          setTimeout(() => {
            navigate("/admin/system")
          }, 2000);
        }

      })
    }
  });


  const fields = [
    { name: 'companyname', label: 'Company Name', type: 'text' },
    { name: 'companyemail', label: 'Company email', type: 'text' },
    { name: 'companycc', label: 'Company CC', type: 'text' },
    { name: 'companybcc', label: 'Company BCC', type: 'text' },
    { name: 'companyhost', label: 'SMTP Host', type: 'text' },
    { name: 'companyport', label: 'SMTP Port', type: 'text' },
    { name: 'smtppasword', label: 'SMTP Password', type: 'text' },
  ];




  return (
    <div className="main-panel">
      <div className="content ">
        <div className="panel-header bg-primary-gradient">
          <div className="py-5 page-inner">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <h4 className="pb-2 text-white fw-bold flex-grow-1">Edit System</h4>
              <Link to='/admin/system' className='btn btn-light'>Back</Link>

            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row row-card-no-pd  shadow-lg mt--2">
            <div className='data-table-div'>

              <Formikform fieldtype={fields.filter(field => !field.showWhen || field.showWhen(formik.values))} formik={formik} btn_name="Update" />
              <AlertToast position="top-center" />
            </div>
          </div>
        </div>

        {/* <div className="page-inner mt--5">
          <div className="row row-card-no-pd  shadow-lg mt--2">
            <form method="POST" className="mt-4 login-form">
              <div className="row">
                <div className="col-lg-12">
                  <h5 className='text-underline'>SMTP Details</h5>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Company Email <span className="text-danger">*</span></label>
                    <div className="position-relative">
                      <input type="text" className=" form-control" placeholder="Company Email" required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>CC<span className="text-danger">*</span></label>
                    <div className="position-relative">
                      <input type="text" className=" form-control" id="email" placeholder="CC" required />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>BCC<span className="text-danger">*</span></label>
                    <div className="position-relative">
                      <input type="text" className=" form-control" id="email" placeholder="BCC" required />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>SMTP Password<span className="text-danger">*</span></label>
                    <div className="position-relative">
                      <input type="text" className=" form-control" id="email" placeholder="SMTP Password" required />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>SMTP Host<span className="text-danger">*</span></label>
                    <div className="position-relative">
                      <input type="text" className=" form-control" id="email" placeholder="SMTP Host" required />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>SMTP Port<span className="text-danger">*</span></label>
                    <div className="position-relative">
                      <input type="text" className=" form-control" id="email" placeholder="SMTP Port" required />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-3">
                  <h5 className='text-underline'>Company Details</h5>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label> Company Name<span className="text-danger">*</span></label>
                    <div className="position-relative">

                      <input type="text" className=" form-control" id="email" placeholder="Company Name" required />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Company Logo <span className="text-danger">*</span></label>
                    <div className="position-relative">

                      <input type="file" className=" form-control" placeholder="Company Logo" required />
                    </div>
                    <div className="form-group">
                      <img src='https://cdn1.designhill.com/uploads/personal_designs/thumbs/e182437c16928369e88891d2a1a3a964-25e3dfd576283ce1f9af3d7300dbb41d16794054416158.jpg?ver=2.12.43' style={{ width: '100px', height: '100px' }} />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4">

                </div> */}
        {/* <div className="col-lg-4">
                  <div className="form-group">
                    <label>Company Favicon <span className="text-danger">*</span></label>
                    <div className="position-relative">

                      <input type="file" className=" form-control" placeholder="Company Logo" required />
                    </div>
                    <div className="form-group">
                      <img src='https://cdn1.designhill.com/uploads/personal_designs/thumbs/e182437c16928369e88891d2a1a3a964-25e3dfd576283ce1f9af3d7300dbb41d16794054416158.jpg?ver=2.12.43' style={{ width: '100px', height: '100px' }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-0 col-lg-4">
                <button className="btn btn-primary btn-block pad" type="submit">Submit</button>
              </div>
            </form> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  )
}
