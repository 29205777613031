import React, { useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const ModalComponent = ({ isOpen, onClose, title, content, btn_name, callFunction , size}) => {
    return (
        <Modal  show={isOpen} onHide={onClose} centered size={size}>
            <Modal.Header closeButton className="p-3">
                <Modal.Title>{title}</Modal.Title>
                <hr className='bg-dark' />
            </Modal.Header>
            <Modal.Body className='px-2 py-0'>{content}</Modal.Body>
            <Modal.Footer>
                {/* <Button variant="primary" onClick={()=>callFunction()}>
                    {btn_name}
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ModalComponent;
