import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { DispatchLogin } from "../../../Layout/Auth/Login"
import { SignIn_function, GetCurrentDate, Forget_Password_SendLink, Forget_Password_Update  , Change_Password_Update} from "../../../Service/auth.service"




export const SignIn = createAsyncThunk("DispatchLogin", async (data) => {
  try {
    const res = await SignIn_function(data)
    return await res?.data;
  } catch (err) {
    console.log("error", err);
    // custom error
  }
});

export const Get_Current_Date = createAsyncThunk("DispatchLogin", async (data) => {
  try {
    const res = await GetCurrentDate(data)
    return await res?.data;
  } catch (err) {
    console.log("error", err);
    // custom error
  }
});
export const ForgetPasswordSendLink = createAsyncThunk("DispatchForgtPassword", async (data) => {
  try {
    const res = await Forget_Password_SendLink(data)
    return await res?.data;
  } catch (err) {
    console.log("error", err);
    // custom error
  }
});

export const ForgetPasswordUpdate = createAsyncThunk("DispatchForgetPasswordUpdate", async (data) => {
  try {
    const res = await Forget_Password_Update(data)
    return await res?.data;
  } catch (err) {
    console.log("error", err);
    // custom error
  }
});
export const ChangePassword = createAsyncThunk("DispatchChangePasswordUpdate", async (data) => {
  try {
    const res = await Change_Password_Update(data)
    return await res?.data;
  } catch (err) {
    console.log("error", err);
    // custom error
  }
});






const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: {
    isLoading: false,
    data: [],
    currentTime: "",
    forgetposswrodlink: "",
    updatepassword: "",
    changepassword: "",
    isError: false,
  },

  recuders: {},
  extraReducers: {

    [SignIn.pending]: (state, action) => {
      console.log("Pending")
    },
    [SignIn.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      return { ...state, data: payload };
    },
    [SignIn.rejected]: (state, action) => {
      console.log("Pending")
    },
    [Get_Current_Date.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      return { ...state, currentTime: payload };
    },
    [ForgetPasswordSendLink.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      return { ...state, forgetposswrodlink: payload };
    },
    [ForgetPasswordUpdate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      return { ...state, updatepassword: payload };
    },
    [ChangePassword.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      return { ...state, changepassword: payload };
    },

  },
});

// export const {ganpat} = HomeScreenSlice.actions
export default AuthSlice;