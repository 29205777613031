
import * as Config from "../Utils/Config";
import axios from "axios";
var CryptoJS = require("crypto-js");

var aliceBaseUrl = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/"


// var userId = "883538";
// var userSession = "2rThruHop21gjKaqPNJ3fKnxpk6voVcQujdDt2Vx5MMXQH0my9sneJLRCGNfJouGLdQGYy2CYg3lNGy6zhU8V25j2KqqjpicHHCNdpbXdnfE85SSotKXikSDZ331cs2fY1FVkTuq2ScjhH95Y4jxNCmZa8G6eH88BstnXIW9NoCEf0JOQ5j2KiUpj1g6STESMBcQjU9q2GyxUVhvDlJI4PxfXg1a3N5n14cArUJO12ryzEv0RO2M3cMWrWQSsBJb";

// var AuthorizationToken = `Bearer${userId}${userSession} `


//  get AliceBlueToken and Seccion Id

export function GetAliceTokenAndID(data) {

    return axios.get(`${Config.base_url}api/alicebluetoken`, Payload, {
        headers: {
            'Content-Type': 'application/json'

        },
        data: {},
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return error.response
        })
}


//  For Create Session
var Payload = { "loginType": "API" }



export function CreateSocketSession(userid, token, type) {

    return axios.post(`${aliceBaseUrl}ws/createSocketSess`, type, {
        headers: {
            'Authorization': `Bearer ${userid} ${token}`,
            // 'Authorization': Payload,
            // 'Access-Control-Allow-Origin' : "*",
            'Content-Type': 'application/json'
        },

    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


export async function ConnctSocket(userId, userSession, onResponse,channelList ) {

    const url = "wss://ws1.aliceblueonline.com/NorenWS/"
    let socket;
    socket = new WebSocket(url)
    socket.onopen = function () {
        var encrcptToken = CryptoJS.SHA256(CryptoJS.SHA256(userSession).toString()).toString();
        var initCon = {
            susertoken: encrcptToken,
            t: "c",
            actid: userId + "_" + "API",
            uid: userId + "_" + "API",
            source: "API"
        }
      
        socket.send(JSON.stringify(initCon))
    }
    socket.onmessage = async function (msg) {
        var response = JSON.parse(msg.data)
        await onResponse(response)

        if (response.s == 'OK') {
            // var channel = await channelList;
            let json = {
                k: channelList,
                t: 't'
            };
            await socket.send(JSON.stringify(json))


        }
    }



}

