import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Get_All_Admins, Add_Admins } from "../../../Service/superadmin.service"


export const GetAllAdmins = createAsyncThunk("superadmin/all_admins", async (data) => {


  try {
    const res = await Get_All_Admins(data)
    return await res;
  } catch (err) {
    console.log("error", err);
  }
});

export const addAdmin = createAsyncThunk("superadmin/add_admin", async (data) => {
  const { req, token } = data

  try {
    const res = await Add_Admins(req, token)
    return await res;
  } catch (err) {
    return await err;

    console.log("error", err);
  }
});

const SuperadminSlice = createSlice({
  name: "SuperadminSlice",
  initialState: {
    isLoading: false,
    alladmins: [],
    connectsession: [],
    addadmin: [],

  },

  recuders: {},
  extraReducers: {
    [addAdmin.pending]: (state, action) => {
      console.log("Pending")
      // state.isLoading = true;
    },
    [addAdmin.fulfilled]: (state, { payload }) => {
      //  state.isLoading = false;
      return { ...state, addadmin: payload };
    },
    [addAdmin.rejected]: (state) => {
      // state.isError = true;
    },
    [GetAllAdmins.fulfilled]: (state, { payload }) => {
      //  state.isLoading = false;
      return { ...state, alladmins: payload };
    },
    // [GetAllAdmins.pending]: (state, action) => {
    //   console.log("Pending")
    //   // state.isLoading = true;
    // },


  },
})

export const getAlladmins = (state) => state.SuperadminSlice.alladmins;
export const add_Admin = (state) => state.SuperadminSlice.addAdmin;
// export const connectsessiondata = (state) => state.LiveMarketData.connectsession;

export default SuperadminSlice;


