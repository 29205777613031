import React, { useEffect, useState } from 'react'
import Formikform from "../../../Utils/Formikform"
import { Formik, Form, Field, useFormik } from 'formik';
import * as  valid_err from "../../../Utils/Common_Messages"
import { toast } from "react-toastify";
import AlertToast from "../../../Utils/Alert_Toast"


import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Email_regex, Mobile_regex } from "../../../Utils/Common_regex"
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "../../../component/admin/admin-assets/css/style.css"

// import { FindOneChildUser, AddAdminsChilds, findOnchild, GetAllMastersName, getMasters, UpdateMasterChild } from "../../../ReduxStore/Slice/AdminChilds"
import { FindOneChildUser, AddAdminsChilds, findOnchild, GetAllMastersName, getMasters, UpdateMasterChild } from "../../../ReduxStore/Slice/AdminSlice/Common"

const EditClient = () => {

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const params_id = useParams()

  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const admin_id = JSON.parse(localStorage.getItem('user_details')).id;
  const RoleId = JSON.parse(localStorage.getItem('user_details')).roles[0];
  const getmasters = useSelector(getMasters)


  const getMastersName = async () => {
    await dispatch(GetAllMastersName({ AdminToken: AdminToken }))
    // await dispatch(FindOneChildUser({ id: params_id.id, token: AdminToken })).then((res) => {
    //   if (res.payload.status) {
    //     setuserdata(res.payload.data[0])
    //   }
    // })
  }


  useEffect(() => {
    getMastersName()
  }, [dispatch])



  const isValidEmail = (email) => {
    return Email_regex(email)
  }
  const isValidContact = (mobile) => {
    return Mobile_regex(mobile)
  }

  const setRoleId = (role) => {
    if (role === "ADMIN") {
      return "2"
    }
    else if (role === "USER") {
      return "4"
    }
    else if (role === "SUPERADMIN") {
      return "1"
    }
    else if (role === "MASTER") {
      return "2"
    }
  }





// console.log("location.state" ,location.state);
  const formik = useFormik({
    initialValues: {
      masterusername: location.state.username,
      fullName: location.state.fullname,
      masteremail: location.state.email,
      mobile: location.state.phone_number,
      broker: location.state.broker,
      licencetype: location.state.license_type,
      // tomonth: location.state.licence,
      masters: location.state.master_id,

      api_secret: location.state.api_secret,
      app_id: location.state.app_id,
      client_code: location.state.client_code,
      api_key: location.state.api_key,
      app_key: location.state.app_key,
      api_type: location.state.api_type,
      demat_userid: location.state.demat_userid,
      add_licence: "0"

    },
    validate: (values) => {
      const errors = {};
      if (!values.masterusername) {
        errors.masterusername = valid_err.USERNAME_ERROR;
      }
      if (!values.fullName) {
        errors.fullName = valid_err.FULLNAME_ERROR;
      }

      if (!values.mobile) {
        errors.mobile = valid_err.CONTACT_ERROR;
      } else if (!isValidContact(values.mobile)) {
        errors.mobile = valid_err.INVALID_CONTACT_ERROR;
      }
      if (!values.licencetype) {
        errors.licencetype = valid_err.LICENCE_TYPE_ERROR;
      }
      // if (!values.masters) {
      //   errors.masters = valid_err.MASTER_ERROR;
      // }
      // if (!values.add_licence) {
      //   errors.add_licence = valid_err.LICENCE_ERROR;
      // }
      // if (!values.broker) {
      //   errors.broker = valid_err.BROKER_ERROR;
      // }
      // if (!values.apisecret) {
      //   errors.apisecret = valid_err.APISECRET_ERROR;
      // }
      // if (!values.apikey) {
      //   errors.apikey = valid_err.APIKEY_ERROR;
      // }
      // if (!values.apiid) {
      //   errors.apiid = valid_err.APIID_ERROR;
      // }

      if (!values.masteremail) {
        errors.masteremail = valid_err.EMPTY_EMAIL_ERROR;
      } else if (!isValidEmail(values.masteremail)) {
        errors.masteremail = valid_err.INVALID_EMAIL_ERROR;
      }

      return errors;
    },
    onSubmit: async (values) => {
      // console.log("ressss", values);



      const req1 = {
        "id": params_id.id,
        "fullname": values.fullName,
        "username": values.masterusername,
        "email": values.masteremail,
        "phone_number": values.mobile,
        "license_type": "1",
        // "licence": location.state.licence,
        // "addlicence": values.tomonth,
        "roleId": "3",
        "master_id": "0",
        "parent_admin_id": admin_id,
        "parent_role_id": setRoleId(RoleId),
        "broker": values.broker,
        // "addlicence": parseInt(values.tomonth),
        "licence": location.state.licence,

        "demat_userid": values.demat_userid,
        "api_secret": values.api_secret,
        "app_id": values.app_id,
        "client_code": values.client_code,
        "api_key": values.api_key,
        "app_key": values.app_key,
        "api_type": values.api_type,
        "addlicence": "0",

      }



      // return
      await dispatch(UpdateMasterChild({ req: req1, AdminToken: AdminToken })).then((res) => {
        // console.log("repso", res);
        if (res.meta.requestStatus === "fulfilled") {
          toast.success(res.payload.data)
          setTimeout(() => {
            navigate("/admin/masters")
          }, 2000);
        }


      })
    }
  });



  const fields = [
    { name: 'masterusername', label: 'Username', type: 'text' },
    { name: 'fullName', label: 'FullName', type: 'text' },
    { name: 'masteremail', label: 'Email', type: 'text' },
    { name: 'mobile', label: 'Mobile', type: 'text' },

    {
      name: 'broker',
      label: 'Broker',
      type: 'select',
      options: [
        { label: 'Market Hub', value: '1' },
        // { label: 'Alice Blue', value: '2' },
        // { label: 'Master Trust', value: '3' },
        // { label: 'Motilal Oswal', value: '4' },
        // { label: 'Zebull', value: '5' },
        // { label: 'IIFl', value: '6' },
        // { label: 'Kotak', value: '7' },
        // { label: 'Mandot', value: '8' },
        // { label: 'Choice', value: '9' },
        // { label: 'Anand Rathi', value: '10' },
        // { label: 'B2C', value: '11' },
        // { label: 'Angel', value: '12' },
        // { label: 'Fyers', value: '13' },
        // { label: '5-Paisa', value: '14' },
        // { label: 'Zerodha', value: '15' },


      ],
    },
    // { name: 'tomonth', label: 'Previous Licence', type: 'text' },

    // {
    //   name: 'add_licence',
    //   label: 'Licence',
    //   type: 'select',
    //   options: [
    //     { label: '0', value: '0' },
    //     { label: '1', value: '1' },
    //     { label: '2', value: '2' },
    //     { label: '3', value: '3' },
    //     { label: '4', value: '4' },
    //     { label: '5', value: '5' },
    //     { label: '6', value: '6' },
    //     { label: '7', value: '7' },
    //     { label: '8', value: '8' },
    //     { label: '9', value: '9' },
    //     { label: '10', value: '10' },
    //     { label: '11', value: '11' },
    //     { label: '12', value: '12' },
    //   ],
    // },

    // --------------------------

    {
      name: 'api_key',
      label: formik.values.broker == 4 ? 'App Key' : formik.values.broker == 7 ? "Consumer Key" : formik.values.broker == 9 ? "Vendor Key" : formik.values.broker == 8 ? 'App Key' : formik.values.broker == 10 ? 'App Key' : "'Api Key", type: 'text',

      showWhen: values => values.broker === 4 || values.broker === 7 || values.broker === 8 || values.broker === 9 || values.broker === 10 || values.broker === 11 || values.broker === 12 || values.broker === 14 || values.broker === 15 || values.broker === 6
        || values.broker === '4' || values.broker === '7' || values.broker === '8' || values.broker === '9' || values.broker === '10' || values.broker === '11' || values.broker === '12' || values.broker === '14' || values.broker === '15' || values.broker === '6'
    },

    {
      name: 'client_code',
      label: formik.values.broker == 1 ? 'User' : formik.values.broker == 4 ? "Client Code" : formik.values.broker == 7 ? "User Name" : formik.values.broker == 9 ? "Vander Id" : formik.values.broker == 11 ? "Client Code" : 'User Id', type: 'text',
      showWhen: values => values.broker === 1 || values.broker === 5 || values.broker === 4 || values.broker === 7 || values.broker === 9 || values.broker === 11 || values.broker === 6

        || values.broker === '1' || values.broker === '5' || values.broker === '4' || values.broker === '7' || values.broker === '9' || values.broker === '11' || values.broker === '6'
    },
    {
      name: 'demat_userid',
      label: formik.values.broker == 9 ? 'User Id' : '', type: 'text',
      showWhen: values => values.broker === 9 || values.broker === '9'
    },


    {
      name: 'app_id',
      label: formik.values.broker == 1 ? 'Verification Code' : formik.values.broker == 5 ? 'Password' : formik.values.broker == 7 ? 'Demat Password' : formik.values.broker == 11 ? 'Password' : formik.values.broker == 13 ? 'App Id' : formik.values.broker == 9 ? 'Password' : formik.values.broker == 14 ? 'User Id ' : 'App Id', type: 'text',
      showWhen: values => values.broker === 2 || values.broker === 1 || values.broker === 3 || values.broker === 5 || values.broker === 7 || values.broker === 9 || values.broker === 11 || values.broker === 13 || values.broker === 14
        || values.broker === '2' || values.broker === '1' || values.broker === '3' || values.broker === '5' || values.broker === '7' || values.broker === '9' || values.broker === '11' || values.broker === '13' || values.broker === '14'
    },



    {
      name: 'app_key',
      label: formik.values.broker == 5 || 6 ? 'App Key' : "", type: 'text',
      showWhen: values => values.broker === 5 || values.broker === '5'
      // || values.broker === '6'
    },

    {
      name: 'api_secret',
      label: formik.values.broker == 1 ? 'Password Code' : formik.values.broker == 5 ? 'DOB' : formik.values.broker == 7 ? 'Consumer Secret' : formik.values.broker == 9 ? 'Encryption Secret Key' : formik.values.broker == 10 ? 'Api Secret Key' : formik.values.broker == 11 ? '2FA' : formik.values.broker == 14 ? 'Encryption Key' : 'Api Secret', type: 'text',
      showWhen: values => values.broker === 1
        || values.broker === 2 || values.broker === 3 || values.broker === 5 || values.broker === 6 || values.broker === 7 || values.broker === 8 || values.broker === 9 || values.broker === 10 || values.broker === 11 || values.broker === 13 || values.broker === 14 || values.broker === 15

        || values.broker === '1' || values.broker === '2' || values.broker === '3' || values.broker === '5' || values.broker === '6' || values.broker === '7' || values.broker === '8' || values.broker === '9' || values.broker === '10' || values.broker === '11' || values.broker === '13' || values.broker === '14' || values.broker === '15'
    },
    {
      name: 'api_type',
      label: formik.values.broker == 5 ? 'DOB' : formik.values.broker == 7 ? 'Trade Api Password' : formik.values.broker == 9 ? 'Encryption IV' : 'Api Secret', type: 'text',
      showWhen: values => values.broker === 3 || values.broker === 7 || values.broker === 9
        // || values.broker === '3'
        || values.broker === '7' || values.broker === '9'
    },

  ];


  // useEffect(() => {
  //   if (formik.values.broker === '1' || formik.values.broker === 1) {
  //     formik.setFieldValue('api_key', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '2' || formik.values.broker === 2) {
  //     formik.setFieldValue('api_key', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '3' || formik.values.broker === 3) {
  //     formik.setFieldValue('api_key', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '4' || formik.values.broker === 4) {
  //     formik.setFieldValue('api_secret', 'null');
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '5' || formik.values.broker === 5) {
  //     formik.setFieldValue('api_secret', 'null');
  //     formik.setFieldValue('api_key', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '6' || formik.values.broker === 6) {
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     // formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '7' || formik.values.broker === 7) {
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '8' || formik.values.broker === 8) {
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '9' || formik.values.broker === 9) {
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //   }

  //   if (formik.values.broker === '10' || formik.values.broker === 10) {
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  //   if (formik.values.broker === '11' || formik.values.broker === 11) {
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  //   if (formik.values.broker === '12' || formik.values.broker === 12) {
  //     formik.setFieldValue('api_secret', 'null');
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  //   if (formik.values.broker === '13' || formik.values.broker === 13) {
  //     formik.setFieldValue('api_key', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  //   if (formik.values.broker === '14' || formik.values.broker === 14) {
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  //   if (formik.values.broker === '15' || formik.values.broker === 15) {
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  // }, [formik.values.broker]);




  return (



    <div className="main-panel">
      <div className="content ">
        <div className="panel-header bg-primary-gradient">
          <div className="py-5 page-inner">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <h4 className="flex-grow-1 text-white fw-bold">Edit Master</h4>
              <Link to='/admin/masters' className='btn btn-light'>Back</Link>
            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row row-card-no-pd  shadow-lg mt--2">
            <div className='data-table-div'>
              <Formikform fieldtype={fields.filter(field => !field.showWhen || field.showWhen(formik.values))} formik={formik} btn_name="Update Master" />


              <AlertToast position="top-center" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditClient
