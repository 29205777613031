import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, useFormik } from 'formik';
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate, Link, useParams } from "react-router-dom";
import * as  valid_err from "../../Utils/Common_Messages"
import { Email_regex } from "../../Utils/Common_regex"
import { fDateTimeSuffix, dateFormatePlusThirty } from '../../Utils/DateFormat';

import { ForgetPasswordUpdate } from "../../ReduxStore/Slice/AuthSlice/AuthSlice"
import Formikform from "../../Utils/Formikform"
import { useDispatch, useSelector } from "react-redux";
import AlertToast from "../../Utils/Alert_Toast"
import { toast } from "react-toastify";

import base64 from "base-64"




const UpdatePassword = () => {



    const navigate = useNavigate()
    const { id } = useParams()
    const decodeUserId = base64.decode(id)

    console.log("id", base64.decode(id));


    const dispatch = useDispatch()
    // const AdminToken = JSON.parse(localStorage.getItem('admin')).accessToken;
    // const AdminToken = JSON.parse(localStorage.getItem('admin')).accessToken;

    const selector = useSelector((state) => state?.AuthSlice)

    const isValidEmail = (email) => {
        return Email_regex(email)
    }

    const formik = useFormik({
        initialValues: {
            newpassword: '',
            confirmpassword: '',
        },
        validate: (values) => {
            const errors = {};

            if (!values.newpassword) {
                errors.newpassword = valid_err.NEW_PASSWORD_ERROR;
            } if (!values.confirmpassword) {
                errors.confirmpassword = valid_err.CONFIRM_PASSWORD_ERROR;
            } else if (values.newpassword !== values.confirmpassword) {
                errors.confirmpassword = valid_err.CONFIRM_AND_NEW_PASSWORD_ERROR;
            }
            return errors;
        },
        onSubmit: async (values) => {

            await dispatch(ForgetPasswordUpdate({ id: decodeUserId, newpassword: values.newpassword, confirmpassword: values.confirmpassword })).then((res) => {
                console.log("localStorage", res);
                if (res.payload.status) {
                    toast.success(res.payload.message)
                    setTimeout(() => {
                       navigate("/login", { replace: true })
                    }, 2000);
                }
                else {
                    toast.error(res.payload.message)
                }
            })


        },
    });

    const fields = [
        { name: 'newpassword', label: 'New Password', type: 'password' },
        { name: 'confirmpassword', label: 'Confirm Password', type: 'password' },
    ];
    return (

        <section style={{ height: '100vh', background: '#ebf8fc', overflowY: 'hidden' }} className="d-flex align-items-center justify-content-center">
            <div className="container  ">
                <div className="row mt-5 d-flex align-items-center justify-content-center">
                    <div className="col-md-4">
                        <div className="card rounded border ">
                            <div className="card-body">
                                <h4 className="text-center card-title">Update Password</h4>
                                <div className="row">
                                    <div className="col-12 my-3">
                                        <div className="text-center">
                                        </div>
                                        <Formikform fieldtype={fields} formik={formik} btn_name="Update" />
                                        <AlertToast position="top-center" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default UpdatePassword