
// import React, { useState, useEffect } from 'react';
// import { GetBrokerAccessToken, accesstoken, GetTradingStatus, tradingstatus, UserTradingstatusOff, } from "../../ReduxStore/Slice/UsersSlice/dashboardSlice"
// // import { FindOneChildUser, findOnchild } from "../../ReduxStore/Slice/AdminChilds"
// import { FindOneChildUser, findOnchild, } from "../../ReduxStore/Slice/AdminSlice/Common"
// import { UpadateBrokerAPiKey, zebullAccessToken , GetKotakAccessToken } from "../../ReduxStore/Slice/UsersSlice/CommonSlice/CommonSlice"
// import Model from "../../component/ReusableComponents/Model/Model"
// import { GetUserAvailableFund, fundetails, IIFLAccessToken } from "../../ReduxStore/Slice/UsersSlice/CommonSlice/CommonSlice"

// import { connect } from 'react-redux'

// import jwtDecode from "jwt-decode";
// import { useDispatch, useSelector } from "react-redux";
// import AlertToast from "../../Utils/Alert_Toast"
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

// import Button from 'react-bootstrap/Button';
// // import Modal from 'react-bootstrap/Modal';

// import { Formik, Form, Field, useFormik } from 'formik';
// import * as  valid_err from "../../Utils/Common_Messages"
// import Formikform from "../../Utils/Formikform"





// const Nav_Header = () => {

//   const navigate = useNavigate()
//   const dispatch = useDispatch()


//   const [showDropdown, setShowDropdown] = useState(false)

//   // for model show hide
//   const [modalOpen, setModalOpen] = useState(false);


//   const userid = JSON.parse(localStorage.getItem('user_details')).id;
//   const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
//   const getAccessTokendata = useSelector(accesstoken)
//   const gettradingstatus = useSelector(tradingstatus && tradingstatus)

//   const onchild = useSelector(findOnchild != undefined && findOnchild)

//   // console.log("gettradingstatus", onchild);


//   const [isMinimized, setIsMinimized] = useState(false);
//   const [componentRerender, setComponentRerender] = useState(false);









//   const getUserProfile = () => {
//     dispatch(FindOneChildUser({ id: userid, AdminToken: AdminToken })).then((res) => {
//       if (res.meta.requestStatus === "fulfilled") {
//         // setShowUser(res.payload.data[0])
//       }
//     })

//   }

//   useEffect(() => {
//     getUserProfile()
//   }, [dispatch])


//   const getUserFund = () => {
//     dispatch(GetUserAvailableFund({ id: userid, AdminToken: AdminToken }))
//   }

//   useEffect(() => {
//     getUserFund()
//   }, [dispatch, componentRerender])


//   // for dropdown 




//   const LoginInWithApi = async (value, brokername, api_key, app_id) => {

//     // console.log("brokername", brokername);
//     // return
//     if (value) {
//       if (brokername == 1) {
//         return dispatch(GetBrokerAccessToken({ client_id: userid, AdminToken: AdminToken })).then((res) => {
//           setComponentRerender(!componentRerender)
//           if (res.payload.status) {
//             toast.success(res.payload.data)
//           }
//           else if (res.payload.data.status == 401) {
//             toast.error("Invalid Credential")
//           }
//         })
//         // dispatch(GetTradingStatus({ id: userid }))

//       } else if (brokername == 2) {
//         window.location.href = `https://ant.aliceblueonline.com/?appcode=${app_id}`;
//         // console.log("Alice Blue");

//       }
//       else if (brokername == 3) {
//         // console.log("Master Trust'");
//       }
//       else if (brokername == 4) {
//         window.location.href = `https://invest.motilaloswal.com/OpenAPI/Login.aspx?apikey=${api_key}`
//         // console.log("Motilal Oswal");
//       }
//       else if (brokername == 5) {
//         // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
//         // console.log("Zebull");

//       }
//       else if (brokername == 6) {
//         await dispatch(IIFLAccessToken({ client_id: userid, AdminToken: AdminToken }))
//         // console.log("IIFl");

//       }
//       else if (brokername == 7) {
//         // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
//         // console.log("Kotak");

//         await dispatch(GetKotakAccessToken({ client_id: userid, AdminToken: AdminToken })).then(async (res) => {
//           console.log("res", res);
//           if (res.payload.status) {
//             if (res.payload.data.error == 'invalid_client') {
//               toast.error("invalid_client")
//             } else if (res.payload.data.message == "Request failed with status code 401") {
//               toast.error("Access Token IS expired")
//             } else if (res.payload.data.message == "Authentication Successful.") {
//               let value = prompt('Enter Your Access Code');
//               if (value === null) {
//                 return;
//               }
//               await dispatch(PassKotakTokenToSession({ client_id: userid, otp: value, AdminToken: AdminToken })).then((res1) => {
//                 if (res1.payload.status) {
            
//                   setComponentRerender(!componentRerender)
//                   toast.success(res1.payload.data)
//                 } else {
//                   setComponentRerender(!componentRerender)
//                   toast.error(res1.payload.data)
//                 }
//               })
//             }
//           }
//           else {
//             if(res.payload.data.status  == 503){
//               toast.error(res.payload.data.message)
//             }



//           }

//         })


//       }
//       else if (brokername == 8) {
//         // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
//         // console.log("Mandot");

//       }
//       else if (brokername == 9) {
//         // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
//         // console.log("Choice");
//       }
//       else if (brokername == 10) {
//         // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
//         // console.log("Anand Rathi");
//       }
//       else if (brokername == 11) {
//         // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
//         // console.log("B2C");
//       }
//       else if (brokername == 12) {
//         // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
//         // console.log("Angel");
//       }
//       else if (brokername == 13) {
//         // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
//         // console.log("Fyers");
//       }
//       else if (brokername == 14) {
//         // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
//         // console.log("5-Paisa");
//       }
//       else if (brokername == 15) {
//         // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
//         // console.log("Zerodha");
//       }
//     }

//     else if (value == false) {
//       return dispatch(UserTradingstatusOff({ id: userid, AdminToken: AdminToken })).then((res) => {
//         setComponentRerender(!componentRerender)
//         if (res.payload.status) {
//           toast.success(res.payload.data)
//         }
//       })
//     }

//   }

//   const { exp } = jwtDecode(AdminToken);

//   const ExpirationTime = exp * 1000 - 60000;

//   if (Date.now() >= ExpirationTime) {
//     localStorage.removeItem("user_details");
//     navigate("/login");
//   }

//   const Logout = () => {
//     localStorage.removeItem("user_details");
//     navigate("/login");
//   };





//   // GetBrokerAccessToken
//   useEffect(() => {
//     dispatch(GetTradingStatus({ id: userid, AdminToken: AdminToken }))
//   }, [dispatch, componentRerender])






//   const handleClick = () => {
//     setIsMinimized(!isMinimized);
//   };

//   useEffect(() => {
//     if (isMinimized) {
//       document.body.classList.add('sidebar_show-client');
//     } else {
//       document.body.classList.remove('sidebar_show-client');
//     }
//   }, [isMinimized]);






//   const formik = useFormik({
//     initialValues: {
//       setclient_code: null,
//       setdemat_userid: null,
//       setapp_id: null,
//       setapp_key: null,
//       setapi_key: null,
//       setapi_type: null,
//     },
//     validate: (values) => {
//       const errors = {};
//       // if (!values.setapikey) {
//       //   errors.setapikey = valid_err.APIKEY_ERROR;
//       // }
//       // if (!values.setappid) {
//       //   errors.setappid = valid_err.APIID_ERROR;
//       // }
//       // if (!values.setapisecret) {
//       //   errors.setapisecret = valid_err.APISECRET_ERROR;
//       // }

//       return errors;
//     },
//     onSubmit: async (values) => {
//       // console.log("test", values);

//       const req = {
//         "api_secret": values.setapi_secret,
//         "demat_userid": values.setdemat_userid,
//         "client_code": values.setclient_code,
//         "app_id": values.setapp_id,
//         "app_key": values.app_key,
//         "api_key": values.setapi_key,
//         "api_type": values.setapi_type,
//         "id": userid,
//       }

//       await dispatch(UpadateBrokerAPiKey({ req: req, AdminToken: AdminToken })).then((res) => {
//         if (res.meta.requestStatus === "fulfilled") {
//           toast.success(res.payload.data)
//           setTimeout(() => {
//             setModalOpen(false)
//           }, 1000);
//         }

//       })


//     }
//   });

//   const fields = [

//     {
//       name: 'setapi_key',
//       label: formik.values.broker == 4 ? 'App Key' : formik.values.broker == 7 ? "Consumer Key" : formik.values.broker == 9 ? "Vendor Key" : formik.values.broker == 8 ? 'App Key' : formik.values.broker == 10 ? 'App Key' : "'Api Key", type: 'text',

//       showWhen: values => values.broker === 4 || values.broker === 7 || values.broker === 8 || values.broker === 9 || values.broker === 10 || values.broker === 11 || values.broker === 12 || values.broker === 14 || values.broker === 15 || values.broker === 6
//         || values.broker === '4' || values.broker === '7' || values.broker === '8' || values.broker === '9' || values.broker === '10' || values.broker === '11' || values.broker === '12' || values.broker === '14' || values.broker === '15' || values.broker === '6'
//     },

//     {
//       name: 'setclient_code',
//       label: formik.values.broker == 1 ? 'User' : formik.values.broker == 4 ? "Client Code" : formik.values.broker == 7 ? "User Name" : formik.values.broker == 9 ? "Vander Id" : formik.values.broker == 11 ? "Client Code" : 'User Id', type: 'text',
//       showWhen: values => values.broker === 1 || values.broker === 5 || values.broker === 4 || values.broker === 7 || values.broker === 9 || values.broker === 11|| values.broker === 6

//         || values.broker === '1' || values.broker === '5' || values.broker === '4' || values.broker === '7' || values.broker === '9' || values.broker === '11' || values.broker === "6"
//     },
//     {
//       name: 'setdemat_userid',
//       label: formik.values.broker == 9 ? 'User Id' : '', type: 'text',
//       showWhen: values => values.broker === 9 || values.broker === '9'
//     },


//     {
//       name: 'setapp_id',
//       label: formik.values.broker == 1 ? 'Verification Code' : formik.values.broker == 5 ? 'Password' : formik.values.broker == 7 ? 'Demat Password' : formik.values.broker == 11 ? 'Password' : formik.values.broker == 13 ? 'App Id' : formik.values.broker == 9 ? 'Password' : formik.values.broker == 14 ? 'User Id ' : 'App Id', type: 'text',
//       showWhen: values => values.broker === 2 || values.broker === 1 || values.broker === 3 || values.broker === 5 || values.broker === 7 || values.broker === 9 || values.broker === 11 || values.broker === 13 || values.broker === 14
//         || values.broker === '2' || values.broker === '1' || values.broker === '3' || values.broker === '5' || values.broker === '7' || values.broker === '9' || values.broker === '11' || values.broker === '13' || values.broker === '14'
//     },



//     {
//       name: 'setapp_key',
//       label: formik.values.broker == 5 || 6 ? 'App Key' : "", type: 'text',
//       showWhen: values => values.broker === 5 || values.broker === '5' || values.broker === '6'
//     },

//     {
//       name: 'setapi_secret',
//       label: formik.values.broker == 1 ? 'Password Code' : formik.values.broker == 5 ? 'DOB' : formik.values.broker == 7 ? 'Consumer Secret' : formik.values.broker == 9 ? 'Encryption Secret Key' : formik.values.broker == 10 ? 'Api Secret Key' : formik.values.broker == 11 ? '2FA' : formik.values.broker == 14 ? 'Encryption Key' : 'Api Secret', type: 'text',
//       showWhen: values => values.broker === 1
//         || values.broker === 2 || values.broker === 3 || values.broker === 5 || values.broker === 6 || values.broker === 7 || values.broker === 8 || values.broker === 9 || values.broker === 10 || values.broker === 11 || values.broker === 13 || values.broker === 14 || values.broker === 15

//         || values.broker === '1' || values.broker === '2' || values.broker === '3' || values.broker === '5' || values.broker === '6' || values.broker === '7' || values.broker === '8' || values.broker === '9' || values.broker === '10' || values.broker === '11' || values.broker === '13' || values.broker === '14' || values.broker === '15'
//     },
//     {
//       name: 'setapi_type',
//       label: formik.values.broker == 5 ? 'DOB' : formik.values.broker == 7 ? 'Consumer Secret' : formik.values.broker == 9 ? 'Encryption IV' : 'Api Secret', type: 'text',
//       showWhen: values => values.broker === 3 || values.broker === 7 || values.broker === 9 ||
//         values.broker === '3' || values.broker === '7' || values.broker === '9'
//     },


//   ];



//   useEffect(() => {
//     if (onchild && onchild.length > 0) {
//       formik.setValues({
//         setapi_secret: onchild[0].api_secret || null,
//         setclient_code: onchild[0].client_code || null,
//         setdemat_userid: onchild[0].demat_userid || null,
//         setapp_id: onchild[0].app_id || null,
//         setapp_key: onchild[0].app_key || null,
//         setapi_key: onchild[0].api_key || null,
//         setapi_type: onchild[0].api_type || null,

//         broker: onchild[0].broker,

//       });
//     }
//   }, [onchild, formik.setValues]);




//   return (
//     <>

//       <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
//         <div className="container-fluid">
//           {/* Brand + Toggler (for mobile devices) */}
//           <div className='row w-100 client-nav-row'>
//             <div className='col-md-4 col-4 ps-4 ps-md-5 d-flex'>
//               <a className="navbar-brand" href="#">
//                 <img src="../logolight.png" className="navbar-brand-img" alt="logo" />
//               </a>
//               <div className="nav-toggle mt-2">
//                 <button onClick={handleClick} className="btn btn-toggle toggle-sidebar">
//                   <i className="fa-solid fa-bars"></i>
//                 </button>
//               </div>
//             </div>
//             <div className='col-md-8 col-8'>
//               <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
//                 {/* Right menu */}

//                 <ul className="navbar-nav ml-lg-auto align-items-center  d-flex justify-content-end">

//                   <li className="nav-item">
//                     <div className="form-check form-switch">
//                       <input className="form-check-input"
//                         type="checkbox"
//                         role="switch"
//                         id="flexSwitchCheckDefault"
//                         checked={gettradingstatus !== undefined && gettradingstatus.trading_type == 1 ? true : false}
//                         onChange={(e) => LoginInWithApi(e.target.checked, onchild && onchild[0].broker, onchild && onchild[0].api_key, onchild && onchild[0].app_id)}
//                       />
//                       <label className="form-check-label text-white" for="flexSwitchCheckDefault">Login With API </label>
//                     </div>
//                   </li>
//                   <a className="mr-2 btn btn-primary " onClick={() => setModalOpen(true)}>
//                     setApiKey
//                   </a>




//                   <li class=" dropdown dropdown-animate">
//                     <a class="nav-link pr-lg-0" role="button" data-toggle="dropdown"
//                       aria-haspopup="true" aria-expanded="false" onClick={() => setShowDropdown(!showDropdown)}>
//                       <div class="media media-pill align-items-center">
//                         <span class="avatar rounded-circle">
//                           <i class="fas fa-user-circle fa-2x"></i>
//                         </span>
//                         <div class="ml-2 d-none d-lg-block">
//                           <span className="mb-0 text-sm font-weight-bold text-white ">{onchild && onchild[0].fullname}</span>
//                         </div>
//                       </div>
//                     </a>
//                     <div class={`dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow ${showDropdown ? "show" : ""}`} >
//                       <h6 class="px-0 dropdown-header">Hi, {onchild && onchild[0].fullname}</h6>
//                       <Link to="/child/profile" className="dropdown-item">
//                         <i class="far fa-user"></i>
//                         My profile
//                       </Link>
//                       <div class="dropdown-divider"></div>
//                       <li className="dropdown-item" onClick={() => Logout()} >
//                         <i class="fa fa-sign-out-alt"></i>
//                         Logout
//                       </li>
//                     </div>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           {/* Navbar nav */}

//           <AlertToast Transition="zoom" position="top-center" />

//           {/* MODEL APIKEY MODEL */}
//           <Model isOpen={modalOpen} onClose={() => setModalOpen(false)} title="Set Broker Api Key" btn_name="Uupa"
//             content={
//               // <Formikform fieldtype={fields} formik={formik} btn_name="Update Key" />
//               <Formikform fieldtype={fields.filter(field => !field.showWhen || field.showWhen(formik.values))} formik={formik} btn_name="Update" />
//             }

//           />





//         </div>

//       </nav >



//     </>
//   )
// }

// export default Nav_Header










import React, { useState, useEffect } from 'react';
import { GetBrokerAccessToken, accesstoken, GetTradingStatus, tradingstatus, UserTradingstatusOff, } from "../../ReduxStore/Slice/UsersSlice/dashboardSlice"
// import { FindOneChildUser, findOnchild } from "../../ReduxStore/Slice/AdminChilds"
import { FindOneChildUser, findOnchild, } from "../../ReduxStore/Slice/AdminSlice/Common"
import { UpadateBrokerAPiKey, zebullAccessToken } from "../../ReduxStore/Slice/UsersSlice/CommonSlice/CommonSlice"
import Model from "../../component/ReusableComponents/Model/Model"
import { GetUserAvailableFund, fundetails, IIFLAccessToken, GetKotakAccessToken, PassKotakTokenToSession, kotakAccessData } from "../../ReduxStore/Slice/UsersSlice/CommonSlice/CommonSlice"

// import { GET_KOTAK_SECURITY_ACCESS_TOKEN , GET_MARKET_HUB_ACCESS_TOKEN , GET_ALICE_BLUE_ACCESS_TOKEN , GET_MASTER_TRUST_ACCESS_TOKEN , GET_MOTILAL_OSWAL_ACCESS_TOKEN , GET_ZEBULL_ACCESS_TOKEN , GET_IIFL_ACCESS_TOKEN , GET_MANDOT_SECURITY_ACCESS_TOKEN , GET_CHOICE_ACCESS_TOKEN , GET_ANAND_RATHI_ACCESS_TOKEN , GET_B2C_ACCESS_TOKEN , GET_ANGEL_ACCESS_TOKEN , GET_FYERS_ACCESS_TOKEN , GET_FIVE_PAISA_ACCESS_TOKEN , } from "../ReusableComponents/GetBrokerAcessToken/GetBrokerAcessToken"

import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import AlertToast from "../../Utils/Alert_Toast"
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';

import { Formik, Form, Field, useFormik } from 'formik';
import * as  valid_err from "../../Utils/Common_Messages"
import Formikform from "../../Utils/Formikform"





const Nav_Header = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()


  const [showDropdown, setShowDropdown] = useState(false)

  // for model show hide
  const [modalOpen, setModalOpen] = useState(false);


  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const getAccessTokendata = useSelector(accesstoken)
  const gettradingstatus = useSelector(tradingstatus && tradingstatus)
  const onchild = useSelector(findOnchild != undefined && findOnchild)



  const [isMinimized, setIsMinimized] = useState(false);
  const [componentRerender, setComponentRerender] = useState(false);









  const getUserProfile = () => {
    dispatch(FindOneChildUser({ id: userid, AdminToken: AdminToken })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        // setShowUser(res.payload.data[0])
      }
    })

  }

  useEffect(() => {
    getUserProfile()
  }, [dispatch])


  const getUserFund = () => {
    dispatch(GetUserAvailableFund({ id: userid, AdminToken: AdminToken }))
  }

  useEffect(() => {
    getUserFund()
  }, [dispatch, componentRerender])


  // for dropdown 




  const LoginInWithApi = async (value, brokername, api_key, app_id) => {

    // console.log("brokername", brokername);
    // return
    if (value) {
      if (brokername == 1) {
        // await GET_MARKET_HUB_ACCESS_TOKEN(userid, AdminToken)

        return dispatch(GetBrokerAccessToken({ client_id: userid, AdminToken: AdminToken })).then((res) => {
          setComponentRerender(!componentRerender)
          if (res.payload.status) {
            toast.success(res.payload.data)
          }
          else if (res.payload.data.status == 401) {
            toast.error("Invalid Credential")
          }
        })

        // dispatch(GetTradingStatus({ id: userid }))

      } else if (brokername == 2) {

        window.location.href = `https://ant.aliceblueonline.com/?appcode=${app_id}`;
        console.log("Alice Blue");

      }
      else if (brokername == 3) {
        console.log("Master Trust'");
      }
      else if (brokername == 4) {
        window.location.href = `https://invest.motilaloswal.com/OpenAPI/Login.aspx?apikey=${api_key}`

        console.log("Motilal Oswal");
      }
      else if (brokername == 5) {
        // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
        console.log("Zebull");

      }
      else if (brokername == 6) {
        await dispatch(IIFLAccessToken({ client_id: userid, AdminToken: AdminToken }))
        console.log("IIFl");

      }
      // Kotak Security
      else if (brokername == 7) {
        // await GET_KOTAK_SECURITY_ACCESS_TOKEN(userid, AdminToken, dispatch)
        await dispatch(GetKotakAccessToken({ client_id: userid, AdminToken: AdminToken })).then(async (res) => {
          console.log("res", res);
          if (res.payload.status) {
            if (res.payload.data.error == 'invalid_client') {
              toast.error("invalid_client")
            } else if (res.payload.data.message == "Request failed with status code 401") {
              toast.error("Access Token IS expired")
            } else if (res.payload.data.message == "Authentication Successful.") {
              let value = prompt('Enter Your Access Code');
              if (value === null) {
                return;
              }
              await dispatch(PassKotakTokenToSession({ client_id: userid, otp: value, AdminToken: AdminToken })).then((res1) => {
                if (res1.payload.status) {
            
                  setComponentRerender(!componentRerender)
                  toast.success(res1.payload.data)
                } else {
                  setComponentRerender(!componentRerender)
                  toast.error(res1.payload.data)
                }
              })
            }
          }
          else {
            if(res.payload.data.status  == 503){
              toast.error(res.payload.data.message)
            }



          }

        })
      }
      else if (brokername == 8) {
        // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
        console.log("Mandot");

      }
      else if (brokername == 9) {
        // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
        console.log("Choice");
      }
      else if (brokername == 10) {
        // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
        console.log("Anand Rathi");
      }
      else if (brokername == 11) {
        // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
        console.log("B2C");
      }
      else if (brokername == 12) {
        // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
        console.log("Angel");
      }
      else if (brokername == 13) {
        // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
        console.log("Fyers");
      }
      else if (brokername == 14) {
        // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
        console.log("5-Paisa");
      }
      else if (brokername == 15) {
        // await dispatch(zebullAccessToken({ id: userid, AdminToken: AdminToken }))
        console.log("Zerodha");
      }
    }

    else if (value == false) {
      return dispatch(UserTradingstatusOff({ id: userid, AdminToken: AdminToken })).then((res) => {
        setComponentRerender(!componentRerender)
        if (res.payload.status) {
          toast.success(res.payload.data)
        }
      })
    }

  }

  const { exp } = jwtDecode(AdminToken);

  const ExpirationTime = exp * 1000 - 60000;

  if (Date.now() >= ExpirationTime) {
    localStorage.removeItem("user_details");
    navigate("/login");
  }

  const Logout = () => {
    localStorage.removeItem("user_details");
    navigate("/login");
  };





  // GetBrokerAccessToken
  useEffect(() => {
    dispatch(GetTradingStatus({ id: userid, AdminToken: AdminToken }))
  }, [dispatch, componentRerender])






  const handleClick = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() => {
    if (isMinimized) {
      document.body.classList.add('sidebar_show-client');
    } else {
      document.body.classList.remove('sidebar_show-client');
    }
  }, [isMinimized]);






  const formik = useFormik({
    initialValues: {
      setclient_code: null,
      setdemat_userid: null,
      setapp_id: null,
      setapp_key: null,
      setapi_key: null,
      setapi_type: null,
    },
    validate: (values) => {
      const errors = {};
      // if (!values.setapikey) {
      //   errors.setapikey = valid_err.APIKEY_ERROR;
      // }
      // if (!values.setappid) {
      //   errors.setappid = valid_err.APIID_ERROR;
      // }
      // if (!values.setapisecret) {
      //   errors.setapisecret = valid_err.APISECRET_ERROR;
      // }

      return errors;
    },
    onSubmit: async (values) => {
      // console.log("test", values);

      const req = {
        "api_secret": values.setapi_secret,
        "demat_userid": values.setdemat_userid,
        "client_code": values.setclient_code,
        "app_id": values.setapp_id,
        "app_key": values.app_key,
        "api_key": values.setapi_key,
        "api_type": values.setapi_type,
        "id": userid,
      }

      await dispatch(UpadateBrokerAPiKey({ req: req, AdminToken: AdminToken })).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success(res.payload.data)
          setTimeout(() => {
            setModalOpen(false)
          }, 1000);
        }

      })


    }
  });

  const fields = [

    {
      name: 'setapi_key',
      label: formik.values.broker == 4 ? 'App Key' : formik.values.broker == 7 ? "Consumer Key" : formik.values.broker == 9 ? "Vendor Key" : formik.values.broker == 8 ? 'App Key' : formik.values.broker == 10 ? 'App Key' : "'Api Key", type: 'text',

      showWhen: values => values.broker === 4 || values.broker === 7 || values.broker === 8 || values.broker === 9 || values.broker === 10 || values.broker === 11 || values.broker === 12 || values.broker === 14 || values.broker === 15 || values.broker === 6
        || values.broker === '4' || values.broker === '7' || values.broker === '8' || values.broker === '9' || values.broker === '10' || values.broker === '11' || values.broker === '12' || values.broker === '14' || values.broker === '15' || values.broker === '6'
    },

    {
      name: 'setclient_code',
      label: formik.values.broker == 1 ? 'User' : formik.values.broker == 4 ? "Client Code" : formik.values.broker == 7 ? "User Name" : formik.values.broker == 9 ? "Vander Id" : formik.values.broker == 11 ? "Client Code" : 'User Id', type: 'text',
      showWhen: values => values.broker === 1 || values.broker === 5 || values.broker === 4 || values.broker === 7 || values.broker === 9 || values.broker === 11 || values.broker === 6

        || values.broker === '1' || values.broker === '5' || values.broker === '4' || values.broker === '7' || values.broker === '9' || values.broker === '11' || values.broker === "6"
    },
    {
      name: 'setdemat_userid',
      label: formik.values.broker == 9 ? 'User Id' : '', type: 'text',
      showWhen: values => values.broker === 9 || values.broker === '9'
    },


    {
      name: 'setapp_id',
      label: formik.values.broker == 1 ? 'Verification Code' : formik.values.broker == 5 ? 'Password' : formik.values.broker == 7 ? 'Demat Password' : formik.values.broker == 11 ? 'Password' : formik.values.broker == 13 ? 'App Id' : formik.values.broker == 9 ? 'Password' : formik.values.broker == 14 ? 'User Id ' : 'App Id', type: 'text',
      showWhen: values => values.broker === 2 || values.broker === 1 || values.broker === 3 || values.broker === 5 || values.broker === 7 || values.broker === 9 || values.broker === 11 || values.broker === 13 || values.broker === 14
        || values.broker === '2' || values.broker === '1' || values.broker === '3' || values.broker === '5' || values.broker === '7' || values.broker === '9' || values.broker === '11' || values.broker === '13' || values.broker === '14'
    },



    {
      name: 'setapp_key',
      label: formik.values.broker == 5 || 6 ? 'App Key' : "", type: 'text',
      showWhen: values => values.broker === 5 || values.broker === '5' || values.broker === '6'
    },

    {
      name: 'setapi_secret',
      label: formik.values.broker == 1 ? 'Password Code' : formik.values.broker == 5 ? 'DOB' : formik.values.broker == 7 ? 'Consumer Secret' : formik.values.broker == 9 ? 'Encryption Secret Key' : formik.values.broker == 10 ? 'Api Secret Key' : formik.values.broker == 11 ? '2FA' : formik.values.broker == 14 ? 'Encryption Key' : 'Api Secret', type: 'text',
      showWhen: values => values.broker === 1
        || values.broker === 2 || values.broker === 3 || values.broker === 5 || values.broker === 6 || values.broker === 7 || values.broker === 8 || values.broker === 9 || values.broker === 10 || values.broker === 11 || values.broker === 13 || values.broker === 14 || values.broker === 15

        || values.broker === '1' || values.broker === '2' || values.broker === '3' || values.broker === '5' || values.broker === '6' || values.broker === '7' || values.broker === '8' || values.broker === '9' || values.broker === '10' || values.broker === '11' || values.broker === '13' || values.broker === '14' || values.broker === '15'
    },
    {
      name: 'setapi_type',
      label: formik.values.broker == 5 ? 'DOB' : formik.values.broker == 7 ? 'Trade Api Password *' : formik.values.broker == 9 ? 'Encryption IV' : 'Api Secret', type: 'text',
      showWhen: values => values.broker === 3 || values.broker === 7 || values.broker === 9 ||
        values.broker === '3' || values.broker === '7' || values.broker === '9'
    },


  ];



  useEffect(() => {
    if (onchild && onchild.length > 0) {
      formik.setValues({
        setapi_secret: onchild[0].api_secret || null,
        setclient_code: onchild[0].client_code || null,
        setdemat_userid: onchild[0].demat_userid || null,
        setapp_id: onchild[0].app_id || null,
        setapp_key: onchild[0].app_key || null,
        setapi_key: onchild[0].api_key || null,
        setapi_type: onchild[0].api_type || null,

        broker: onchild[0].broker,

      });
    }
  }, [onchild, formik.setValues]);




  return (
    <>

      <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
        <div className="container-fluid">
          {/* Brand + Toggler (for mobile devices) */}
          <div className='row w-100 client-nav-row'>
            <div className='col-md-4 col-4 ps-4 ps-md-5 d-flex'>
              <a className="navbar-brand" href="#">
                <img src="../logolight.png" className="navbar-brand-img" alt="logo" />
              </a>
              <div className="nav-toggle mt-2">
                <button onClick={handleClick} className="btn btn-toggle toggle-sidebar">
                  <i className="fa-solid fa-bars"></i>
                </button>
              </div>
            </div>
            <div className='col-md-8 col-8'>
              <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                {/* Right menu */}

                <ul className="navbar-nav ml-lg-auto align-items-center  d-flex justify-content-end">

                  <li className="nav-item">
                    <div className="form-check form-switch">
                      <input className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        checked={gettradingstatus !== undefined && gettradingstatus.trading_type == 1 ? true : false}
                        onChange={(e) => LoginInWithApi(e.target.checked, onchild && onchild[0].broker, onchild && onchild[0].api_key, onchild && onchild[0].app_id)}
                      />
                      <label className="form-check-label text-white" for="flexSwitchCheckDefault">Login With API </label>
                    </div>
                  </li>
                  <a className="mr-2 btn btn-primary " onClick={() => setModalOpen(true)}>
                    setApiKey
                  </a>




                  <li class=" dropdown dropdown-animate">
                    <a class="nav-link pr-lg-0" role="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false" onClick={() => setShowDropdown(!showDropdown)}>
                      <div class="media media-pill align-items-center">
                        <span class="avatar rounded-circle">
                          <i class="fas fa-user-circle fa-2x"></i>
                        </span>
                        <div class="ml-2 d-none d-lg-block">
                          <span className="mb-0 text-sm font-weight-bold text-white ">{onchild && onchild[0].fullname}</span>
                        </div>
                      </div>
                    </a>
                    <div class={`dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow ${showDropdown ? "show" : ""}`} >
                      <h6 class="px-0 dropdown-header">Hi, {onchild && onchild[0].fullname}</h6>
                      <Link to="profile" className="dropdown-item">
                        <i class="far fa-user"></i>
                        My profile
                      </Link>
                      <div class="dropdown-divider"></div>
                      <li className="dropdown-item" onClick={() => Logout()} >
                        <i class="fa fa-sign-out-alt"></i>
                        Logout
                      </li>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Navbar nav */}

          <AlertToast Transition="zoom" position="top-center" />

          {/* MODEL APIKEY MODEL */}
          <Model isOpen={modalOpen} onClose={() => setModalOpen(false)} title="Set Broker Api Key" btn_name="Uupa"
            content={
              // <Formikform fieldtype={fields} formik={formik} btn_name="Update Key" />
              <Formikform fieldtype={fields.filter(field => !field.showWhen || field.showWhen(formik.values))} formik={formik} btn_name="Update" />
            }

          />





        </div>

      </nav >



    </>
  )
}

export default Nav_Header