import React, { useEffect, useState } from 'react'
import Formikform from "../../../Utils/Formikform"
import { Formik, Form, Field, useFormik } from 'formik';
import * as  valid_err from "../../../Utils/Common_Messages"
import { toast } from "react-toastify";
import AlertToast from "../../../Utils/Alert_Toast"
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Email_regex, Mobile_regex } from "../../../Utils/Common_regex"
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "../../../component/admin/admin-assets/css/style.css"

// import { FindOneChildUser, AddAdminsChilds, findOnchild, GetAllMastersName, getMasters, UpdateMasterChild } from "../../../ReduxStore/Slice/AdminChilds"
// import { FindOneChildUser, AddAdminsChilds, findOnchild, GetAllMastersName, getMasters, UpdateMasterChild } from "../../../ReduxStore/Slice/AdminSlice/Common"


import { FindOneChildUser, AddAdminsChilds, findOnchild, GetAllMastersName, getMasters, UpdateMasterChild } from "../../../ReduxStore/Slice/AdminSlice/Common"


const EditAdmin = () => {

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const params_id = useParams()

  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const admin_id = JSON.parse(localStorage.getItem('user_details')).admin_id;
  const RoleId = JSON.parse(localStorage.getItem('user_details')).roles[0];
  const getmasters = useSelector(getMasters)

  console.log("getmasters", location);

  const [userdata, setuserdata] = useState("")

  const getMastersName = async () => {
    await dispatch(GetAllMastersName({ AdminToken: AdminToken }))
    // await dispatch(FindOneChildUser({ id: params_id.id, token: AdminToken })).then((res) => {
    //   if (res.payload.status) {
    //     setuserdata(res.payload.data[0])
    //   }
    // })
  }


  useEffect(() => {
    getMastersName()
  }, [dispatch])



  const isValidEmail = (email) => {
    return Email_regex(email)
  }
  const isValidContact = (mobile) => {
    return Mobile_regex(mobile)
  }

  const setRoleId = (role) => {
    if (role === "ADMIN") {
      return "2"
    }
    else if (role === "USER") {
      return "4"
    }
    else if (role === "SUPERADMIN") {
      return "1"
    }
    else if (role === "MASTER") {
      return "2"
    }
  }



  // console.log("location.state", location.state);

  const formik = useFormik({
    initialValues: {
      username: location.state.username,
      fullName: location.state.fullname,
      email: location.state.email,
      mobile: location.state.phone_number,
      broker: location.state.broker,
      licencetype: location.state.license_type,

      tomonth: location.state.licence,
      // apikey: location.state.api_key,
      // apisecret: location.state.api_secret,
      // apiid: location.state.app_id,
      // masters: location.state.master_id,
      // api_secret: location.state.api_secret,
      // app_id: location.state.app_id,
      // client_code: location.state.client_code,
      // api_key: location.state.api_key,
      // app_key: location.state.app_key,
      // api_type: location.state.api_type,
      // demat_userid: location.state.demat_userid,


      add_licence: "0"
    },
    validate: (values) => {
      const errors = {};
      if (!values.username) {
        errors.username = valid_err.USERNAME_ERROR;
      }
      if (!values.fullName) {
        errors.fullName = valid_err.FULLNAME_ERROR;
      }

      if (!values.mobile) {
        errors.mobile = valid_err.CONTACT_ERROR;
      } else if (!isValidContact(values.mobile)) {
        errors.mobile = valid_err.INVALID_CONTACT_ERROR;
      }
      if (!values.licencetype) {
        errors.licencetype = valid_err.LICENCE_TYPE_ERROR;
      }
      if (!values.masters) {
        errors.masters = valid_err.MASTER_ERROR;
      }
      if (!values.add_licence) {
        errors.add_licence = valid_err.LICENCE_ERROR;
      }
      if (!values.broker) {
        errors.broker = valid_err.BROKER_ERROR;
      }
      // if (!values.apisecret) {
      //   errors.apisecret = valid_err.APISECRET_ERROR;
      // }
      // if (!values.apikey) {
      //   errors.apikey = valid_err.APIKEY_ERROR;
      // }
      // if (!values.apiid) {
      //   errors.apiid = valid_err.APIID_ERROR;
      // }
      if (!values.email) {
        errors.email = valid_err.EMPTY_EMAIL_ERROR;
      } else if (!isValidEmail(values.email)) {
        errors.email = valid_err.INVALID_EMAIL_ERROR;
      }

      return errors;
    },
    onSubmit: async (values) => {

      const req = {
        "id": params_id.id,
        "fullname": values.fullName,
        "username": values.username,
        "email": values.email,
        "phone_number": values.mobile,
        "license_type": values.license_type,
        "licence": values.tomonth,

        "roleId": "4",
        "master_id": values.masters,
        "parent_admin_id": admin_id,
        "parent_role_id": setRoleId(RoleId),
        "broker": values.broker,
        "demat_userid": values.demat_userid,
        "api_secret": values.api_secret,
        "app_id": values.app_id,
        "client_code": values.client_code,
        "api_key": values.api_key,
        "app_key": values.app_key,
        "api_type": values.api_type,

        "addlicence": values.add_licence,

      }
      console.log("req", req);



      await dispatch(UpdateMasterChild({ req: req, AdminToken: AdminToken })).then((res) => {

        console.log("repso", res);
        if (res.meta.requestStatus === "fulfilled") {

          if (res.payload.status) {
            toast.success(res.payload.data)
            setTimeout(() => {
              navigate("/admin/childs")
            }, 2000);
          }
          else {
            toast.error(res.payload.data)

          }
        }

      })
    }
  });



  const fields = [
    { name: 'username', label: 'Username', type: 'text' },
    { name: 'fullName', label: 'FullName', type: 'text' },
    { name: 'email', label: 'Email', type: 'text' },
    { name: 'mobile', label: 'Mobile', type: 'text' },
    {
      name: 'tomonth',
      label: 'Licence',
      type: 'text',
      // options: [
      //   { label: '1', value: '1' },
      //   { label: '2', value: '2' },
      //   { label: '3', value: '3' },
      //   { label: '4', value: '4' },
      //   { label: '5', value: '5' },
      //   { label: '6', value: '6' },
      //   { label: '7', value: '7' },
      //   { label: '8', value: '8' },
      //   { label: '9', value: '9' },
      //   { label: '10', value: '10' },
      //   { label: '11', value: '11' },
      //   { label: '12', value: '12' },

      // ],
    },

  ];






  return (



    <div className="main-panel">
      <div className="content ">
        <div className="panel-header bg-primary-gradient">
          <div className="py-5 page-inner">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <h4 className="flex-grow-1 text-white fw-bold">Edit Admin</h4>
              <Link to='/super/admins' className='btn btn-light'>Back</Link>
            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row row-card-no-pd  shadow-lg mt--2">
            <div className='data-table-div'>
              <Formikform fieldtype={fields.filter(field => !field.showWhen || field.showWhen(formik.values))} formik={formik} btn_name="Update" />
              <AlertToast position="top-center" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditAdmin
