import React, { useEffect, useState } from 'react'
import APiCreateInfo from "../../../component/ReusableComponents/ApiCreateInfo/ApiCreateInfo"
import { FindOneChildUser } from "../../../ReduxStore/Slice/AdminSlice/Common"
import { useDispatch, useSelector } from "react-redux";

const CreateApiInformation = () => {
  const dispatch = useDispatch()

  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const id = JSON.parse(localStorage.getItem('user_details')).id;

  const [userdata, setuserdata] = useState('')
  console.log("userdata", userdata);



  const getMastersName = async () => {
    await dispatch(FindOneChildUser({ id: id, AdminToken: AdminToken })).then((res) => {
      if (res.payload.status) {
        setuserdata(res.payload.data[0])
      }
    })
  }


  useEffect(() => {
    getMastersName()
  }, [dispatch])






  return (
    <div><APiCreateInfo title="Api Create Information"  userData={userdata}/></div>
  )
}

export default CreateApiInformation