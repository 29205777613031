import React, { useState } from 'react'
import axios from "axios";

// import Files
import * as Config from "../Utils/Config";
import { header } from "../Utils/api_header";



// Admin Dashboard
export async function Get_All_Admins(data, token) {
    try {
        const res = await axios.get(`${Config.base_url}superadmin/all_admins`, data  , {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}
// Admin Dashboard
export async function Add_Admins(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}superadmin/addadmin`, data  , {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;

        console.log("error", err);
        // custom error
    }

}
