
import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";

import logo from './logo.svg';

// import './App.css';
// import Header from './component/admin/Header';
// import Sidebar from './component/admin/Sidebar';
import Routing from "./Route/Route"
// import Wraper from './component/admin/Wraper';




function App() {
  const navigate = useNavigate()


  return <>
    <Routing />
  </>


}

export default App;
