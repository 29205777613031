import React from 'react'
import { fDateTime  , fDate } from "../../../Utils/DateFormat"

const AcountInfo = ({ findProfileDetails }) => {
    console.log("account", findProfileDetails);



    const BrokerName = (value) => {
        if (value == 1) {
            return "markethub"
        }
        else if (value == 2) {
            return "alice blue"
        }
        else if (value == 3) {
            return "master trust"
        }
        else if (value == 4) {
            return "Motilal Oswal"
        }
        else if (value == 5) {
            return "Zebull"
        }
        else if (value == 6) {
            return "IIFl"
        }
        else if (value == 7) {
            return "Kotak"
        }
        else if (value == 8) {
            return "Mandot"
        }
        else if (value == 9) {
            return "Choice"
        }
        else if (value == 10) {
            return "Anand Rathi"
        }
        else if (value == 11) {
            return "B2C"
        }
        else if (value == 12) {
            return "Angel"
        }
        else if (value == 13) {
            return "Fyers"
        }
        else if (value == 14) {
            return "5-Paisa"
        }
        else if (value == 15) {
            return "Zerodha"
        }

    }
    return (
        <div>
            <div class="p-3 border row ">
                <div class="col-md-4 border-right">
                    <h5>Broker</h5>
                </div>
                <div class="col-md-8">
                    <h5>{BrokerName(findProfileDetails && findProfileDetails[0].broker)}</h5>
                </div>
            </div>
            <div class="p-3 border row ">
                <div class="col-md-4 border-right">
                    <h5>Licence</h5>
                </div>
                <div class="col-md-8">
                    <h5>{findProfileDetails && findProfileDetails[0].licence} </h5>
                </div>
            </div>
            <div class="p-3 border row ">
                <div class="col-md-4 border-right">
                    <h5>CreateAt Date</h5>
                </div>
                <div class="col-md-8">
                    <h5>{findProfileDetails && fDate(findProfileDetails[0].start_date)}</h5>

                </div>
            </div>
            <div class="p-3 border row ">
                <div class="col-md-4 border-right">
                    <h5>Expiry Date</h5>
                </div>
                <div class="col-md-8">
                <h5>{findProfileDetails && fDate( findProfileDetails[0].expiry_date)}</h5>
                </div>
            </div>
        </div>
    )
}

export default AcountInfo