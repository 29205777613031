import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Get_Client_Acording_to_Its_master } from "../../../../Service/admin.service"




export const GetClientAcordingItMaster = createAsyncThunk("admin/getmasterclients", async (data, token) => {
  const { id, AdminToken } = data
  try {
    const res = await Get_Client_Acording_to_Its_master({ id: id }, AdminToken)
    return await res?.data;
  } catch (err) {
    console.log("error", err);
    // custom error
  }
});







const MasterSlice = createSlice({
  name: "MasterSlice",
  initialState: {
    isLoading: false,
    masterClientlist: [],
    isError: false,
  },

  recuders: {},
  extraReducers: {

    [GetClientAcordingItMaster.pending]: (state, action) => {
      console.log("Pending")
    },
    [GetClientAcordingItMaster.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      console.log("paload", payload);
      return { ...state, masterClientlist: payload };
    },
    [GetClientAcordingItMaster.rejected]: (state) => {
      state.isError = true;
    },

  },

});

export const masterClientlist = (state) => state && state.masterClientlist;


export default MasterSlice;