import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, useFormik } from 'formik';
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate, Link, useParams } from "react-router-dom";
import * as  valid_err from "../../../Utils/Common_Messages"
import { Email_regex } from "../../../Utils/Common_regex"
import { fDateTimeSuffix, dateFormatePlusThirty } from '../../../Utils/DateFormat';

import { ForgetPasswordUpdate, ChangePassword } from "../../../ReduxStore/Slice/AuthSlice/AuthSlice"
import Formikform from "../../../Utils/Formikform"
import { useDispatch, useSelector } from "react-redux";
import AlertToast from "../../../Utils/Alert_Toast"
import { toast } from "react-toastify";





const UpdatePassword = () => {



    const navigate = useNavigate()



    const dispatch = useDispatch()
    const userid = JSON.parse(localStorage.getItem('user_details')).id;
    const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;


    const selector = useSelector((state) => state?.AuthSlice)

    const isValidEmail = (email) => {
        return Email_regex(email)
    }

    const formik = useFormik({
        initialValues: {
            resoldpassword: '',
            resnewpassword: '',
            resconfirmpassword: '',
        },
        validate: (values) => {
            const errors = {};

            if (!values.resoldpassword) {
                errors.resoldpassword = valid_err.OLD_PASSWORD_ERROR;
            }
            if (!values.resnewpassword) {
                errors.resnewpassword = valid_err.NEW_PASSWORD_ERROR;
            } 
            // else if (values.resnewpassword !== values.resoldpassword) {
            //     errors.resnewpassword = valid_err.OLD_AND_NEW_PASSWORD_ERROR;
            // }
            if (!values.resconfirmpassword) {
                errors.resconfirmpassword = valid_err.CONFIRM_PASSWORD_ERROR;
            } else if (values.resnewpassword !== values.resconfirmpassword) {
                errors.resconfirmpassword = valid_err.CONFIRM_AND_NEW_PASSWORD_ERROR;
            }
            return errors;
        },
        onSubmit: async (values) => {

            await dispatch(ChangePassword({ id: userid, old_password: values.resoldpassword, password: values.resnewpassword, AdminToken: AdminToken })).then((res) => {
                console.log("localStorage", res);
                if (res.meta.requestStatus) {
                    toast.success(res.payload.message)
                    setTimeout(() => {
                        navigate("/child/dashboard", { replace: true })
                    }, 2000);
                }
                else {
                    toast.error(res.payload.message)
                }
            })


        },
    });

    const fields = [
        { name: 'resoldpassword', label: 'Old Password', type: 'password' },
        { name: 'resnewpassword', label: 'New Password', type: 'password' },
        { name: 'resconfirmpassword', label: 'Confirm Password', type: 'password' },
    ];
    return (

        <div className='d-flex align-items-center justify-content-center col-12'>
            <Formikform fieldtype={fields} formik={formik} btn_name="Update" />
        </div>

        // <div className='main-content position-relative'>
        //     <div className="page-content">
        //         <div className="page-title">
        //             <div className="row justify-content-between align-items-center">
        //                 <div className="mb-3 col-md-6 mb-md-0">
        //                     <h5 className="mb-0 text-white h3 font-weight-400"></h5>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="row">
        //             <div className="col-md-12">
        //                 <div className="card">
        //                     <div className="card-body">
        //                         <div className="row">

        //                                              <Formikform fieldtype={fields} formik={formik} btn_name="Update" />



        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        // <section style={{ height: '100vh', background: '#ebf8fc', overflowY: 'hidden' }} className="d-flex align-items-center justify-content-center">
        //     <div className="container  ">
        //         <div className="row mt-5 d-flex align-items-center justify-content-center">
        //             <div className="col-md-4">
        //                 <div className="card rounded border ">
        //                     <div className="card-body">
        //                         <h4 className="text-center card-title">Reset Password</h4>
        //                         <div className="row">
        //                             <div className="col-12 my-3">
        //                                 <div className="text-center">
        //                                 </div>
        //                                 <Formikform fieldtype={fields} formik={formik} btn_name="Update" />
        //                                 <AlertToast position="top-center" />
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section > */}
    )
}

export default UpdatePassword