import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";
import MasterDashboard from '../Layout/Master_Clients/Dashboard/MasterDashboard';
import Profile from '../Layout/Master_Clients/Profile/Profile';
import TradingStatus from '../Layout/Master_Clients/TradingStatus/TradingStatus';
import TradeHistory from '../Layout/Master_Clients/TradeHistory/TradeHistory';
import TradingReport from '../Layout/Master_Clients/TradingReport/TradingReport';
import Positions from '../Layout/Master_Clients/Positions/Positions';
import CreateApiInformation from '../Layout/Master_Clients/CreateApiInformation/CreateApiInformation';
import NotFound from "../Layout/NotFound"

import Signals from '../Layout/Child_Clients/Signals/Signals';
import RiskMgmt from '../Layout/Child_Clients/RiskMgmt/RiskMgmt';

import Header from '../component/master/Header';
import Client_sidebar from '../component/client/Client_sidebar';

// import NotFound from "../Layout/NotFound"




const Client = () => {

    const location = useLocation();
    const navigate = useNavigate()

    //   const navigate = useNavigate()
    const role_id = localStorage.getItem("Role")


    useEffect(() => {
        // const token = localStorage.getItem('token');
        const expiryTime = localStorage.getItem('expiryTime');

        if (expiryTime) {
            const currentTime = new Date().getTime();
            console.log("currentTime", currentTime);
            console.log("expiryTime", expiryTime);
            if (parseInt(currentTime) > parseInt(expiryTime, 10)) {
                // Session expired, handle accordingly
                //  localStorage.removeItem('token');
                //    localStorage.removeItem('expiryTime');
                navigate("/")
                console.log('Session expired');
            }
        }
    }, []);


    return (
        <>
            {/* <Header /> */}
            {location.pathname !== "/master" ? <Header /> : <NotFound />}
            {/* {location.pathname !== "/client" || location.pathname !== "/client/*" ? <Client_sidebar /> : null} */}
            <Routes path="/master">
                <>
                    <Route exact path="/dashboard" element={<MasterDashboard />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/tradingstatus" element={<TradingStatus />} />
                    <Route exact path="/positions" element={<Positions />} />
                    <Route exact path="/createapiinformation" element={<CreateApiInformation />} />
                    {/* <Route path="/signals" element={<Signals />} /> */}
                    {/* <Route path="/riskmgmt" element={<RiskMgmt />} /> */}
                    <Route path="/tradehistory" element={<TradeHistory />} />
                    <Route path="/tradereport" element={<TradingReport />} />
                    <Route path="*" element={<NotFound />} />
                </>
            </Routes>
        </>
    )
}

export default Client