import React, { useState, useEffect, Component } from 'react';
import DataTable from '../../../Utils/Datatables';
import Model from "../../../component/ReusableComponents/Model/Model"
import { ShowColor, ShowColor_Compare_two } from '../../../Utils/ShowTradeColor';

import { useDispatch, useSelector } from "react-redux";
import { CreateSession, ConnectSocket } from "../../../ReduxStore/Slice/LiveMarketData"
import $ from "jquery";
// import { CreateSocketSession, ConnctSocket } from "../../../Service/alices_socket.service"






const ReusedTradeHistory = ({ title, tableData }) => {

    const dispatch = useDispatch()
    const { data, status } = tableData


    // for model show hide
    const [modalOpen, setModalOpen] = useState(false);


    var CreatechannelList = "";
    if (data != undefined && data != 'Session Expired Please trading re-login' && data != 'Please On Trading First') {
        data != undefined && data?.map((item) => {
            CreatechannelList += `${item.exchange}|${item.instrumentToken}#`
        })

    }


    // NSE|2211#NSE|8658

    // NSE|14366#NSE|3787



    const ShowPosition = async () => {
        var userId = "883538"
        var userSession = "e3TF1JDlxRRRYHQfAu98xTyZjVkvSCztgnuJeBpuV8Wh6kZnN311JTZ4lIhp2pGM3O78nEWZUarpCyKAhvjgiFpWTUjNpLqGlmhRu4IhxdxVe1hED9L20k8uV2k3JPx4aMvBbs1vv8WrAf3Xp8geW9KdnaZMP6rxhpJkcS0O0WpyFhOfOCq3dT9rXEkQmoxc1uSWemIFbrt0NHQDxXiNRjV1XPAMRXnWfvS9VFAHvkkfIVtQUoqUY6O7veglPLuO"

        let type = { loginType: "API" }

        let channelList = CreatechannelList.slice(0, -1)

        // console.log("channelList" ,channelList);

        dispatch(CreateSession({ userId: userId, userSession: userSession })).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                if (res.payload.data.stat == "Ok") {
                    const handleResponse = (response) => {

                        // console.log("response" ,response);
                        if (response.tk) {
                            if (response.lp != undefined) {

                                //  --------------------------------------------------- Get LTP Price  --------------------------------------------
                                $(".ShowLTP_" + response.tk).html(response.lp);
                                const get_stock_price = $(".avg_Price" + response.tk).html();
                                ShowColor_Compare_two("ShowLTP_", response.lp, get_stock_price, response.tk)

                                // if (parseFloat(response.lp) < parseFloat(get_stock_price)) {
                                //     $('.ShowLTP_' + response.tk).append('&#8595;')
                                //     $('.ShowLTP_' + response.tk).css({ "color": "red" });
                                // }
                                // else if (parseFloat(response.lp) > parseFloat(get_stock_price)) {
                                //     $('.ShowLTP_' + response.tk).css({ "color": "green" });
                                //     $('.ShowLTP_' + response.tk).append('&#8593;')
                                // } else if (parseFloat(response.lp) === parseFloat(get_stock_price)) {
                                //     $('.ShowLTP_' + response.tk).css({ "color": "black" });
                                // }
                            }
                            //  --------------------------------------------------- Get LTP Price  --------------------------------------------

                        }
                    }
                    dispatch(ConnectSocket({ userId: userId, userSession: userSession, handleResponse: handleResponse, channelList: channelList }))
                }
            }
        })
    }








    useEffect(() => {
        ShowPosition()
    }, [data])




    const ShowMoreDetails = (item) => {
        // setShowMore(item)
        setModalOpen(true)
    }




    return (
        <div>

            <div className='main-content position-relative'>
                <div className="page-content">
                    {/* Page title */}
                    <div className="page-title">
                        <div className="row justify-content-between align-items-center">
                            <div className="mb-3 col-md-6 mb-md-0">
                                <h5 className="mb-0 text-white h3 font-weight-400">{title}</h5>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card" style={{ height: "100vh !important", overflowY: 'scroll !important' }}>
                                <div className="card-body" style={{ height: "100vh !important", overflowY: 'scroll !important' }}>
                                    <div className="row profile">
                                        {
                                            tableData != undefined && tableData.length == 0 || data.length == 0 ? <h6 className='text-center'>No Data Available</h6> : tableData.data === "Please On Trading First" ? <h6 className='text-center'>Please On Trading First</h6> :
                                                data == "Session Expired Please trading re-login" ? <h6 className='text-center'>Session Expired Please trading re-login</h6> :
                                                    <>
                                                        {data && data.map((item) => {
                                                            return <>

                                                                <div className='row border rounded my-3  boxes-row'>
                                                                    <div className='col-lg-5'>
                                                                        <div className='d-flex '>
                                                                            <span class="badge bg-light text-dark ms-0" style={{ height: '21px;' }}>{item.exchange}</span>
                                                                            <h5 className='ms-2'>{(item.instrumentName) + (item.expiryDate == "-" ? "" : item.expiryDate)}</h5>
                                                                        </div>
                                                                        {/* <p>1lot=25 QTY</p> */}
                                                                        <span class={`badge btn-outline-${item.transactionType.toUpperCase() == 'BUY' ? "success" : "danger"}`}  >{item.transactionType}</span>
                                                                        <span class="badge btn-outline-warning ms-2">{item.instrumentType == 'OI' ? "OPTIONS" : item.instrumentType == 'EQ' ? "EQUITY" : "-"}</span>
                                                                        <span class="badge btn-outline-info ms-2">{item.product}</span>
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <div className='row'>
                                                                            <div className='col-lg-4 float-lg-right'>
                                                                                <p className='mb-0'>TRADED QTY</p>
                                                                                <h6>{item.quantity || item.orderQuantity}</h6>
                                                                            </div>
                                                                            <div className='col-lg-4 float-lg-right'>
                                                                                <p className='mb-0'>AVG. Price</p>
                                                                                <h6>{item.price}</h6>
                                                                            </div>
                                                                            <div className='col-lg-4 float-lg-right'>
                                                                                <p className='mb-0'>LTP</p>
                                                                                <h6 className={`ShowLTP_${item.instrumentToken}`}></h6>

                                                                                <p className={"d-none avg_Price" + item.instrumentToken} >{item.price}</p>

                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-lg-4 float-lg-right'>
                                                                                <p className='mb-0'>Order Id</p>
                                                                                <h6>{item.orderId}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-1'>
                                                                        <div className='col-lg-1'>
                                                                            <button
                                                                                className="border-0 "
                                                                                onClick={() => ShowMoreDetails(item)}
                                                                            >
                                                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                                                            </button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </>
                                                        })}

                                                    </>
                                        }


                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <Model isOpen={modalOpen} size={"lg"} onClose={() => setModalOpen(false)} title="Order Details "
                            content={
                                <>
                                    <div className="container-fluid">
                                        <div className="row mb-3 mb- border-bottom">
                                            <div className="col-6 ">
                                                <div className='d-flex'>
                                                    <h6 >Order No. : 2230615019872</h6>
                                                </div>
                                            </div>
                                            <div className="col-6 d-flex ms-auto ">
                                                <h6 className=' ms-auto' >Completely Traded Order</h6>
                                                <span class="badge btn-outline-warning ms-2">TRADED</span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6   border-secondary  border-right">
                                                <div className='d-flex align-item-center '>
                                                    <h6 className='flex-grow-1'>Order Price</h6>
                                                    <h6>0</h6>
                                                </div>
                                                <div className='d-flex align-item-center '>
                                                    <h6 className='flex-grow-1'>Quantity </h6>
                                                    <h6>0.00</h6>
                                                </div>
                                                <div className='d-flex align-item-center '>
                                                    <h6 className='flex-grow-1'>Valid Till</h6>
                                                    <h6>0.00</h6>
                                                </div>
                                                <div className='d-flex align-item-center '>
                                                    <h6 className='flex-grow-1'>Total Value</h6>
                                                    <h6>0.00</h6>
                                                </div>
                                            </div>
                                            <div className="col-6 d-flex flex-column">
                                                <p className="text-muted ms-auto" style={{ fontSize: '12px' }}>LAST UPDATED ON 10:11:59AM 15/06/2023</p>

                                                <div className='d-flex  '>
                                                    <span class="badge bg-light text-dark ms-0 " style={{ height: '21px;' }}>BSE</span>
                                                    <h6 className='ms-2 flex-grow-1'>IDEA</h6>
                                                    <h6 className='ms-2 '>2.2</h6>
                                                    <h6 className='ms-2'>-0.02(0.00%)</h6>
                                                </div>
                                                <div>
                                                    <span class="badge btn-outline-success">BUY</span>
                                                    <span class="badge btn-outline-warning ms-2">EQUITY</span>
                                                    <span class="badge btn-outline-warning ms-2">NORMAL</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row  my-2 border-primary  border-top ">
                                            <div className="col-12 mt-3 ">
                                                <h5>Order History</h5>
                                                <h6>Exchange trade number : 12174500

                                                </h6>
                                                <div className='d-flex border rounded my-3  boxes-row'>
                                                        <div className='d-flex align-items-center my-auto justify-contant-center'>
                                                            <span class="badge btn-outline-success">BUY</span>
                                                            <p className='text-muted'>New Forwarded to Exchange, Pending for Confirmation.</p>
                                                            <p class="text-muted flex-grow-1 ms-auto">10:12:00AM 15/06/23</p>



                                                        </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            }
                        /> */}


                    </div>
                </div>
            </div>
        </div >
    )
}

export default ReusedTradeHistory