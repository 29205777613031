import React from 'react'
import { Link } from 'react-router-dom'
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import Content from './Content'
import Footer from './Footer'
import { superadmin_route, admin_route } from "./Sidebar_config"

const Sidebar = () => {


  const location = useLocation()


  const RoleId = JSON.parse(localStorage.getItem('user_details')).roles[0];



  return (
    <>
      <div className="sidebar sidebar-style-2" data-background-color="light">
        <div className="sidebar-wrapper scrollbar scrollbar-inner">
          <div className="sidebar-content">
            <ul className="nav nav-primary">
              {
                RoleId == "SUPERADMIN" ? superadmin_route.map((item, index) => {
                  return <>
                    <li className={`nav-item ${location.pathname == item.route ? "active" : ""}`} key={index}>
                      <Link to={item.route !== "" && item.route}>
                        <i className={item.icon} />
                        <p>{item.name}</p>
                      </Link>
                    </li>
                  </>
                })
                  :
                  admin_route.map((item, index) => {
                    return <>
                      <li className={`nav-item ${location.pathname == item.route ? "active" : ""}`} key={index}>
                        <Link to={item.route !== "" && item.route}>
                          <i className={item.icon} />
                          <p>{item.name}</p>
                        </Link>
                      </li>
                    </>
                  })
              }
            </ul>
          </div>
        </div>
      </div>
    </>

  )
}

export default Sidebar