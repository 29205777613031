import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Upadate_Risk_Mgmt_Qty_Segment } from "../../../../Service/user.service"


export const UpadateRiskMgmtQtySegment = createAsyncThunk("admin/updateQtyType", async (data, token) => {
    const { req, AdminToken } = data

    try {
        const res = await Upadate_Risk_Mgmt_Qty_Segment(req, AdminToken)
        if (res.status) {
            return await res?.data;
        }
    } catch (err) {
        console.log("error", err);
    }
});



const RiskMgmtSlice = createSlice({
    name: "RiskMgmtSlice",
    initialState: {
        isLoading: false,
        updateRiskMgmtData: [],
    },

    recuders: {},
    extraReducers: {
        //     [GetAdminAllChilds.pending]: (state, {payload}) => {
        //   state.isLoading = true;
        //   return { ...state, childlist: [] };

        // },
        [UpadateRiskMgmtQtySegment.pending]: (state, action) => {
            console.log("Pending")
        },
        [UpadateRiskMgmtQtySegment.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            return { ...state, updateRiskMgmtData: payload };
        },
        [UpadateRiskMgmtQtySegment.rejected]: (state) => {
            state.isError = true;
        },

    },
})

export const accesstoken = (state) => state.RiskMgmtSlice.updateRiskMgmtData;

export default RiskMgmtSlice;

