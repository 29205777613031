import React from 'react'

const ShowProfitLoss = ({LivePl}) => {
    return (
        <div>            
            {/* <div class="mt-2 mb-4"> */}
            <div class="row row-card-no-pd ">
                <div class="col-md-6">
                    <div class="card card-stats card-round full-height">
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-4">
                                    <div class="text-center icon-big">
                                        <i class="fa fa-download text-warning"></i>
                                    </div>
                                </div>
                                <div class="col-md-6 col-8 col-stats">
                                    <div class="numbers">
                                        <h6 class="card-category">Today's P & L</h6>
                                        <h5 className={LivePl}>
                                   
                                        </h5>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-stats card-round full-height">
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-4">
                                    <div class="text-center icon-big">
                                        <i class="fa fa-download text-warning"></i>
                                    </div>
                                </div>
                                <div class="col-md-6 col-8 col-stats">
                                    <div class="numbers">
                                        <h class="card-category">Overall P & L</h>
                                        <h2>
                                            0
                                        </h2>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </div>
    )
}

export default ShowProfitLoss