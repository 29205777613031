import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import { DispatchSystem } from "../../Layout/Admin/System/System"
import { admin_system, Update_admin_system } from "../../../../Service/admin.service"




export const AdminSystem = createAsyncThunk("admin/system", async (data) => {
  const { id, admin_id, AdminToken } = data


  try {
    const res = await admin_system({ id: id, admin_id: admin_id }, AdminToken)
    // console.log("resss" , res);
    return await res?.data;
  } catch (err) {
    console.log("error", err);
    // custom error
  }
});



export const UpdateAdminSystem = createAsyncThunk("update/admin/system", async (data ) => {
  const { req, AdminToken } = data
  console.log("data", data)
  try {
    const res = await Update_admin_system( req , AdminToken)
    // console.log("resss" , res);
    return await res?.data;
  } catch (err) {
    console.log("error", err);
    // custom error
  }
});





const Systemslice = createSlice({
  name: "Systemslice",
  initialState: {
    isLoading: false,
    data: [],
    updatesystem: [],
    isError: false,
  },

  recuders: {},

  extraReducers: {

    [AdminSystem.pending]: (state, action) => {
      console.log("Pending")
    },
    [AdminSystem.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      return { ...state, data: payload };
    },
    [AdminSystem.rejected]: (state) => {
      state.isError = true;
    },
    [UpdateAdminSystem.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      return { ...state, updatesystem: payload };
    },
  },

  // extraReducers: (builder) => {
  //   builder

  //     .addCase(AdminSystem.fulfilled, (state, action) => {
  //       state.isLoading = false;
  //       state.data = action.payload;
  //     })
  //     .addCase(AdminSystem.pending, (state, action) => {
  //       state.isLoading = true;
  //       state.data = action.payload;
  //     })
  //     .addCase(AdminSystem.rejected, (state) => {
  //       state.isLoading = true;
  //       state.isError = true;
  //     });
  // },
});


export default Systemslice;