import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from 'formik';
import Formikform from "../../../Utils/Formikform";
import DataTable from "../../../Utils/Datatables";
import { toast } from "react-toastify";
import AlertToast from "../../../Utils/Alert_Toast";
import { Link } from "react-router-dom";
// import { GetAdminAllClients } from "../../../ReduxStore/Slice/AdminMasters"
import {
  GetAdminAllClients,
  masterlist,
  FindOneChildUser
  ,
  findOnchild1
} from "../../../ReduxStore/Slice/AdminSlice/Common";
import { UpadateRiskMgmtQtySegment } from "../../../ReduxStore/Slice/UsersSlice/ChildSlice/RiskMgmtSlice";


import * as valid_err from "../../../Utils/Common_Messages";
import { Email_regex, Mobile_regex, No_Negetive_Input_regex, } from "../../../Utils/Common_regex";

const RiskMgmt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userid = JSON.parse(localStorage.getItem("user_details")).id;
  const admin_id = JSON.parse(localStorage.getItem("user_details")).admin_id;
  const RoleId = JSON.parse(localStorage.getItem("user_details")).roles[0];
  const AdminToken = JSON.parse(localStorage.getItem("user_details")).accessToken;



  // usestate

  const { data, status } = useSelector(findOnchild1 && findOnchild1)

  console.log("data", data);



  const [userdata, setuserdata] = useState()






  // useEffect(() => {
  //   if (status) {
  //     setuserdata(data[0])
  //   }
  // }, [data])




  // for qty Selection
  const [QtySelection, setQtySelection] = useState('')
  const [masterQtyMultiply, setmasterQtyMultiply] = useState()
  const [FundAlocate, setFundAlocate] = useState()


  // for Segment Selection

  const [segmntSelection, setsegmntSelection] = useState('')


  console.log("segmntSelection", segmntSelection);

  // NSC
  const [selectNSE, setselectNSE] = useState("")
  // NFO
  const [selectNFO_Option, setselectNFO_Option] = useState("")
  const [selectNFO_Future, setselectNFO_Future] = useState('')
  console.log("selectNFO_Future", selectNFO_Future);
  // CDS

  const [selectCDS_Future, setselectCDS_Future] = useState("")
  const [selectCDS_Option, setselectCDS_Option] = useState("")
  // MCX
  const [selectMCX_Future, setselectMCX_Future] = useState("")
  const [selectMCX_Option, setselectMCX_Option] = useState("")




  useEffect(() => {
    if (status) {
      setQtySelection(data[0].qty_type)
      setselectNFO_Option(data[0].nfo_status[0])
      setselectNFO_Future(data[0].nfo_status[1])
      setselectCDS_Option(data[0].cds_status[0])
      setselectCDS_Future(data[0].cds_status[1])
      setselectMCX_Option(data[0].mcx_status[0])
      setselectMCX_Future(data[0].mcx_status[1])
      setmasterQtyMultiply(data[0].multiplayer)
      setFundAlocate(data[0].percent_of_fund)
    }
  }, [data])


  console.log("segmntSelection", segmntSelection);


  const IntegerOnly = (value) => {
    return No_Negetive_Input_regex(value);
  };


  const fields = [
    {
      name: "QuantitySelection",
      label: "Quantity Selection",
      type: "select",
      options: [
        {
          label: "Master Qty Multiplier",
          value: "0",
          additionalField: {
            name: "QtyMultipliyer",
            label: "Qty Multipliyer",
            type: "text",
          },
        },
        {
          label: "Fund Alocation",
          value: "1",
          additionalField: {
            name: "FundAlocation",
            label: "Per Trade Fund (in %)",
            type: "text",
          },
        },
      ],

    },
    {
      name: "QtyMultipliyer",
      label: "Qty Multipliyer",
      type: "text",
      showWhen: (values) => values.QuantitySelection === "0" || values.QuantitySelection === 0,
    },
    {
      name: "FundAlocation",
      label: "Per Trade Fund (in %)",
      type: "text",
      showWhen: (values) => values.QuantitySelection === "1" || values.QuantitySelection === 1,
    },
    {
      name: "SegmentSelection",
      label: "Segment Selection",
      type: "checkbox",

      options: [
        {
          label: "NSE",
          value: "1",

        },

        {
          label: "NFO",
          value: "2",
          additionalField: [
            {
              name: "NFOfuture",
              label: "NFO Future",
              type: "checkbox",
            },
            {
              name: "NFOoption",
              label: "NFO Option",
              type: "checkbox",
            },
          ],
        },
        {
          label: "MCX",
          value: "3",
          additionalField: [
            {
              name: "MCXfuture",
              label: "MCX Future",
              type: "checkbox",
            },
            {
              name: "MCXoption",
              label: "MCX Option",
              type: "checkbox",
            },
          ],

        },
        {
          label: "CDS",
          value: "4",
          additionalField: [
            {
              name: "CDSfuture",
              label: "CDS Future",
              type: "checkbox",
            },
            {
              name: "CDSoption",
              label: "CDS Option",
              type: "checkbox",
            },
          ],

        },
      ],
    },
    {
      name: "NFOoption",
      label: "NFO Option",
      type: "checkbox",
      showWhen: (values) => values.SegmentSelection === true,

    },
    {
      name: "NFOfuture",
      label: "NFO Future",
      type: "checkbox",
      showWhen: (values) => values.SegmentSelection === true,

    },
    {
      name: "MCXoption",
      label: "MCX Option",
      type: "checkbox",
      showWhen: (values) => values.SegmentSelection === true,

    },
    {
      name: "MCXfuture",
      label: "MCX Future",
      type: "checkbox",
      showWhen: (values) => values.SegmentSelection === true,

    },

    {
      name: "CDSoption",
      label: "CDS Option",
      type: "checkbox",
      showWhen: (values) => values.SegmentSelection === true,

    },
    {
      name: "CDSfuture",
      label: "CDS Future",
      type: "checkbox",
      showWhen: (values) => values.SegmentSelection === true,

    },
  ];


  const SubmitRiskMGMT = async (e) => {
    e.preventDefault();
    const req = {
      id: userid,
      // "qty_type"

      qty_type: QtySelection,
      multiplayer: masterQtyMultiply,
      nfo_status: [selectNFO_Option, selectNFO_Future],
      mcx_status: [selectMCX_Option, selectMCX_Future],
      cds_status: [selectCDS_Option, selectCDS_Future],
      percent_of_fund: FundAlocate,
    };

    await dispatch(
      UpadateRiskMgmtQtySegment({ req: req, AdminToken: AdminToken })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success(res.payload);
      }
    });

  }







  const getOneUser = async () => {
    await dispatch(FindOneChildUser({ id: userid, AdminToken: AdminToken }))
  }


  useEffect(() => {
    getOneUser();
  }, [dispatch]);



  return (
    <div className="main-content position-relative">
      <div className="page-content">
        <div className="page-title">
          <div className="row justify-content-between align-items-center">
            <div className="mb-3 col-md-6 mb-md-0">
              <h5 className="mb-0 text-white h3 font-weight-400">
                Risk Management
              </h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <form >
                    <h5 className="d-block"> Quantity  Selection</h5>
                    <div className="col-lg-12 d-flex">
                      <div className="form-group mb-0 text-left col-6">
                        <label htmlFor="qtyselection" className="form-check-label mb-0">Qty Multiplier</label>
                        <select
                          className=" form-control"
                          id="qtyselection"
                          name="qtyselection"
                          onChange={(e) => setQtySelection(e.target.value)}
                          value={QtySelection}
                        >
                          <option value="" selected disabled>Select</option>
                          <option value={0}>Master Qty Multiplier</option>
                          <option value={1}>Fund Allocation (%)</option>
                        </select>
                      </div>
                      {QtySelection === 0 || QtySelection === "0" ? (
                        <div className="form-group mb-0 text-left col-6">
                          <label htmlFor="masterQtyMultiplier" className="form-check-label mb-0">Master Qty Multiplier</label>
                          <input
                            className=" form-control"
                            type="text"
                            id="masterQtyMultiplier"
                            name="masterQtyMultiplier"
                            onChange={(e) => setmasterQtyMultiply(e.target.value)}
                            value={masterQtyMultiply}
                          />

                        </div>
                      ) : ""}

                      {QtySelection === 1 || QtySelection === "1" ? (
                        <div className="form-group mb-0 text-left col-6">
                          <label htmlFor="fundAllocation" className="form-check-label mb-0">Fund Allocation</label>
                          <input
                            type="text"
                            className=" form-control"
                            id="fundAllocation"
                            name="fundAllocation"
                            onChange={(e) => setFundAlocate(e.target.value)}
                            value={FundAlocate}
                          />

                        </div>
                      ) : ""}
                    </div>
                    <hr />

                    <h5 className="d-block mb-5"> Segment Selection</h5>


                    <div className="row flex-column">
                      <div className="  col-lg-6 d-flex">
                        {/* <div className="form-group mb-0 text-left col-6">
                          <label htmlFor="nse" className="form-check-label mb-0">NSE </label>
                          <input
                            type="checkbox"
                            id="nse"
                            name="nse"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.fundAllocation}
                          />
                          {formik.touched.fundAllocation && formik.errors.fundAllocation && (
                            <div className="error">{formik.errors.fundAllocation}</div>
                          )}
                        </div> */}
                        <div className="form-group mb-0 text-left col-10">
                          <label htmlFor="nfofuture" className="form-check-label mb-0">NFO FUTURE</label>
                          <input
                            type="checkbox"
                            id="nfofuture"
                            name="nfofuture"
                            onChange={(e) => setselectNFO_Future(e.target.checked ? 2 : 0)}

                            checked={selectNFO_Future && selectNFO_Future == 2 || selectNFO_Future && selectNFO_Future == "2"}

                          />

                        </div>
                        <div className="form-group mb-0 text-left col-10">
                          <label htmlFor="cdsfuture" className="form-check-label mb-0">CDS FUTURE</label>
                          <input
                            type="checkbox"
                            id="cdsfuture"
                            name="cdsfuture"

                            onChange={(e) => setselectCDS_Future(e.target.checked ? 2 : 0)}

                            checked={selectCDS_Future && selectCDS_Future == 2 || selectCDS_Future && selectCDS_Future == "2"}

                          />

                        </div>
                        <div className="form-group mb-0 text-left col-10">
                          <label htmlFor="mcxfuture" className="form-check-label mb-0">MCX FUTURE</label>
                          <input
                            type="checkbox"
                            id="mcxfuture"
                            name="mcxfuture"
                            onChange={(e) => setselectMCX_Future(e.target.checked ? 2 : 0)}
                            checked={selectMCX_Future && selectMCX_Future == 2 || selectMCX_Future && selectMCX_Future == "2"}
                          />

                        </div>
                      </div>
                      <div className=" d-flex col-lg-6">
                        {/* <div className="form-group mb-0 text-left col-6">
                        </div> */}

                        <div className="form-group mb-0 text-left col-10">
                          <label htmlFor="nfooption" className="form-check-label mb-0">NFO OPTION</label>
                          <input
                            type="checkbox"
                            id="nfooption"
                            name="nfooption"

                            onChange={(e) => setselectNFO_Option(e.target.checked ? 1 : 0)}
                            checked={selectNFO_Option && selectNFO_Option == 1 || selectNFO_Option && selectNFO_Option == '1'}

                          />
                        </div>
                        <div className="form-group mb-0 text-left col-10">
                          <label htmlFor="cdsoption" className="form-check-label mb-0">CDS OPTION</label>
                          <input
                            type="checkbox"
                            id="cdsoption"
                            name="cdsoption"
                            onChange={(e) => setselectCDS_Option(e.target.checked ? 1 : 0)}
                            checked={selectCDS_Option && selectCDS_Option == 1 || selectCDS_Option && selectCDS_Option == '1'}
                          />

                        </div>
                        <div className="form-group mb-0 text-left col-10">
                          <label htmlFor="mcxoption" className="form-check-label mb-0">MCX OPTION</label>
                          <input
                            type="checkbox"
                            id="mcxoption"
                            name="mcxoption"
                            onChange={(e) => setselectMCX_Option(e.target.checked ? 1 : 0)}
                            checked={selectMCX_Option && selectMCX_Option == 1 || selectMCX_Option && selectMCX_Option == '1'}
                          />
                        </div>
                      </div>


                    </div>

                    <button type="submit" className="btn btn-primary" onClick={(e) => SubmitRiskMGMT(e)}>Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default RiskMgmt;
