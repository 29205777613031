import React from 'react'

const AcountInfo = ({ findProfileDetails }) => {
    return (
        <div>

            <div>
                <div class="p-3 border row ">
                    <div class="col-md-4 border-right">
                        <h5>Fullname</h5>
                    </div>
                    <div class="col-md-8">
                        <h5>{findProfileDetails && findProfileDetails[0].fullname}</h5>
                    </div>
                </div>
                <div class="p-3 border row ">
                    <div class="col-md-4 border-right">
                        <h5>Email Address</h5>
                    </div>
                    <div class="col-md-8">
                        <h5>{findProfileDetails && findProfileDetails[0].email}</h5>
                    </div>
                </div>
                <div class="p-3 border row ">
                    <div class="col-md-4 border-right">
                        <h5>Mobile Number</h5>
                    </div>
                    <div class="col-md-8">
                        <h5>{findProfileDetails && findProfileDetails[0].phone_number}</h5>
                    </div>
                </div>
            </div></div>
    )
}

export default AcountInfo