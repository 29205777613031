import React from 'react'
import AcountInfo from "./AcountInfo"
import PersonalInfo from "./PersonalInfo"
import ResetPassword from "./ResetPassword"
const Maincomponent = ({ Title, findProfileDetails }) => {



  return (
    <div>  <div className='main-content position-relative'>
      <div className="page-content">
        {/* Page title */}
        <div className="page-title">
          <div className="row justify-content-between align-items-center">
            <div className="mb-3 col-md-6 mb-md-0">
              <h5 className="mb-0 text-white h3 font-weight-400">{Title}</h5>
            </div>
          </div>
        </div>
        <div></div>
        <div></div>
        <div></div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row profile">
                  <>
                    <ul className="mb-4 nav nav-pills bg-light p-2" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Personal Info
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="Account-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#Account"
                          type="button"
                          role="tab"
                          aria-controls="Account"
                          aria-selected="false"
                        >
                          Account Info
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="Reset_Password-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#Reset_Password"
                          type="button"
                          role="tab"
                          aria-controls="Reset_Password"
                          aria-selected="false"
                        >
                          Reset Password
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >

                        {/* Personal Info */}
                        <PersonalInfo findProfileDetails={findProfileDetails} />
                      </div>
                      {/* Acount Info */}
                      <div
                        className="tab-pane fade"
                        id="Account"
                        role="tabpanel"
                        aria-labelledby="Account-tab"
                      >
                        <AcountInfo findProfileDetails={findProfileDetails} />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="Reset_Password"
                        role="tabpanel"
                        aria-labelledby="Reset_Password-tab"
                      >
                        <ResetPassword />
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></div>
  )
}

export default Maincomponent