import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from '../../../component/admin/Footer';
import Content from '../../../component/admin/Content';
import DataTable from '../../../Utils/Datatables';
import { Link } from "react-router-dom";
// import { GetAdminAllChilds , GetAllMastersName , getChildlist } from "../../../ReduxStore/Slice/AdminChilds"
import { GetAllMastersName, AddAdminsChilds, addChild, getMasters, getChildlist, GetAdminAllChilds, Remove_One_User } from "../../../ReduxStore/Slice/AdminSlice/Common"
import { toast } from "react-toastify";
import AlertToast from "../../../Utils/Alert_Toast"


const Client = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const admin_id = JSON.parse(localStorage.getItem('user_details')).admin_id;
  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  // const mater = useSelector((state) => state && state?.AdminChilds)

  const childList = useSelector(getChildlist)

  // console.log("childList" ,childList);

  const [rerender, setRerender] = useState(false)



  const GetAllClients = async () => {
    // await dispatch(GetAllMastersName({ AdminToken: AdminToken }))
    await dispatch(GetAdminAllChilds({ id: userid, admin_id: admin_id , AdminToken:AdminToken }))
  }

  useEffect(() => {
    GetAllClients()
  }, [dispatch, rerender])


  const showBrokerName = (value) => {


    if (value == 1) {
      return "markethub"
    }
    else if (value == 2) {
      return "alice blue"
    }
    else if (value == 3) {
      return "master trust"
    }
    else if (value == 4) {
      return "Motilal Oswal"
    }
    else if (value == 5) {
      return "Zebull"
    }
    else if (value == 6) {
      return "IIFl"
    }
    else if (value == 7) {
      return "Kotak"
    }
    else if (value == 8) {
      return "Mandot"
    }
    else if (value == 9) {
      return "Choice"
    }
    else if (value == 10) {
      return "Anand Rathi"
    }
    else if (value == 11) {
      return "B2C"
    }
    else if (value == 12) {
      return "Angel"
    }
    else if (value == 13) {
      return "Fyers"
    }
    else if (value == 14) {
      return "5-Paisa"
    }
    else if (value == 15) {
      return "Zerodha"
    }
  }





  const RemoveUser = async (userid) => {
    if (window.confirm("Do You Want To Remove It")) {
      // console.log("test", userid);
      await dispatch(Remove_One_User({ id: userid, token: AdminToken })).then((res) => {
        // console.log("repso", res);
        if (res.meta.requestStatus === "fulfilled") {
          toast.success(res.payload.data)
          setRerender(!rerender)
        }
      })

    } else {

// console.log("erroir");
    }


  }


  const columns = [
    {
      name: 'Username',
      selector: row => row.username,
      width: "140px"
    },
    {
      name: 'show_password',
      selector: row => row.show_password,
      width: "140px"
    },
    {
      name: "Contact ",
      selector: row => row.phone_number,
      width: "170px"

    },
    {
      name: 'Email',
      selector: row => row.email,
      width: "170px"

    },
    {
      name: 'masters',
      selector: row => row.master_id,
      width: "170px"

    },
    {
      name: 'Broker',
      selector: row => showBrokerName(row.broker),
      width: "150px"

    },

    {
      name: 'Start Date',
      selector: row => row.start_date,
      width: "140px"
    },
    {
      name: 'End Date',
      selector: row => row.expiry_date,
      width: "140px"
    },
    {
      name: 'Actions',
      selector: row =>
        <>
          <div className='actiontd'>
            <Link to={`/admin/child/edit/${row.id} `} state={row}>
              <i class="fa-solid fa-pen-to-square" />
            </Link>
            {/* //<a href='#' className='mx-2'><i class="fa-regular fa-eye"></i></a> */}
            <a ><i class="fa-solid fa-trash mx-3" onClick={() => RemoveUser(row.id)}></i></a>
          </div>
        </>
      ,
      width: "172px"

    },


  ]



  return (
    <div>

      <div className="main-panel">
        <div className="content ">
          <div className="panel-header bg-primary-gradient">
            <div className="py-5 page-inner">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                <h2 className="pb-2 text-white fw-bold">Child Clients</h2>
              </div>
            </div>
          </div>
          <div className="page-inner mt--5">
            <div className="row row-card-no-pd  shadow-lg mt--2">
              <div className='data-table-div'>
                <Link to='/admin/child/add' className='btn btn-primary'> <i class="fa-solid fa-plus"></i> Add Child</Link>
                <DataTable
                  columns={columns} data={childList}
                />
              </div>
              <AlertToast position="top-center" />
            </div>
          </div>
        </div>
      </div>
      {/* </Content  > */}
    </div>
  )
}

export default Client