import React from 'react'
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";

import Sidebar from '../component/admin/Sidebar';
import Header from '../component/admin/Header';
import Footer from '../component/admin/Footer';
import AdminDashboard from '../Layout/Admin/Dashboard/Dashboard';
import AddClients from "../Layout/Admin/Clients/AddClient"
import AddChild from "../Layout/Admin/Child_Clients/AddChild"
import EditClients from "../Layout/Admin/Clients/EditClient"
import EditChild from "../Layout/Admin/Child_Clients/EditChild"
import Clients from "../Layout/Admin/Clients/Client"
import ChildClients from "../Layout/Admin/Child_Clients/ChildClients"
import System from "../Layout/Admin/System/System"
import Login from '../Layout/Auth/Login';
import Editsystem from "../Layout/Admin/System/Edit_System"

const Admin = () => {

    const location = useLocation();
    const navigate = useNavigate()
    const role_id = localStorage.getItem("Role")


    return (
        <>
            {location.pathname !== "/admin" && location.pathname !== "/admin/*" ? <Header /> : null}
            {location.pathname !== "/admin" && location.pathname !== "/admin/*" ? <Sidebar /> : null}

            <Routes>
                {/* <> */}
                <Route exact path="/dashboard" element={<AdminDashboard />} />
                <Route exact path="/masters" element={<Clients />} />
                <Route exact path="/master/add" element={<AddClients />} />
                <Route exact path="/master/edit/:id" element={<EditClients />} />
                <Route exact path="/child/add" element={<AddChild />} />
                <Route exact path="/child/edit/:id" element={<EditChild />} />
                <Route exact path="/childs" element={<ChildClients />} />
                <Route exact path="/system" element={<System />} />
                <Route exact path="system/editsystem/:id" element={<Editsystem />} />
                {/* </> */}
            </Routes>


        </>
    )
}

export default Admin