

export const Nav_Config = [
    {
        id: 1,
        name: "Dashboard",
        icon: "fa-solid fa-house",
        route: "/master/dashboard"
    },
    {
       id: 2,
        name: "Trading Status",
        icon: "fa fa-receipt",
       route: "/master/tradingstatus"
    },
    {
      id: 3 ,
       name: "Positions",
       icon: "fa-sharp fa-regular fa-map",
      route: "/master/positions"
   },
    {
      id: 4 ,
       name: "Api Create Info",
       icon: "fa-solid fa-info",
      route: "/master/createapiinformation"
   },
   {
       id: 3,
       name: "Tradehistory",
       icon: "fa-solid fa-clock-rotate-left",
      route: "/master/tradehistory"
   },
 {
       id: 3,
       name: "TradeReport",
       icon: "fa-solid fa-file-lines",
      route: "/master/tradereport"
   },

  //  {
    //    id: 4,
    //    name: "RiskMgmt",
   //     icon: "fa-solid fa-people-roof",
   //     route: "/client/riskmgmt"
   // },
]