import React, { useState, useEffect, Component } from 'react';
import ReusedTradeHistory from "../../../component/ReusableComponents/TradHistory/TradHistory"
import { GetTradingStatus, tradingstatus } from "../../../ReduxStore/Slice/UsersSlice/dashboardSlice"

import { useDispatch, useSelector } from "react-redux";
import { getUserTradeHistorydata, tradehisotyData } from "../../../ReduxStore/Slice/UsersSlice/CommonSlice/CommonSlice"
import { CreateSession, ConnectSocket } from "../../../ReduxStore/Slice/LiveMarketData"






const TradeHistory = () => {

  const dispatch = useDispatch()




  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const trading_status = useSelector(tradingstatus && tradingstatus)
  const getTradehistory = useSelector(tradehisotyData)





  




  const userTradhistory = () => {
    dispatch(getUserTradeHistorydata({ id: userid, AdminToken: AdminToken }))
  }

  useEffect(() => {
    userTradhistory()
  }, [dispatch , trading_status && trading_status.trading_type])



  useEffect(() => {
      dispatch(GetTradingStatus({ id: userid, AdminToken: AdminToken }))
  }, [dispatch  ])




  return (
    <>
      {/* {getTradehistory && getTradehistory.length === 0 ?
        <p>No Data Found1</p>
        : */}
      <ReusedTradeHistory
        tableData={getTradehistory}
        title="Master Trade Book" />

      {/* }  */}




    </>
  )
}

export default TradeHistory