import React, { useState, useEffect, Component } from 'react';

import Formikform from "../../../Utils/Formikform"
import { Email_regex, Mobile_regex } from "../../../Utils/Common_regex"
import { Formik, Form, Field, useFormik } from 'formik';
import * as  valid_err from "../../../Utils/Common_Messages"
// import { FindOneChildUser, findOnchild } from "../../../ReduxStore/Slice/AdminChilds"
import { FindOneChildUser, findOnchild } from "../../../ReduxStore/Slice/AdminSlice/Common"

import { useDispatch, useSelector } from "react-redux";
import MainComponent from  "../../../component/ReusableComponents/Profile/Maincomponent"

const Profile = () => {

  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const dispatch = useDispatch()

  const findProfileDetails = useSelector(findOnchild)
  console.log("findProfileDetails", findProfileDetails);


  const getUserProfile = () => {
    dispatch(FindOneChildUser({ id: userid, AdminToken: AdminToken }))
  }

  useEffect(() => {
    getUserProfile()
  }, [dispatch])









  return (

    <>
    <MainComponent findProfileDetails={findProfileDetails && findProfileDetails}  Title="Master Profile"/>
    </>
  )
}

export default Profile