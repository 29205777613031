import React, { useState } from 'react'
import axios from "axios";

// import Files
import * as Config from "../Utils/Config";
import { header } from "../Utils/api_header";


//  Get Access Token 
export function Get_Broker_access_token(data, token) {
    return axios.post(`${Config.base_url}markethub/access_token`, data, {
        headers: header(token),
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}

//  Get Access Token 
export function Get_User_Trading_status(data, token) {
    return axios.post(`${Config.base_url}user/getTradingStatus`, data, {
        headers: header(token),
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//  Get Access Token 
export function Get_User_Trading_status_Off(data, token) {
    return axios.post(`${Config.base_url}user/tradingOff`, data, {
        headers: header(token),
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



//  Get Access Token 
export function Get_User_Trading_status_List(data, token) {
    return axios.post(`${Config.base_url}user/tradingstatus`, data, {
        headers: header(token),
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



//  GET USER FUND

export async function Get_User_Available_Fund(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}user/fundcheck`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}


//  GET USER FUND

export async function Get_User_Trade_History(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}user/trade-book`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}



//  GET BROKER API Key

export async function Upadate_Broker_APi_Key(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}user/updateprofile`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}



//  Upadate_Risk_Mgmt_Qty_Segment

export async function Upadate_Risk_Mgmt_Qty_Segment(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}user/updateQtyType`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}

//  Send ID for Zebull Broker AccesToken

export async function zebull_AccessToken(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}zebull-token`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}



//  Get IIFL AccesToken

export async function IIFL_AccessToken(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}iifl-token`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}

//  Get KOTAK AccesToken

export async function Get_Kotak_AccessToken(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}kotak-token`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}

//  Get KOTAK AccesToken

export async function Pass__KotakToken_To_Session(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}kotak-session   `, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}




//  Get POSITION DATA

export async function get_User_position_data(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}user/get_position_data`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}

//  Get POSITION DATA

export async function get_User_TradeHistory_data(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}user/trade-book`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}


//  Get TRADE REPORT

export async function get_User_TradeReport_data(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}user/trade-report`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}









