import React, { useEffect, useState } from 'react'
import Formikform from "../../../Utils/Formikform"
import { Formik, Form, Field, useFormik } from 'formik';
import * as  valid_err from "../../../Utils/Common_Messages"
import AlertToast from "../../../Utils/Alert_Toast"
import { toast } from "react-toastify";

import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Email_regex, Mobile_regex } from "../../../Utils/Common_regex"
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "../../../component/admin/admin-assets/css/style.css"

import { addAdmin } from "../../../ReduxStore/Slice/SuperadminSlice/SuperadminSlice"

const AddAdmin = () => {
  // for alertToast

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const admin_id = JSON.parse(localStorage.getItem('user_details')).admin_id;
  const RoleId = JSON.parse(localStorage.getItem('user_details')).roles[0];




  const isValidEmail = (email) => {
    return Email_regex(email)
  }
  const isValidContact = (mobile) => {
    return Mobile_regex(mobile)
  }

  const setRoleId = (role) => {
    if (role === "ADMIN") {
      return "2"
    }
    else if (role === "USER") {
      return "4"
    }
    else if (role === "SUPERADMIN") {
      return "1"
    }
    else if (role === "MASTER") {
      return "3"
    }
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      fullName: '',
      email: '',
      mobile: '',
     // broker: '',
      licence: '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.username) {
        errors.username = valid_err.USERNAME_ERROR;
      }
      if (!values.fullName) {
        errors.fullName = valid_err.FULLNAME_ERROR;
      }

      if (!values.mobile) {
        errors.mobile = valid_err.CONTACT_ERROR;
      } else if (!isValidContact(values.mobile)) {
        errors.mobile = valid_err.INVALID_CONTACT_ERROR;
      }

      if (!values.licence) {
        errors.tomonth = valid_err.LICENCE_ERROR;
      }


      if (!values.email) {
        errors.email = valid_err.EMPTY_EMAIL_ERROR;
      } else if (!isValidEmail(values.email)) {
        errors.email = valid_err.INVALID_EMAIL_ERROR;
      }

      return errors;
    },
    onSubmit: async (values) => {
      // console.log("values", values);
      const req = {
        "fullname": values.fullName,
        "username": values.username,
        "email": values.email,
        "phone_number": values.mobile,
        "licence": values.licence,
        "roleId": "2",
        "master_id": values.masters,
        "parent_admin_id": userid,
        "parent_role_id": setRoleId(RoleId),
        "roles": [
          "admin"
        ],

      }
      // return
      await dispatch(addAdmin({ req: req, AdminToken: AdminToken })).then((res) => {
        console.log("res.met", res.payload);
        if (res.meta.requestStatus === "fulfilled") {
          if (res.payload.status) {
            toast.success(res.payload.data)
            setTimeout(() => {
              navigate("/super/admins")
            }, 2000);
          }
          else if (res.payload.response.status == 400) {
            // setshowLoader(false)
            toast.error(res.payload.response.data.message)
          }
        }
      })
    }

  });

  const fields = [
    { name: 'username', label: 'Username', type: 'text' },
    { name: 'fullName', label: 'FullName', type: 'text' },
    { name: 'email', label: 'Email', type: 'text' },
    { name: 'mobile', label: 'Mobile', type: 'text' },
    {
      name: 'licence',
      label: 'Licence',
      type: 'text',
    },

  ];



  // useEffect(() => {
  //   if (formik.values.broker === '1' || formik.values.broker === 1) {
  //     formik.setFieldValue('api_key', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '2' || formik.values.broker === 2) {
  //     formik.setFieldValue('api_key', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '3' || formik.values.broker === 3) {
  //     formik.setFieldValue('api_key', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '4' || formik.values.broker === 4) {
  //     formik.setFieldValue('api_secret', 'null');
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '5' || formik.values.broker === 5) {
  //     formik.setFieldValue('api_secret', 'null');
  //     formik.setFieldValue('api_key', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '6' || formik.values.broker === 6) {
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //   //  formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '7' || formik.values.broker === 7) {
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '8' || formik.values.broker === 8) {
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }

  //   if (formik.values.broker === '9' || formik.values.broker === 9) {
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //   }

  //   if (formik.values.broker === '10' || formik.values.broker === 10) {
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  //   if (formik.values.broker === '11' || formik.values.broker === 11) {
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  //   if (formik.values.broker === '12' || formik.values.broker === 12) {
  //     formik.setFieldValue('api_secret', 'null');
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  //   if (formik.values.broker === '13' || formik.values.broker === 13) {
  //     formik.setFieldValue('api_key', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  //   if (formik.values.broker === '14' || formik.values.broker === 14) {
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  //   if (formik.values.broker === '15' || formik.values.broker === 15) {
  //     formik.setFieldValue('app_id', 'null');
  //     formik.setFieldValue('app_key', 'null');
  //     formik.setFieldValue('client_code', 'null');
  //     formik.setFieldValue('api_type', 'null');
  //     formik.setFieldValue('demat_userid', 'null');
  //   }
  // }, [formik.values.broker]);




  return (



    <div className="main-panel">
      <div className="content ">
        <div className="panel-header bg-primary-gradient">
          <div className="py-5 page-inner">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <h4 className="flex-grow-1 text-white fw-bold">Add Admin</h4>
              <Link to='/super/admins' className='btn btn-light'>Back</Link>
            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row row-card-no-pd  shadow-lg mt--2">
            <div className='data-table-div d-flex flex-column'>
              <Formikform fieldtype={fields.filter(field => !field.showWhen || field.showWhen(formik.values))} formik={formik} btn_name="Add Child" />
              <AlertToast message="Success ganpat!" type="info" position="top-center" />


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddAdmin
