import React, { useState, useEffect } from 'react'
import { Nav_Config } from "./Nav_Config"
import { Link } from 'react-router-dom'
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
// import { FindOneChildUser, findOnchild } from "../../ReduxStore/Slice/AdminChilds"
import { FindOneChildUser, findOnchild } from "../../ReduxStore/Slice/AdminSlice/Common"


import { useDispatch, useSelector } from "react-redux";



const Client_sidebar = ({ sidebar }) => {

  const location = useLocation()

  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;

  const onchild = useSelector(findOnchild != undefined && findOnchild)
  const dispatch = useDispatch()


  const [isOpen, setIsopen] = useState(false);


  const [isMinimized, setIsMinimized] = useState(false);

  const getUserProfile = () => {
    dispatch(FindOneChildUser({ id: userid, AdminToken: AdminToken }))
  }

  useEffect(() => {
    getUserProfile()
  }, [dispatch])



  // for Handel Sidebar Toggle
  const handleClick = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() => {
    if (isMinimized) {
      document.body.classList.add('sidebar_show-client');
    } else {
      document.body.classList.remove('sidebar_show-client');
    }
  }, [isMinimized]);
  // for Handel Sidebar Toggle

  const setBroker = (id) => {
    if (id == 1) {
      return "Market Hub"
    } else if (id == 2) {
      return "Alice Blue"
    } else if (id == 3) {
      return "Master Trust"
    }
    else if (id == 4) {
      return "Motilal Oswal"
    }
    else if (id == 5) {
      return "Zebull"
    }
    else if (id == 6) {
      return "IIFl"
    }
    else if (id == 7) {
      return "Kotak"
    }
    else if (id == 8) {
      return "Mandot"
    }
    else if (id == 9) {
      return "Choice"
    }
    else if (id == 10) {
      return "Anand Rathi"
    }
    else if (id == 11) {
      return "B2C"
    }
    else if (id == 12) {
      return "Angel"
    }
    else if (id == 13) {
      return "Fyers"
    }
    else if (id == 14) {
      return "5-Paisa"
    }
    else if (id == 15) {
      return "Zerodha"
    }


  }








  return (
    <>
      <div className={`sidenav ${sidebar == true ? 'hide' : 'show'}`} id="sidenav-main">
        {/* Sidenav header */}
        <div className=" d-flex align-items-center">
          {/* <a className="navbar-brand text-light" href="#">
             
              COPY TRADING
            </a> */}
          <div className="ml-auto">
            {/* Sidenav toggler */}
            <div className="sidenav-toggler sidenav-toggler-dark d-md-none" data-action="sidenav-unpin" data-target="#sidenav-main" >
              <div className="sidenav-toggler-inner">
                <button onClick={handleClick} className="btn btn-toggle toggle-sidebar">
                  <i className="fa-solid fa-bars"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* User mini profile */}
        <div className="text-center sidenav-user d-flex flex-column align-items-center justify-content-between">
          {/* Avatar */}
          <div>
            <a href="#" className="avatar rounded-circle avatar-xl">
              <i className="fas fa-user-circle fa-4x" />
            </a>
            <div className="mt-4">
              <h5 className="mb-0 text-white"> {onchild && onchild[0].fullname}  </h5>
              <span className="mb-3 text-sm text-white d-block opacity-8">Master User</span>
              <span className="mb-3 text-sm text-white d-block opacity-8">{setBroker(onchild && onchild[0].broker)}</span>

              <a className="shadow btn btn-sm btn-white btn-icon rounded-pill hover-translate-y-n3 w-75">
                <span className="btn-inner--icon"><i className="fa fa-coins fa-2x" /></span>
                <span className="btn-inner--text " style={{ fontSize: '13px' }
                }>$2,777.90</span>
              </a>
            </div>
          </div>
          {/* User info */}
          {/* Actions */}
          <div className="mt-4 w-100 actions d-flex justify-content-between">
          </div>
        </div>
        {/* Application nav */}

        <div className="clearfix nav-application">
          {Nav_Config && Nav_Config.map((item) => {
            return <>
              <Link to={item.route} className={`text-sm btn btn-square ${location.pathname == item.route ? "active" : ""} `}>
                <span className="btn-inner--icon d-block"><i class={`${item.icon} fa-2x`}></i></span>
                <span className="pt-2 btn-inner--icon d-block">{item.name}</span>
              </Link>

            </>
          })}

        </div>

      </div>
    </>
  )
}

export default Client_sidebar






{/* <a href="#/" className="text-sm btn btn-square   ">
<span className="btn-inner--icon d-block"><i className="far fa-download fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">Deposit</span>
</a>
<a href="#/" className="text-sm btn btn-square  ">
<span className="btn-inner--icon d-block"><i className="fas fa-arrow-alt-circle-up fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">Withdraw</span>
</a>
<a href="#/" className="text-sm btn btn-square ">
<span className="btn-inner--icon d-block"><i className="fal fa-history fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">Profit History</span>
</a>
<a href="#/" className="text-sm btn btn-square ">
<span className="btn-inner--icon d-block"><i className="fas fa-money-check-alt fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">Transactions</span>
</a>
<a href="#/" className="text-sm btn btn-square  ">
<span className="btn-inner--icon d-block"><i className="fab fa-stack-exchange fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">Swap Crypto</span>
</a>
<a href="#/" className="text-sm btn btn-square ">
<span className="btn-inner--icon d-block"><i className="fas fa-exchange fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">Transfer funds</span>
</a>
<a href="#/" className="text-sm btn btn-square ">
<span className="btn-inner--icon d-block"><i className="far fa-receipt fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">Managed Accounts</span>
</a>
<a href="#/" className="text-sm btn btn-square ">
<span className="btn-inner--icon d-block"><i className="fas fa-address-card fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">Profile</span>
</a>
<a href="#/" className="text-sm btn btn-square ">
<span className="btn-inner--icon d-block"><i className="fas fa-hand-holding-seedling fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">Trading Plans</span>
</a>
<a href="#/l" className="text-sm btn btn-square  ">
<span className="btn-inner--icon d-block"><i className="far fa-hand-holding-seedling fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">My Plans</span>
</a>
<a href="#/" className="text-sm btn btn-square   ">
<span className="btn-inner--icon d-block"><i className="fas fa-graduation-cap fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">Education</span>
</a>
<a href="#/" className="text-sm btn btn-square ">
<span className="btn-inner--icon d-block">
  <i className="fas fa-wave-square fa-2x" />
</span>
<span className="pt-2 btn-inner--icon d-block">Trade Signals</span>
</a>
<a href="#/" className="text-sm btn btn-square ">
<span className="btn-inner--icon d-block"><i className="fas fa-retweet fa-2x" /></span>
<span className="pt-2 btn-inner--icon d-block">Referrals</span>
</a> */}