import React from 'react'
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";
import ClientDashboard from '../Layout/Child_Clients/Dashboard/Dashboard';
import Signals from '../Layout/Child_Clients/Signals/Signals';
import RiskMgmt from '../Layout/Child_Clients/RiskMgmt/RiskMgmt';
import TradeHistory from '../Layout/Child_Clients/TradeHistory/TradeHistory';
import TradingStatus from '../Layout/Child_Clients/TradingStatus/TradingStatus';
import Positions from '../Layout/Child_Clients/Positions/Positions';
import CreateApiInformation from '../Layout/Child_Clients/CreateApiInformation/CreateApiInformation';
import Profile from '../Layout/Child_Clients/Profile/Profile';
import Header from '../component/client/Header';
import NotFound from "../Layout/NotFound"

// import Client_sidebar from '../component/client/Client_sidebar';


// const Profile = React.lazy(() => import('../Layout/Child_Clients/Profile/Profile'))
// const CreateApiInformation = React.lazy(() => import('../Layout/Child_Clients/CreateApiInformation/CreateApiInformation'))
// const TradingStatus = React.lazy(() => import('../Layout/Child_Clients/TradingStatus/TradingStatus'))
// const TradeHistory = React.lazy(() => import(''))
// const Client_sidebar = React.lazy(() => import(''))
// const Client_sidebar = React.lazy(() => import(''))
// const Client_sidebar = React.lazy(() => import(''))

const Client = () => {

    const location = useLocation();
    //   const navigate = useNavigate()
    const role_id = localStorage.getItem("Role")

    return (
        <>
            {/* <Header /> */}
            {location.pathname !== "/child" ? <Header /> : null}
            {/* {location.pathname !== "/client" || location.pathname !== "/client/*" ? <Client_sidebar /> : null} */}
            <Routes path="/child">
                <>
                    <Route exact path="/dashboard" element={<ClientDashboard />} />
                    <Route exact path="/signals" element={<Signals />} />
                    <Route exact path="/riskmgmt" element={<RiskMgmt />} />
                    <Route exact path="/tradehistory" element={<TradeHistory />} />
                    <Route exact path="/tradingstatus" element={<TradingStatus />} />
                    <Route exact path="/createapiinformation" element={<CreateApiInformation />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/positions" element={<Positions />} />
                    <Route path="*" element={<NotFound />} />

                </>
            </Routes>
        </>
    )
}

export default Client