import React, { useState, useEffect, Component } from 'react';

// import { FindOneChildUser, findOnchild } from "../../../ReduxStore/Slice/AdminChilds"
// import { FindOneChildUser, findOnchild , get_User_position_data } from "../../../ReduxStore/Slice/AdminSlice/Common"

// import { FindOneChildUser, findOnchild , get_User_position_data } from "../../../ReduxStore/Slice/AdminSlice/Common"

import { getUserPositionData, positionData } from "../../../ReduxStore/Slice/UsersSlice/CommonSlice/CommonSlice"
import { GetTradingStatus, tradingstatus } from "../../../ReduxStore/Slice/UsersSlice/dashboardSlice"





import { useDispatch, useSelector } from "react-redux";
// import MainComponent from  "../../../component/ReusableComponents/TradingPositions/Maincomponent"
import MainComponent from "../../../component/ReusableComponents/TradingPositions/Positions"

const Profile = ({ }) => {

  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const dispatch = useDispatch()
  const trading_status = useSelector(tradingstatus && tradingstatus)


  const getpositions = useSelector(positionData)







  const userPosition = () => {
    dispatch(getUserPositionData({ id: userid, AdminToken: AdminToken }))
  }

  useEffect(() => {
    userPosition()
  }, [dispatch, trading_status && trading_status.trading_type])





  useEffect(() => {
    dispatch(GetTradingStatus({ id: userid, AdminToken: AdminToken }))
  }, [dispatch])





  return (

    <>
      <div>

        <div className='main-content position-relative'>
          <div className="page-content">
            {/* Page title */}
            <div className="page-title">
              <div className="row justify-content-between align-items-center">
                <div className="mb-3 col-md-6 mb-md-0">
                  <h5 className="mb-0 text-white h3 font-weight-400">Positions</h5>
                </div>
              </div>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row profile">
                      <MainComponent
                        getPositions={getpositions && getpositions}
                        Title="Positions & Holdings" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile