import { configureStore } from "@reduxjs/toolkit";


//AUTH SLICE
import AuthSlice from "../Slice/AuthSlice/AuthSlice";


// ADMIN SLICE 
import AdminDashboardSlice from "../Slice/AdminSlice/Dashboard/dashboardSlice"
import AdminChilds from "../Slice/AdminSlice/Common"
import Systemslice from "../Slice/AdminSlice/System/SystemSlice"

//  MASTER SLICE
import MasterSlice from "../Slice/AdminSlice/Master/MasterSlice"

//  CHILD SLICE
import RiskMgmtSlice from "../Slice/UsersSlice/ChildSlice/RiskMgmtSlice"

// COMMON FOR MASTER OR CHILD 
import CommonSlice from "../Slice/UsersSlice/CommonSlice/CommonSlice"


// COMMON FOR MASTER OR CHILD 
import DashboardSlice from "../Slice/UsersSlice/dashboardSlice"

// For Live Market  Data 
import LiveMarketData from "../Slice/LiveMarketData"



// SUPERADMIN  SLICE

import SuperadminSlice from "../Slice/SuperadminSlice/SuperadminSlice"

const store = configureStore({
    reducer: {
        AuthSlice: AuthSlice.reducer,
        Systemslice: Systemslice.reducer,
        // Adminslice: Adminslice.reducer,
        AdminChilds: AdminChilds.reducer,
        DashboardSlice: DashboardSlice.reducer,
        CommonSlice: CommonSlice.reducer,
        RiskMgmtSlice: RiskMgmtSlice.reducer,
        aaDashboardSlice: AdminDashboardSlice.reducer,
        MasterSlice: MasterSlice.reducer,
        LiveMarketData: LiveMarketData.reducer,
        SuperadminSlice: SuperadminSlice.reducer

    }

})

export default store