import React, { useState, useEffect } from "react";
// import './admin-assets/css/colors/dark.css';
import "./admin-assets/css/colors/green.css";
import "./admin-assets/css/customs.css";
import "./admin-assets/css/line.css";
// import './admin-assets/css/style.css';
import "./admin-assets/css/bootstrap.min.css";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

const Header = () => {
  const navigate = useNavigate();
  const [isMinimized, setIsMinimized] = useState(false);
  const [ShowDropdown, setShowDropwdown] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false)

  const AdminToken = JSON.parse(
    localStorage.getItem("user_details")
  ).accessToken;

  const handleClick = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() => {
    if (isMinimized) {
      document.body.classList.add("sidebar_minimize");
    } else {
      document.body.classList.remove("sidebar_minimize");
    }
  }, [isMinimized]);

  const handleClickDropwdowm = () => {
    // alert()
    setShowDropwdown(!ShowDropdown);
  };

  // useEffect(() => {
  //   if (ShowDropdown) {
  //     document.body.classList.add('show');
  //   } else {
  //     document.body.classList.remove('show');
  //   }
  // }, [ShowDropdown]);

  const { exp } = jwtDecode(AdminToken);

  const ExpirationTime = exp * 1000 - 60000;

  if (Date.now() >= ExpirationTime) {
    localStorage.removeItem("user_details");
    navigate("/");
  }

  const Logout = () => {
    localStorage.removeItem("user_details");
    navigate("/");
  };

  return (
    // <div className="wrapper">
    <div className="main-header">
      {/* Logo Header */}
      <div className="logo-header" data-background-color="blue">
        {/* <a href="/" className="logo" style={{ fontSize: 27, color: "#fff" }}>
          CopyTrading
        </a> */}
        <img src="./logolight.png" alt="TradeX" className="logo" />
        <button
          onClick={handleClick}
          className="ml-auto navbar-toggler sidenav-toggler"
          type="button"
          data-toggle="collapse"
          data-target="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="fa-solid fa-bars"></i>
          </span>
        </button>
        <button className="topbar-toggler more">
          <i className="icon-options-vertical " />
        </button>
        <div className="nav-toggle">
          <button
            onClick={handleClick}
            className="btn btn-toggle toggle-sidebar"
          >
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
      </div>
      {/* End Logo Header */}
      {/* Navbar Header */}


      {/* <nav
        className="navbar navbar-header navbar-expand-lg"
        data-background-color="blue"
      >
        <div className="container-fluid justify-content-end">
          <>

            <div className="btn-group">
              <button
                type="button"
                className="btn  dropdown-toggle "
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="text-white fas fa-user" />
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    Account Settings
                  </a>
                  <a className="dropdown-item" href="#">
                    Change Password
                  </a>
                  <div className="dropdown-divider" />
                  <a className="dropdown-item" onClick={() => Logout()}>
                    Logout
                  </a>
                  <form
                    id="logoutform"
                    action="#"
                    method="POST"
                    style={{ display: "none" }}
                  >
                    <input
                      type="hidden"
                      name="_token"
                      defaultValue="Sy02Ef8SJQQeRe43QourTrcQGvl4hxsbz0Be4dpv"
                    />
                  </form>
                </li>
              </ul>
            </div>
          </>
        </div>
      </nav> */}

      <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
        <ul className="navbar-nav ml-lg-auto align-items-center  d-flex justify-content-end">

          <li class=" dropdown dropdown-animate col-md-12 d-flex justify-content-end">
            <a class="nav-link pr-lg-0" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" onClick={() => setShowDropdown(!showDropdown)}>
              <div class="media media-pill align-items-center">
                <span class="avatar rounded-circle">
                  <i class="fas fa-user-circle fa-2x"></i>
                </span>
                <div class="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold text-white ">
                    Admin
                  </span>
                </div>
              </div>
            </a>
            <div class={`dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow ${showDropdown ? "show" : ""}`} >
              <h6 class="px-0 dropdown-header">Hi,

                Admin
              </h6>
              <Link to="profile" className="dropdown-item">
                <i class="far fa-user"></i>
                My profile
              </Link>
              <div class="dropdown-divider"></div>
              <li className="dropdown-item" onClick={() => Logout()} >
                <i class="fa fa-sign-out-alt"></i>
                Logout
              </li>
            </div>
          </li>
        </ul>
      </div >
    </div>
  );
};

export default Header;
