


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Get_User_Trading_status_List, Get_User_Available_Fund, Upadate_Broker_APi_Key, zebull_AccessToken, IIFL_AccessToken, Get_Kotak_AccessToken, Pass__KotakToken_To_Session, get_User_position_data  , get_User_TradeHistory_data , get_User_TradeReport_data} from "../../../../Service/user.service"


export const GetTradingStatusList = createAsyncThunk("user/tradingstatus", async (data, token) => {
    const { id, AdminToken } = data
    try {
        const res = await Get_User_Trading_status_List({ userid: id }, AdminToken)
        if (res.status) {
            return await res?.data;
        }
    } catch (err) {
        console.log("error", err);
    }
});

export const GetUserAvailableFund = createAsyncThunk("user/user_fund", async (data, token) => {
   
    const { id, AdminToken } = data
    try {
        const res = await Get_User_Available_Fund({ id: id }, AdminToken)
        // if (res.status) {
        return await res;
        // }
    } catch (err) {
        console.log("error", err);
    }
});


export const UpadateBrokerAPiKey = createAsyncThunk("user/updateapikey", async (data, token) => {
    const { req, AdminToken } = data
    try {
        const res = await Upadate_Broker_APi_Key(req, AdminToken)
        // if (res.status) {
        return await res;
        // }
    } catch (err) {
        console.log("error", err);
    }
});

export const zebullAccessToken = createAsyncThunk("user/zebullToken", async (data, token) => {
    const { req, AdminToken } = data
    try {
        const res = await zebull_AccessToken(req, AdminToken)
        // if (res.status) {
        return await res;
        // }
    } catch (err) {
        console.log("error", err);
    }
});


export const IIFLAccessToken = createAsyncThunk("user/IIFLToken", async (data, token) => {
    const { client_id, AdminToken } = data
    try {
        const res = await IIFL_AccessToken({ client_id: client_id }, AdminToken)
        // if (res.status) {
        return await res;
        // }
    } catch (err) {
        console.log("error", err);
    }
});
export const GetKotakAccessToken = createAsyncThunk("user/KotakToken", async (data, token) => {
    const { client_id, AdminToken } = data
    try {
        const res = await Get_Kotak_AccessToken({ client_id: client_id }, AdminToken)
        // if (res.status) {
        return await res;
        // }
    } catch (err) {
        console.log("error", err);
    }
});

export const PassKotakTokenToSession = createAsyncThunk("user/passKotakTokento_nextapi", async (data, token) => {
    const { client_id, AdminToken, otp } = data
    try {
        const res = await Pass__KotakToken_To_Session({ client_id: client_id, otp: otp }, AdminToken)
        // if (res.status) {
        return await res;
        // }
    } catch (err) {
        console.log("error", err);
    }
});

export const getUserPositionData = createAsyncThunk("user/get_position_data", async (data) => {
    const { id, AdminToken } = data
    console.log("Data", data);
    try {
        const res = await get_User_position_data({ id: id }, AdminToken)
        // if (res.status) {
        return await res;
        // }
    } catch (err) {
        console.log("error", err);
    }
});

export const getUserTradeHistorydata = createAsyncThunk("user/tradehisotry", async (data) => {
    const { id, AdminToken } = data
    console.log("Data", data);
    try {
        const res = await get_User_TradeHistory_data({ id: id }, AdminToken)
        // if (res.status) {
        return await res;
        // }
    } catch (err) {
        console.log("error", err);
    }
});

export const getUserTradeReportdata = createAsyncThunk("user/tradeReport", async (data) => {
    const { id, AdminToken } = data
    // console.log("Data", data);
    try {
        const res = await get_User_TradeReport_data({ id: id }, AdminToken)
        // if (res.status) {
        return await res;
        // }
    } catch (err) {
        console.log("error", err);
    }
});




const UserComonSlice = createSlice({
    name: "UserComonSlice",
    initialState: {
        isLoading: false,
        tradingstatuslist: [],
        fundetails: [],
        updatebrokerapikey: [],
        zebullAccessData: [],
        iiflAccessData: [],
        kotakAccessData: [],
        kotakSessionAccessData: [],
        positiondata: [],
        tradehisoty: [],
        tradereport: []
    }
    ,

    recuders: {},
    extraReducers: {
        [GetTradingStatusList.pending]: (state, action) => {
            console.log("Pending")
        },
        [GetTradingStatusList.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            return { ...state, tradingstatuslist: payload };
        },
        [GetTradingStatusList.rejected]: (state) => {
            state.isError = true;
        },
        [GetUserAvailableFund.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            return { ...state, fundetails: payload };
        },
        [UpadateBrokerAPiKey.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            return { ...state, updatebrokerapikey: payload };
        },
        [zebullAccessToken.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            return { ...state, zebullAccessData: payload };
        },
        [IIFL_AccessToken.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            return { ...state, iiflAccessData: payload };
        },
        [GetKotakAccessToken.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            return { ...state, kotakAccessData: payload };
        },
        [PassKotakTokenToSession.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            return { ...state, kotakSessionAccessData: payload };
        },
        [getUserPositionData.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            return { ...state, positiondata: payload };
        },
        [getUserTradeHistorydata.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            return { ...state, tradehisoty: payload };
        },
        [getUserTradeReportdata.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            return { ...state, tradereport: payload };
        },
    },
})

export const tradingstatuslist = (state) => state && state?.CommonSlice?.tradingstatuslist;
export const fundetails = (state) => state && state?.CommonSlice?.fundetails;
export const kotakAccessData = (state) => state && state?.CommonSlice?.kotakAccessData;
export const positionData = (state) => state && state?.CommonSlice?.positiondata;
export const tradehisotyData = (state) => state && state?.CommonSlice?.tradehisoty;
export const tradereportData = (state) => state && state?.CommonSlice?.tradereport;


export default UserComonSlice;

