import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CreateSocketSession, ConnctSocket } from "../../Service/alices_socket.service"


export const CreateSession = createAsyncThunk("aliceSocket/createSession", async (data) => {
  console.log("teststsstttt",);
  const { userId, userSession } = data

  let type = { loginType: "API" }
  try {
    const res = await CreateSocketSession(userId, userSession, type)
    return await res;
  } catch (err) {
    console.log("error", err);
  }
});

export const ConnectSocket = createAsyncThunk("aliceSocket/ConnectSession", async (data,) => {
  const { userId, userSession, handleResponse, channelList } = data
  try {
    const res = await ConnctSocket(userId, userSession, handleResponse, channelList)

      return await res?.data;

  } catch (err) {
    console.log("error", err);
  }
});

const LiveMarketData = createSlice({
  name: "LiveMarketData",
  initialState: {
    isLoading: false,
    createsession: [],
    connectsession: [],

  },

  recuders: {},
  extraReducers: {

    [CreateSession.pending]: (state, action) => {
      console.log("Pending")
      // state.isLoading = true;
    },
    [CreateSession.fulfilled]: (state, { payload }) => {
    //  state.isLoading = false;
      return { ...state, createsession: payload };
    },
    [CreateSession.rejected]: (state) => {
      // state.isError = true;
    },
    [ConnectSocket.fulfilled]: (state, { payload }) => {
      // state.isError = false;
      return { ...state, connectsession: payload };
    },
    // [ConnectSocket.pending]: (state) => {
    //   state.isError = true;
    //   return { ...state, connectsession: [] };
    // },

  },
})

// export const accesstokendata = (state) => state.LiveMarketData.accesstoken;
// export const connectsessiondata = (state) => state.LiveMarketData.connectsession;

export default LiveMarketData;


