
import React, { useState, useEffect } from 'react';
import * as Config from "../../../Utils/Config";
import Model from "../../../component/ReusableComponents/Model/Model"
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

const ApiCreateInfo = ({ Content, title, userData }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [filterdata, setFilterData] = useState("");


    const Data = [
        {
            id: 1,
            HeadingTitle: "Market Hub",
            describtion:
                "Please Update CLIENT CODE , PASSWORD CODE And VERIFICATION CODE for all these details please contact with Market hub broker then Submit  And  Login With Api Trading On...",
            // LinkOne: "https://www.5paisa.com/developerapi/authorization",
            // LinkTwo: "encryption key 5 paisa :- vEhJgDxk3PJbRqhK5b2BrA80ez5aJY8x",
            // Apicreate:'you will get Api Secret Key And App id please Update this detail in your Profile...'
        },
        {
            id: 2,
            HeadingTitle: "Alice Blue",
            describtion:
                "Kindly follow these steps to link your demat account with this Algo Software.",
            // Kindly click on below mention brokerage firm link it will redirect to your concern brokerage API link and generate API with this. Kindly follow instruction as your broker or sub broker link guide to you and update our link and connect your demat with our Algo software.
            LinkOne: "https://ant.aliceblueonline.com/?appcode=G9EOSWCEIF9ARCB",
            LinkTwo: `${Config.broker_redirect_url}aliceblue-token?key=YOUR_CLIENT_KEY`,
            link3: `${Config.broker_redirect_url}aliceblue-token?key=123abc`,
            Apicreate: 'You will get APP code and Secret Key, please Update them on your Profile in this software.',
            // broker_id: 3
        },
        {
            id: 3,
            HeadingTitle: "Master Trust",
            describtion:
                "Kindly follow these steps to link your demat account with this Algo Software.",
            LinkOne: "https://develop-masterswift.mastertrust.co.in/",
            LinkTwo: `${Config.broker_redirect_url}mastertrust/access_token`,
            Apicreate: 'You will get API Secret Key and APP code, please Update them on your Profile in this software.'
        },
        {
            id: 4,
            HeadingTitle: "Motilal Oswal ",
            describtion:
                "Kindly click on the link given below and  Go to Get Start Button And login Your Account and Next Step is create App Follow Next step and mail To motilal broker.",

            LinkOne: `https://invest.motilaloswal.com/Home/TradingAPI`,

            LinkTwo: `${Config.broker_redirect_url}motilaloswal/access_token?email=YOUR_PANEL_EMAIL`,
            link3: `e.g - ${Config.broker_redirect_url}motilaloswal/access_token?email=ss@gmail.com`,
            Apicreate: 'You will get API Secret Key and APP code, please Update them on your Profile in this software. And Send Mail This Formate',

            describtion1: `  To :- tradingapi@motilaloswal.com
          Subject :- Motilal Api Key
          --------------------------------------------------------
          Hello Sir,
      
          Please Activate the given API Key for the "Enter Your Client Code" Demat Code.
          
          1. Api Key :- Enter Your APIKEY
          
          In Reference to "CDPL9786" ID
          
          Regards & Thanks
          
          `,
        },
        {
            id: 5,
            HeadingTitle: "Zebull",
            Link1: "https://go.mynt.in/#/",
            Apicreate: "This Url Use To login Demat Account And Go Profile Section And Click On Api Key Section And Generate APi Key In 10 YEAR. Page Refresh and Copy App Key And Vender Code Copy and Paste Your profile Section in Panel.Update Panel Profile on User Id And Password On Demat password And APP Key And Date Of Birth."
            // Link2: "https://ant.aliceblueonline.com/plugin/callback",
          },
        {
            id: 6,
            HeadingTitle: "IIFL",
            describtion:
                "Please update the USERNAME and PASSWORD for all these details please contact with IIFL broker then submit and log in with API trading. If you are using IIFL broker it is mandatory to have the IIFL blaze app.",
            // LinkOne: "https://login.fyers.in/?cb=https://apidashboard.fyers.in",
            // LinkTwo: `${Config.broker_redirect_url}fyersapi`,
            //    Apicreate:'you will get Api Secret Key And App id please Update this detail in your Profile...'
        },
        {
            id: 7,
            HeadingTitle: "Kotak Securities",
            describtion:
                "Kindly click on below mention brokerage firm link it will redirect to your concern brokerage API link and generate API with this. Kindly follow instruction as your broker or sub broker link guide to you and update our link and connect your dmat with our Algo software.",

            LinkOne: "https://tradeapi.kotaksecurities.com/devportal/applications",

            describtion2: 'Login account and click "default application" and next click on the production key in the sidebar and the consumer key and consumer secret update on your profile and demat password and trading API password both update on your profile. The access code generated is sent to the registered email address & mobile number. A generated access code is valid for the day (till 11:59:59 pm on the same day).',
            // LinkTwo: `${Config.broker_redirect_url}fyersapi`,
            //    Apicreate:'you will get Api Secret Key And App id please Update this detail in your Profile...'
        },
        {
            id: 8,
            HeadingTitle: "Mandot",
            describtion:
                "Please Update USERNAME And PASSWORD for all these details please contact with Mandot broker then Submit  And  Login With Api Trading On...",
            // LinkOne: "https://login.fyers.in/?cb=https://apidashboard.fyers.in",
            // LinkTwo: `${Config.broker_redirect_url}fyersapi`,
            //    Apicreate:'you will get Api Secret Key And App id please Update this detail in your Profile...'
        },
        {
            id: 9,
            HeadingTitle: "Choice",
            describtion:
                "Please Update VENDOR ID,USER ID, PASSWORD, VENDOR KEY, ENCRYPTION SECRET KEY And ENCRYPTION IV for all these details please contact with Choice broker then Submit  And  Login With Api Trading On...",
            // LinkOne: "https://login.fyers.in/?cb=https://apidashboard.fyers.in",
            // LinkTwo: `${Config.broker_redirect_url}fyersapi`,
            //    Apicreate:'you will get Api Secret Key And App id please Update this detail in your Profile...'
        },



        {
            id: 10,
            HeadingTitle: "Anand Rathi",
            describtion:
                "Please Update SECRET KEY And APP KEY for all these details please contact with Anand Rathi broker then Submit  And Login With Api Trading On...",
            // LinkOne: "https://login.fyers.in/?cb=https://apidashboard.fyers.in",
            // LinkTwo: `${Config.broker_redirect_url}fyersapi`,
            //    Apicreate:'you will get Api Secret Key And App id please Update this detail in your Profile...'
        },

        {
            id: 11,
            HeadingTitle: "B2C",
            describtion:
                "Kindly follow these steps to link your demat account with this Algo Software.",
            // LinkOne: "https://login.fyers.in/?cb=https://apidashboard.fyers.in",
            // LinkTwo: `${Config.broker_redirect_url}fyersapi`,
            //    Apicreate:'you will get Api Secret Key And App id please Update this detail in your Profile...'
        },
        {
            id: 12,
            HeadingTitle: "Angel",
            describtion:
                "Kindly follow these steps to link your demat account with this Algo Software.",
            LinkOne: "https://smartapi.angelbroking.com/",
            LinkTwo: `${Config.broker_redirect_url}angelbroking/access_token?email=YOUR_PANEL_EMAIL`,
            link3: `e.g - ${Config.broker_redirect_url}angelbroking/access_token?email=ss@gmail.com`,
            Apicreate: 'You will get API Secret Key and APP code, please Update them on your Profile in this software.'
        },
        {
            id: 13,
            HeadingTitle: "Fyers",
            describtion:
                "Kindly follow these steps to link your demat account with this Algo Software.",
            LinkOne: "https://myapi.fyers.in/dashboard/",
            LinkTwo: `${Config.broker_redirect_url}fyers/access_token`,
            Apicreate: 'You will get API Secret Key and APP code, please Update them on your Profile in this software.'
        },
        {
            id: 14,
            HeadingTitle: "5 Paisa",
            describtion:
                "Kindly follow these steps to link your demat account with this Algo Software.",
            LinkOne: "https://invest.5paisa.com/DeveloperAPI/APIKeys",
            // LinkTwo: "encryption key 5 paisa :- vEhJgDxk3PJbRqhK5b2BrA80ez5aJY8x",
        },

        {
            id: 15,
            HeadingTitle: "Zerodha",
            describtion:
                "Kindly follow these steps to link your demat account with this Algo Software.",
            LinkOne: " https://kite.trade/",
            LinkTwo: `${Config.broker_redirect_url}zerodha/access_token?email=YOUR_PANEL_EMAIL`,
            link3: `e.g - ${Config.broker_redirect_url}zerodha/access_token?email=ss@gmail.com`,
            Apicreate: 'You will get API Secret Key and APP code and Update them on your Profile in this software.'
        },






    ];




    const getInfo = (e) => {
        const event = e.target.value;
        const Filterdata = Data.filter((item) => {
            if (parseInt(item.id) == parseInt(event)) {
                return item;
            }
        });
        setModalOpen(true);
        setFilterData(Filterdata);
    };







    const { id, HeadingTitle, describtion, LinkOne, LinkTwo, link3, ZBStep2, Apicreate, describtion1, describtion2 } = filterdata && filterdata[0];

    console.log("HeadingTitle", HeadingTitle);
    return (
        <div><div className='main-content position-relative'>
            <div className="page-content">
                <div className="page-title">
                    <div className="row justify-content-between align-items-center">
                        <div className="mb-3 col-md-6 mb-md-0">
                            <h5 className="mb-0 text-white h3 font-weight-400">{title}</h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">

                                    {
                                        Data && Data.map((item, index) => {
                                            return userData.broker == item.id ?
                                                <>

                                                    <div className='col-md-4 my-2 '>
                                                        <button className='btn btn-primary  w-75' key={index} value={item.id} onClick={(e) => getInfo(e)}>{item.HeadingTitle}</button>
                                                    </div>
                                                </> : ""
                                        })
                                    }


                                    <Model isOpen={modalOpen} onClose={() => setModalOpen(false)} title="Set Broker Api Key" btn_name="Uupa"
                                        content={
                                            <>

                                                <div className='px-2'>
                                                    <h6 > API Process of {HeadingTitle}: -</h6>
                                                    <p>{describtion}</p>

                                                    {
                                                        !LinkOne ? "" :
                                                            <>
                                                                <h6 className=' text-underline'>Step 1:  Click below link and Login :- </h6>
                                                                <Link className='text-info' to={LinkOne}>{LinkOne}</Link>

                                                            </>
                                                    }

                                                    {!LinkTwo ? '' : <>
                                                        <h6 className=' text-underline mt-2'>Step 2:  Enter your Details and the Redirect URL which is given below.   : -</h6>

                                                        <Link className='text-info' to={toString(LinkTwo)}
                                                        > {LinkTwo}
                                                        </Link>
                                                    </>}

                                                    {!ZBStep2 ? '' : <>
                                                        <h6 className=" text-underline mt-2">
                                                            Step 2: &nbsp;
                                                            <span className="fw-Bold">How to find API?</span>
                                                        </h6>
                                                        <p className="fw-light">
                                                            {ZBStep2 ? ZBStep2 : ''}

                                                        </p>
                                                    </>}


                                                    {link3 == "" ?  <>
                                                        <h6 className='fw-1 text-danger my-2'>NOTE: Please remember to use the email ID of your panel at the place of "YOUR_PANEL_EMAIL".</h6>
                                                    </>   : <h6>{link3}</h6> }


                                                    {!Apicreate ? '' : <>

                                                        <h6 className='text-underline  mt-2'>Step 3:  Create API</h6>
                                                        <p>       {Apicreate ? Apicreate : ''}</p>
                                                    </>}

                                                    <h6> <b> {describtion1}</b></h6>
                                                    <h6> {describtion2}</h6>
                                                </div>

                                            </>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></div >
    )
}

export default ApiCreateInfo