import React, { useState, useEffect } from 'react';
import './client_assets/css/colors/dark.css';
import './client_assets/css/colors/green.css';
import './client_assets/css/style.css';
// import './client_assets/css/bootstrap.min.css';
import Client_sidebar from './Client_sidebar';
import Nav_Header from './Nav_Header';
// import TradeHistory from '../../Layout/Client/TradeHistory/TradeHistory';
// import Client from '../../Route/Client';


const Header = () => {

  const [isMinimized, setIsMinimized] = useState(false);
  const [ShowDropdown, setShowDropwdown] = useState(false);

  const handleClick = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() => {
    if (isMinimized) {
      document.body.classList.add('sidebar_show-client');
    } else {
      document.body.classList.remove('sidebar_show-client');
    }
  }, [isMinimized]);

  return (
    <div>
      <Nav_Header />
      <div className="container-application">
        {/* Sidenav */}
        <Client_sidebar />
      </div>
    </div>



  )
}

export default Header