// import React from 'react';
// import { toast, ToastContainer } from 'react-toastify';
// import "react-toastify/dist/ReactToastify.css";


// function Alert_Toast({alertmsg , alerttype , }) {
//   const notify = () => {
   
//     toast.success("Success Notification !", {
//       position: toast.POSITION.TOP_CENTER,
//       theme: "colored"
//     });
//       };



//    return (
//       <>
//         <button onClick={notify}>Notify</button>;
//         <ToastContainer autoClose={3000} />
//       </>
//     );
// }


// export default Alert_Toast;





import React from 'react';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const AlertToast = ({ message, type , position  } ) => {
  
  // console.log("test" , message);
  // console.log("type" , type);
  // const notify = () => {
  //   toast[type](message, {
  //     position: toast.POSITION.TOP_CENTER,
  //     theme: 'colored',
  //   });
  // };

  return (
    <>
      {/* <button onClick={notify}>Notify</button> */}
      <ToastContainer autoClose={2000}   limit={1} position={position}  message={message} type={type} Transition="zoom" theme="colored"/>
    </>
  );
};

AlertToast.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
};

AlertToast.defaultProps = {
  type: 'success',
};

export default AlertToast;
