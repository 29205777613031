import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, useFormik } from 'formik';
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate, Link } from "react-router-dom";
import * as  valid_err from "../../Utils/Common_Messages"
import { Email_regex } from "../../Utils/Common_regex"
import { fDateTimeSuffix, dateFormatePlusThirty } from '../../Utils/DateFormat';

import { ForgetPasswordSendLink } from "../../ReduxStore/Slice/AuthSlice/AuthSlice"
import Formikform from "../../Utils/Formikform"
import { useDispatch, useSelector } from "react-redux";
import AlertToast from "../../Utils/Alert_Toast"
import { toast } from "react-toastify";


const ForgetPassword = () => {



    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const AdminToken = JSON.parse(localStorage.getItem('admin')).accessToken;

    const selector = useSelector((state) => state?.AuthSlice)

    const isValidEmail = (email) => {
        return Email_regex(email)
    }

    const formik = useFormik({
        initialValues: {
            forgetuseremail: '',
        },
        validate: (values) => {
            const errors = {};
            // if (!values.forgetuseremail) {
            //   errors.forgetuseremail = valid_err.EMPTY_EMAIL_ERROR;
            // } else if (!isValidEmail(values.forgetuseremail)) {
            //   errors.forgetuseremail = valid_err.INVALID_EMAIL_ERROR;
            // }

            return errors;
        },
        onSubmit: async (values) => {

            await dispatch(ForgetPasswordSendLink({ email: values.forgetuseremail })).then((res) => {
                console.log("localStorage", res);
                if (res.payload.status) {
                    toast.success(res.payload.data)
                    setTimeout(() => {
                        navigate("/login", { replace: true })
                    }, 2000);

                }
                else {
                    toast.error(res.payload.message)
                }
            })


        },
    });

    const fields = [
        { name: 'forgetuseremail', label: 'Enter Your  Email', type: 'text' },
    ];
    return (

        <section style={{ height: '100vh', background: '#ebf8fc', overflowY: 'hidden' }} className="d-flex align-items-center justify-content-center">
            <div className="container  ">
                <div className="row mt-5 d-flex align-items-center justify-content-center">
                    <div className="col-md-4">
                        <div className="card rounded border ">
                            <div className="card-body">
                                <h4 className="text-center card-title">Password Reset</h4>
                                <div className="row">
                                    <div className="col-12 my-3">
                                        <div className="text-center">
                                        </div>
                                        <Formikform fieldtype={fields} formik={formik} btn_name="Continue" />
                                        <div className="text-center ">
                                            <p className="mt-3 mb-0">
                                                <small className="mr-2 text-dark">Back To Login1?</small>
                                                <Link
                                                    to="/login"
                                                    className="text-dark font-weight-bold"
                                                >
                                                    Login
                                                </Link>
                                            </p>
                                        </div>
                                        <AlertToast position="top-center" />

                                        <div className="text-center">
                                            <small className="text-dark" style={{ fontSize: 11 }}>
                                                © Copyright 2023 &nbsp; Copytrading
                                                &nbsp;
                                                All Rights Reserved.
                                            </small>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default ForgetPassword