import React from 'react'
import Dashboard from '../Layout/Admin/Dashboard/Dashboard'
import Client from '../Layout/Admin/Clients/Client'
import "../component/admin/admin-assets/css/style.css"
import DataTable from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"


const Datatable = (props) => {

    const { columns, data, url, id, deleteFunc, headerNames, options123 } = props

    const customStyles = {
        rows: {
            style: {
                // minHeight: '72px', // override the row height
            },
        },
        headCells: {
            style: {
                background: "#0e4152 ",
                color: "white",
                justifyContentCenter: "center"
                // paddingLeft: '8px', // override the cell padding for head cells
                // paddingRight: '8px',
            },
        },
        cells: {
            style: {
                // paddingLeft: '8px', // override the cell padding for data cells
                justifyContentCenter: "center"

                // paddingRight: '8px',
            },

        },
    };

    const columns123 = [
        {
            name: 'SR No.',
            selector: (row, index) => index + 1,
            width: "90px"
        },
        ...columns]



    return (
        <div>

            {/* <DataTableExtensions
                export={false}
                print={false}
                // {...tableData}
                columns={columns123}/
                data={data}

           > */}

                <DataTable
                columns={columns123}
                data={data}

                fixedHeader
                fixedHeaderScrollHeight="700px"
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                customStyles={customStyles}
                highlightOnHover
                paginationRowsPerPageOptions={[10, 50, 100]}
                paginationComponentOptions={{ selectAllRowsItem: true, selectAllRowsItemText: 'All' }}
                className="mt-5"
            />
           {/* // </DataTableExtensions> */}

        </div >



    )
}

export default Datatable