export const EMPTY_EMAIL_ERROR = 'Please Enter Email ';
export const INVALID_EMAIL_ERROR = 'please Enter A Valid Email ';
export const PASSWORD_ERROR = 'Please Enter Password ';
export const USERNAME_ERROR = 'Please Enter Username ';
export const FULLNAME_ERROR = 'Please Enter FullName ';
export const CONTACT_ERROR = 'Please Enter Contact Number ';
export const INVALID_CONTACT_ERROR = 'Please Enter Valid Contact Number ';
export const MASTER_ERROR = 'Please Enter Select Master ';
export const APIKEY_ERROR = 'Please Enter APIKey ';
export const APIID_ERROR = 'Please Enter APIID ';
export const APISECRET_ERROR = 'Please Enter API Secret ';
export const BROKER_ERROR = 'Please Select Broker ';
export const LICENCE_TYPE_ERROR = 'Please Enter Licence Type ';
export const LICENCE_ERROR = 'Please Enter Licence Month';
export const QUANTITYSELECT_ERROR = 'Please Select Quantity Selection';
export const PER_TRADE_ERROR = 'Please Enter Per Trade Fund Value ';
export const PER_TRADE_INVALID_ERROR = 'Please Enter integer  Value ';
export const QTY_ERROR = 'Please Enter Per  QTY ';
export const QTY_INVALID_ERROR = 'Please Enter Invalid QTY ';
export const SEGEMENTSELECT_ERROR = 'Please Select Segment Selection';
export const SEGEMENTSELECT_LEASTONE_ERROR = 'Please Select At Least One ';
export const NEW_PASSWORD_ERROR = 'Please Enter New Password ';
export const OLD_PASSWORD_ERROR = 'Please Enter Old Password ';
export const CONFIRM_PASSWORD_ERROR = 'Please Enter Confirm Password';
export const CONFIRM_AND_NEW_PASSWORD_ERROR = 'New Password And Confirm Password Are Not Matched';
export const OLD_AND_NEW_PASSWORD_ERROR = 'Old Password And New Password Are Not Matched';

// ForCompany Details


export const COMPANY_HOST_ERROR = 'Please Enter Valid SMTP HOST';
export const COMPANY_PORT_ERROR = 'Please Enter Valid SMTP PORT';
export const COMPANY_PASSWORD_ERROR = 'Please Enter Valid SMTP Password';
export const COMPANY_NAME_ERROR = 'Please Enter Valid Company Name';
export const EMPTY_COMPANY_EMAIL_ERROR = 'Please Enter  Valid  Company Email ';
export const INVALID_COMPANY_EMAIL_ERROR = 'please Enter A Valid Company Email ';
export const EMPTY_COMPANYCC_EMAIL_ERROR = 'Please Enter  Valid  Company CC Email ';
export const INVALID_COMPANYCC_EMAIL_ERROR = 'please Enter A Valid Company CC  Email ';
export const EMPTY_COMPANYBCC_EMAIL_ERROR = 'Please Enter  Valid  Company BCC Email ';
export const INVALID_COMPANYBCC_EMAIL_ERROR = 'please Enter A Valid Company BCC Email ';

