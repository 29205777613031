import React, { useState, useEffect, Component } from 'react';

import { GetTradingStatusList, tradingstatuslist } from "../../../ReduxStore/Slice/UsersSlice/CommonSlice/CommonSlice"
import { useDispatch, useSelector } from "react-redux";
import DataTable from '../../../Utils/Datatables';
import { fDateTime } from '../../../Utils/DateFormat';




const TradingStatus = () => {

  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const dispatch = useDispatch()

  const getTradingStatuList = useSelector((state) => state)

  const { data, status } = useSelector(tradingstatuslist && tradingstatuslist)

  // console.log("getTradingStatuList", data);
  // console.log("getTradingStatuList", getTradingStatuList)


  const getUserProfile = () => {
    dispatch(GetTradingStatusList({ id: userid, AdminToken: AdminToken }))
  }

  useEffect(() => {
    getUserProfile()
  }, [dispatch])



  const columns = [

    {
      name: 'Status',
      selector: row => row.trading == null ? "-" : row.trading,
      width: "140px"
    },
    {
      name: 'Quantity Type',
      selector: row => row.qty_type == null ? "-" :row.qty_type,
      width: "140px"
    },
    {
      name: 'Segment Selsection',
      selector: row => row.segment_selsection == null ? "-":row.segment_selsection ,
      width: "190px"
    },
    {
      name: 'Quantity Type',
      selector: row => row.qty_type == null ?"-":row.qty_type,
      width: "140px"
    },
    {
      name: 'Other',
      selector: row => row.other == null ? "-": row.other,
      width: "140px"
    },
    {
      name: 'Time',
      selector: row => fDateTime(row.createdAt),
      width: "200px"
    },
    // {
    //   name: 'updateBy',
    //   selector: row => row.updateBy == 0,
    //   width: "140px"
    // },



  ]



  return (
    <div>
      <div className='main-content position-relative'>
        <div className="page-content">
          <div className="page-title">
            <div className="row justify-content-between align-items-center">
              <div className="mb-3 col-md-6 mb-md-0">
                <h5 className="mb-0 text-white h3 font-weight-400">Trading Status</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <DataTable
                      columns={columns}
                      data={data && data}

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  )
}

export default TradingStatus