import React, { useState, useEffect, Component } from 'react';
import { GetUserAvailableFund, fundetails, } from "../../../ReduxStore/Slice/UsersSlice/CommonSlice/CommonSlice"
// import { GetTradingStatusList, tradingstatuslist } from "../../../ReduxStore/Slice/UsersSlice/CommonSlice/CommonSlice"

import { useDispatch, useSelector } from "react-redux";


const Dashboard = () => {
  const dispatch = useDispatch()


  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;

  const getuserFund = useSelector((state) => state !== undefined && state.CommonSlice.fundetails)
  const test = useSelector((state) => state !== undefined && state.CommonSlice.fundetails)

  console.log("getuserFund" ,getuserFund);
  
  const getUserProfile = () => {
    dispatch(GetUserAvailableFund({ id: userid, AdminToken: AdminToken }))
  }

  useEffect(() => {
    getUserProfile()
  }, [dispatch])


  return (
    <div className='main-content position-relative'>
      <div className="page-content">
        {/* Page title */}
        <div className="page-title">
          <div className="row justify-content-between align-items-center">
            <div className="mb-3 col-md-6 mb-md-0">
              <h5 className="mb-0 text-white h3 font-weight-400">Master Dashboard</h5>
            </div>
          </div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="nk-block-head-content">
                      <h5 className="text-primary h5">Account Summary</h5>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                    <div className="card card-stats">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h6 className="mb-1 text-muted">Client Id</h6>
                            {/* <span className="mb-0 h5 font-weight-bold"> &#8377; {data != undefined && data.data[0].cash ? data != undefined && data.data[0].cash : "-"}</span> */}
                            <span className="mb-0 h5 font-weight-bold">  {

                              getuserFund !== undefined ? getuserFund.length == 0 || getuserFund.status === false ? "-" : getuserFund.data.client_id
                                : '-'
                            }</span>
                          </div>
                          <div className="col-auto">
                            <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                              <i className="fas fa-sack-dollar" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                    <div className="card card-stats">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h6 className="mb-1 text-muted">Total Cash</h6>
                            {/* <span className="mb-0 h5 font-weight-bold"> &#8377; {data != undefined && data.data[0].cash ? data != undefined && data.data[0].cash : "-"}</span> */}
                            <span className="mb-0 h5 font-weight-bold"> &#8377; {
                              getuserFund !== undefined ?
                                getuserFund.length == 0 || getuserFund.status === false ? "-" : getuserFund.data.Total_Cash : "-"}</span>
                          </div>
                          <div className="col-auto">
                            <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                              <i className="fas fa-sack-dollar" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                    <div className="card card-stats">
                      {/* Card body */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h6 className="mb-1 text-muted">Available Cash</h6>
                            <span className="mb-0 h5 font-weight-bold">&#8377; {
                              getuserFund !== undefined ?
                                getuserFund.length == 0 || getuserFund.status === false ? "-" : getuserFund.data.Available_Cash : "-"
                            }</span>
                          </div>
                          <div className="col-auto">
                            <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                              <i className="fas fa-coins" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                    <div className="card card-stats">
                      {/* Card body */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h6 className="mb-1 text-muted">Used  Margin </h6>
                            <span className="mb-0 h5 font-weight-bold">&#8377;
                              {
                                getuserFund !== undefined ?

                                  getuserFund.length == 0 || getuserFund.status === false ? "-" : getuserFund.data.Used_Margin : ""
                              }

                            </span>
                          </div>
                          <div className="col-auto">
                            <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                              <i className="fas fa-gift" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                    <div className="card card-stats">
                      {/* Card body */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h6 className="mb-1 text-muted">Available Margin</h6>
                            <span className="mb-0 h5 font-weight-bold">&#8377;
                              {
                                getuserFund !== undefined ?

                                  getuserFund.length == 0 || getuserFund.status === false ? "-" : getuserFund.data.AvailableMargin
                                  : "-"

                              }
                            </span>
                          </div>
                          <div className="col-auto">
                            <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                              <i className="fas fa-th-list" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {getuserFund.length == 0 || getuserFund.status === false ?
                <h5 className="mb-1 text-center text-danger">{
                  getuserFund.length == 0 || getuserFund.data
                  }</h5>
                : ""
              }
            </div>
          </div>
        </div>
      </div></div>
  )
}

export default Dashboard