import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from '../../../component/admin/Footer';
import Content from '../../../component/admin/Content';
import DataTable from '../../../Utils/Datatables';
import { toast } from "react-toastify";
import AlertToast from "../../../Utils/Alert_Toast"
import Model from "../../../component/ReusableComponents/Model/Model"

import { Link } from "react-router-dom";
import { GetAdminAllClients, masterlist, GetAdminAllChilds, Remove_One_User } from "../../../ReduxStore/Slice/AdminSlice/Common"
import { GetClientAcordingItMaster } from "../../../ReduxStore/Slice/AdminSlice/Master/MasterSlice"
import { fDateTime } from '../../../Utils/DateFormat';


const Client = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const admin_id = JSON.parse(localStorage.getItem('user_details')).admin_id;
  const userid = JSON.parse(localStorage.getItem('user_details')).id;


  const masterList = useSelector(masterlist)
  const { masterClientlist } = useSelector(state => state && state.MasterSlice)

  console.log("Dadda", masterlist);

  const mater = useSelector((state) => state && state?.Adminslice)

  console.log("masterClientlist", mater);

  const [rerender, setRerender] = useState(false)
  const [modalOpen, setModalOpen] = useState(false);
  const [showMasterUsername, setShowMasterUsername] = useState('');


  const GetAllClients = async () => {
    await dispatch(GetAdminAllClients({ id: userid, admin_id: admin_id , AdminToken:AdminToken }))
  }

  useEffect(() => {
    GetAllClients()
  }, [dispatch, rerender])



  const showBrokerName = (value) => {

    if (value == 1) {
      return "markethub"
    }
    else if (value == 2) {
      return "alice blue"
    }
    else if (value == 3) {
      return "master trust"
    }
    else if (value == 4) {
      return "Motilal Oswal"
    }
    else if (value == 5) {
      return "Zebull"
    }
    else if (value == 6) {
      return "IIFl"
    }
    else if (value == 7) {
      return "Kotak"
    }
    else if (value == 8) {
      return "Mandot"
    }
    else if (value == 9) {
      return "Choice"
    }
    else if (value == 10) {
      return "Anand Rathi"
    }
    else if (value == 11) {
      return "B2C"
    }
    else if (value == 12) {
      return "Angel"
    }
    else if (value == 13) {
      return "Fyers"
    }
    else if (value == 14) {
      return "5-Paisa"
    }
    else if (value == 15) {
      return "Zerodha"
    }
  }



  const RemoveUser = async (userid) => {
    // console.log("test", userid);
    await dispatch(Remove_One_User({ id: userid, token: AdminToken })).then((res) => {
      // console.log("repso", res);
      if (res.meta.requestStatus === "fulfilled") {
        toast.success(res.payload.data)
        setRerender(!rerender)
      }
    })


  }


  const ShowUsers = async (id, username) => {
    setShowMasterUsername(username)
    setModalOpen(true)

    await dispatch(GetClientAcordingItMaster({ id: id, AdminToken: AdminToken }))

  }
  const columns = [

    {
      name: 'Username',
      selector: row => row.username,
      width: "140px"
    },
    {
      name: 'show_password',
      selector: row => row.show_password,
      width: "140px"
    },
    {
      name: "Contact ",
      selector: row => row.phone_number,
      width: "170px"

    },
    {
      name: 'Email',
      selector: row => row.email,
      width: "170px"

    },

    {
      name: 'Broker',
      selector: row => showBrokerName(row.broker),
      width: "150px"

    },
    {
      name: 'Start Date',
      selector: row => row.start_date,
      width: "140px"
    },
    {
      name: 'Actions',
      selector: row =>
        <>
          <div className='actiontd'>

            <a data-toggle="tooltip" data-placement="top" title="Show Clients">

              <i class="fa-sharp fa-solid fa-people-arrows mx-3 " style={{ fontSize: "16px" }} onClick={() => ShowUsers(row.id, row.username)}></i>
            </a>

            <Link to={`/admin/master/edit/${row.id} `} state={row}>
              <i class="fa-solid fa-pen-to-square" />
            </Link>
            {/* //<a href='#' className='mx-2'><i class="fa-regular fa-eye"></i></a> */}

            <a data-toggle="tooltip" data-placement="top" title="Delete "><i class="fa-solid fa-trash mx-3" onClick={() => RemoveUser(row.id)}></i></a>


          </div>
        </>
      ,
      width: "172px"

    },


  ]


  const columns1 = [

    {
      name: 'Username',
      selector: row => row.username,
      width: "140px"
    },
    {
      name: 'show_password',
      selector: row => row.show_password,
      width: "140px"
    },
    {
      name: "Contact ",
      selector: row => row.phone_number,
      width: "170px"

    },
    {
      name: 'Email',
      selector: row => row.email,
      width: "170px"

    },

    {
      name: 'Broker',
      selector: row => showBrokerName(row.broker),
      width: "150px"

    },
    {
      name: 'Start Date',
      selector: row => row.start_date,
      width: "140px"
    },
  ]


  return (
    <div>

      <div className="main-panel">
        <div className="content ">
          <div className="panel-header bg-primary-gradient">
            <div className="py-5 page-inner">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                <h2 className="pb-2 text-white fw-bold">Master Clients</h2>
              </div>
            </div>
          </div>
          <div className="page-inner mt--5">
            <div className="row row-card-no-pd  shadow-lg mt--2">
              <div className='data-table-div'>
                <Link to='/admin/master/add' className='btn btn-primary'> <i class="fa-solid fa-plus"></i> Add Master</Link>
                <DataTable
                  columns={columns}
                  data={masterList && masterList}
                />

                <AlertToast position="top-center" />


                <Model size="lg" isOpen={modalOpen} onClose={() => setModalOpen(false)} title={`${showMasterUsername && showMasterUsername} Total Clients`} btn_name="Uupa"
                  content={
                    <>
                      <DataTable
                        columns={columns1}
                        data={masterClientlist && masterClientlist}
                      />
                    </>} />

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Content  > */}
    </div>
  )
}

export default Client